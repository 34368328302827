import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  Badge,
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  mergeStyles,
  MoreLessToggle,
  SpinNumberInput,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  FeeType,
  ServiceRuleType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { ServiceWarningsContext } from "@modules/billing/screens/shared-components/add-service-modal/context/ServiceWarningsContext.tsx";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { Schedule } from "@stores/billing/models/Schedule.ts";
import {
  getServiceTax,
  getServiceTotal
} from "@stores/billing/utils/billing.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { UnitInput } from "@ui-components/form/UnitInputField.tsx";

import { SelectedServiceTitleQuantity } from "./SelectedServiceTitleQuantity.tsx";
import { getIsValid, getValidationResult } from "./utils.ts";

export interface SelectedServicesListProps {
  item: InvoiceItemFormValue;
  isExtended: boolean;
  schedules: Schedule[];
  onChange: (item: InvoiceItemFormValue) => void;
  toggleIsExtended: (id: string) => void;
  claimId?: string;
}

export const SelectedServiceDescription: React.FC<SelectedServicesListProps> =
  observer(
    ({ item, isExtended, onChange, schedules, toggleIsExtended, claimId }) => {
      const { billing } = useStores();
      const theme = useTheme();

      const { serviceSearch, quantity } = item;

      const { name, description, code, scheduleId, feeType, isService } =
        serviceSearch || {};

      const handleChange = (item: InvoiceItemFormValue) => {
        onChange({
          ...item,
          gst: getServiceTax(item, billing.gstPercent).toString(),
          total: getServiceTotal(item, billing.gstPercent).toString()
        });
      };

      const isValid = getIsValid(item, claimId);
      const validationResult = getValidationResult(item, claimId);

      const { getServiceErrorsAndWarnings } = useContext(
        ServiceWarningsContext
      );

      const warnings = getServiceErrorsAndWarnings(item, { claimId });

      const scheduleName = schedules.find(
        schedule => schedule.id === scheduleId
      )?.name;

      return (
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack tokens={{ childrenGap: 4 }} horizontal>
            <Text
              className={
                isExtended
                  ? mergeStyles({ whiteSpace: "initial" })
                  : "clampTwoLines"
              }
            >
              {name || description}
            </Text>
            <SelectedServiceTitleQuantity
              feeType={item.feeType}
              quantity={item.quantity}
              isExtended={isExtended}
            />
          </Stack>
          {isExtended && (
            <>
              {name && (
                <Text styles={{ root: { whiteSpace: "initial" } }} block>
                  {description}
                </Text>
              )}
              <Stack horizontal tokens={{ childrenGap: 12 }}>
                {item.serviceSearch?.rules?.some(
                  r => r.ruleType === ServiceRuleType.UserDefinedAmount
                ) ? (
                  <SpinNumberInput
                    {...dataAttribute(
                      DataAttributes.Element,
                      "selected-service-fee-input"
                    )}
                    value={String(item.fee)}
                    styles={{
                      root: { marginTop: 8 },
                      spinButtonWrapper: { width: 120 }
                    }}
                    label="Fee"
                    step={0.01}
                    onChange={newFee => {
                      handleChange({ ...item, fee: newFee ?? "" });
                    }}
                  />
                ) : (
                  <Stack>
                    <Text
                      styles={{
                        root: {
                          fontSize: FontSizes.size10,
                          color: theme.palette.neutralSecondary
                        }
                      }}
                    >
                      Fee
                    </Text>
                    <Text>{currencyFormat(Number(item.fee))}</Text>
                  </Stack>
                )}
                {feeType && feeType !== FeeType.FlatRate && (
                  <>
                    <UnitInput
                      {...dataAttribute(
                        DataAttributes.Element,
                        "selected-service-quantity-input"
                      )}
                      styles={{ root: { width: 120, marginTop: 8 } }}
                      unitType={feeType}
                      value={Number(quantity)}
                      onChange={newQuantity => {
                        handleChange({
                          ...item,
                          quantity: String(newQuantity || 0)
                        });
                      }}
                    />
                    <Stack>
                      <Text
                        styles={{
                          root: {
                            fontSize: FontSizes.size10,
                            color: theme.palette.neutralSecondary
                          }
                        }}
                      >
                        Total
                      </Text>
                      <Text
                        {...dataAttribute(
                          DataAttributes.Element,
                          "selected-service-total"
                        )}
                      >
                        {currencyFormat(
                          getServiceTotal(item, billing.gstPercent)
                        )}
                      </Text>
                    </Stack>
                  </>
                )}
              </Stack>
            </>
          )}
          <Stack
            styles={{ root: { marginTop: 14 } }}
            horizontal
            horizontalAlign="space-between"
            verticalAlign={isValid ? "baseline" : "end"}
            tokens={{ childrenGap: 8 }}
          >
            <Stack tokens={{ childrenGap: 8 }} horizontalAlign="start">
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 8 }}
              >
                {scheduleName && (
                  <Badge
                    {...dataAttribute(DataAttributes.Element, "schedule-badge")}
                    styles={{
                      root: {
                        backgroundColor: theme.palette.themeLighter,
                        borderRadius: 2,
                        display: "inline-block"
                      },
                      content: { whiteSpace: "initial" }
                    }}
                  >
                    {scheduleName}
                  </Badge>
                )}
                {!isService && <FontIcon iconName="Shop" />}
              </Stack>
              {!isValid && (
                <Stack>
                  {Object.entries(validationResult).map(([key, value]) => (
                    <Text
                      key={key}
                      data-invalid-fee-code={code}
                      styles={(_props, theme) => ({
                        root: {
                          color: theme.semanticColors.errorText,
                          whiteSpace: "break-spaces"
                        }
                      })}
                    >
                      <>{value}</>
                    </Text>
                  ))}
                </Stack>
              )}
              {isValid && !!warnings.messages?.length && (
                <Stack>
                  {warnings.messages.map(warning => (
                    <Text
                      key={warning}
                      styles={{
                        root: {
                          color: theme.palette.yellowDark,
                          whiteSpace: "break-spaces"
                        }
                      }}
                    >
                      {warning}
                    </Text>
                  ))}
                </Stack>
              )}
            </Stack>
            <Stack styles={{ root: { pointerEvents: "auto" } }}>
              <MoreLessToggle
                value={!isExtended!}
                onChanged={() => toggleIsExtended(item.serviceId)}
              />
            </Stack>
          </Stack>
        </Stack>
      );
    }
  );
