import { IGroup } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  ObservationMetadataItem
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { Measurement } from "@stores/clinical/models/Measurement.ts";
import { Observation } from "@stores/clinical/models/Observation.ts";

import { FormattedMeasurement } from "./generateMeasurementColumns.tsx";
import { MeasurementRow } from "./ObservationsExpandedTable.tsx";

export enum ObservationsLabels {
  BloodPressure = "Blood pressure",
  PulseBpm = "Pulse (bpm)",
  PulseBpmInTreeView = "Pulse",
  Respiration = "Respiration",
  Temperature = "Temperature (°C)",
  TemperatureInTreeView = "Temperature",
  Height = "Height",
  Weight = "Weight",
  Neck = "Neck",
  Waist = "Waist",
  Hip = "Hip",
  O2SaturationRate = "O₂ saturation (%)",
  K10 = "K10",
  PSFS = "PSFS",
  GRCS = "GRCS",
  DASH = "DASH",
  DASH11 = "DASH 11",
  EPDS = "EPDS",
  DASS21 = "DASS 21",
  DASS = "DASS21",
  RAND36 = "RAND36",
  OREBRO = "OREBRO",
  NPRS = "NPRS",
  BMI = "Body mass index (BMI)",
  BMIInTreeView = "BMI",
  PulseSittingManual = "Sitting; Manual",
  PulseStandingManual = "Standing; Manual",
  PulseSittingMachine = "Sitting; Machine",
  PulseStandingMachine = "Standing; Machine",
  PulseLyingManual = "Lying; Manual",
  PulseLyingMachine = "Lying; Machine",
  BPLeftArmSitting = "Left arm; Sitting",
  BPLeftArmLying = "Left arm; Lying",
  BPLeftArmStanding = "Left arm; Standing",
  BPRightArmStanding = "Right arm; Standing",
  BPRightArmSitting = "Right arm; Sitting",
  BPRightArmLying = "Right arm; Lying",
  Machine = "Machine",
  Manual = "Manual",
  BGL = "Blood glucose level (BGL)"
}
export const getLabel = (type: string, summary?: string) => {
  switch (type) {
    case "PUL":
      let pulseText = "";
      switch (summary) {
        case "MAC":
          pulseText = ObservationsLabels.Machine;
          break;
        case "MAN":
          pulseText = ObservationsLabels.Manual;
          break;
      }
      return pulseText;
    case "PULSITMAN":
      return ObservationsLabels.PulseSittingManual;
    case "PULSTAMAN":
      return ObservationsLabels.PulseStandingManual;
    case "PULSLYMAN":
      return ObservationsLabels.PulseLyingManual;
    case "PULSITMAC":
      return ObservationsLabels.PulseSittingMachine;
    case "PULSTAMAC":
      return ObservationsLabels.PulseStandingMachine;
    case "PULSLYMAC":
      return ObservationsLabels.PulseLyingMachine;
    case "LSITSYS":
    case "LSITDIA":
      return ObservationsLabels.BPLeftArmSitting;
    case "LLYISYS":
    case "LLYIDIA":
      return ObservationsLabels.BPLeftArmLying;
    case "LSTASYS":
    case "LSTADIA":
      return ObservationsLabels.BPLeftArmStanding;
    case "RSTASYS":
    case "RSTADIA":
      return ObservationsLabels.BPRightArmStanding;
    case "RSITSYS":
    case "RSITDIA":
      return ObservationsLabels.BPRightArmSitting;
    case "RLYISYS":
    case "RLYIDIA":
      return ObservationsLabels.BPRightArmLying;
    case "SYS":
    case "DIA":
      return "";
    default:
      return type;
  }
};

export enum ObservationGroup {
  GroupByDateAndTypeKey = "ByDate",
  GroupByExaminationAndDateKey = "ByExamination",
  GroupByDateAndTypeLabel = "By date",
  GroupByExaminationAndDateLabel = "By examination",
  ShowAsTableLabel = "Show as table"
}

export const bloodPressureTypes = new Set([
  "LSITSYS",
  "LSTASYS",
  "LLYISYS",
  "RSITSYS",
  "RSTASYS",
  "RLYISYS",
  "LSITDIA",
  "LSTADIA",
  "LLYIDIA",
  "RSITDIA",
  "RSTADIA",
  "RLYIDIA",
  "SBP",
  "DBP",
  "SYS",
  "DIA",
  "BP"
]);

export const clinicalTools = new Set([
  "K10",
  "GRCS",
  "PSFS",
  "DASH11",
  "DASS21",
  "OREBRO",
  "EPDS",
  "NPRS",
  "RAND36"
]);

export const pulseTypes = new Set([
  "PUL",
  "PULSITMAN",
  "PULSTAMAN",
  "PULSLYMAN",
  "PULSITMAC",
  "PULSTAMAC",
  "PULSLYMAC"
]);

export enum BloodPressureType {
  Systolic = "Systolic",
  Diastolic = "Diastolic"
}

export const getMeasurementTypeLabel = (
  type: string,
  isTreeView?: boolean
): string => {
  if (bloodPressureTypes.has(type)) return ObservationsLabels.BloodPressure;
  if (pulseTypes.has(type))
    return isTreeView
      ? ObservationsLabels.PulseBpmInTreeView
      : ObservationsLabels.PulseBpm;
  if (type === "RRT") return ObservationsLabels.Respiration;
  if (type === "TMP")
    return isTreeView
      ? ObservationsLabels.TemperatureInTreeView
      : ObservationsLabels.Temperature;
  if (type === "HGT") return ObservationsLabels.Height;
  if (type === "WGT") return ObservationsLabels.Weight;
  if (type === "NEK") return ObservationsLabels.Neck;
  if (type === "WST") return ObservationsLabels.Waist;
  if (type === "HIP") return ObservationsLabels.Hip;
  if (type === "O2S") return ObservationsLabels.O2SaturationRate;
  if (type === "K10") return ObservationsLabels.K10;
  if (type === "DASH") return ObservationsLabels.DASH11;
  if (type === "DASS21") return ObservationsLabels.DASS21;
  if (type === "GRCS") return ObservationsLabels.GRCS;
  if (type === "NPRS") return ObservationsLabels.NPRS;
  if (type === "OREBRO") return ObservationsLabels.OREBRO;
  if (type === "RAND36") return ObservationsLabels.RAND36;
  if (type === "PSFS") return ObservationsLabels.PSFS;
  if (type === "EPDS") return ObservationsLabels.EPDS;
  if (type === "BGL") return ObservationsLabels.BGL;
  if (type === "BMI")
    return isTreeView
      ? ObservationsLabels.BMIInTreeView
      : ObservationsLabels.BMI;
  return type;
};

export interface GroupedMeasurement {
  id: string;
  value: string;
  timestamp: string;
  type: string;
  summary: string;
  typeLabel: string;
  label?: string;
  systolic?: string;
  diastolic?: string;
  pulse?: string;
}

export interface MeasurementItemProps {
  primaryKey: string;
  measurements: GroupedMeasurement[];
}

export interface GroupedMeasurementItem {
  key: string;
  groupedMeasurements: GroupedMeasurement[];
}

export type GroupedByDateAndType = Record<
  string,
  Record<string, GroupedMeasurement[]>
>;

export const groupMeasurementsByDateAndType = (
  measurements: Measurement[],
  getTypeLabel: (type: string) => string
): GroupedByDateAndType => {
  return measurements.reduce((acc: GroupedByDateAndType, m) => {
    const dateKey = DateTime.fromISO(m.timestamp).toFormat("dd/MM/yy");
    const typeLabel = getTypeLabel(m.type);

    const formattedTimestamp = DateTime.fromISO(m.timestamp).toFormat(
      "h:mm:ss a"
    );

    if (!acc[dateKey]) {
      acc[dateKey] = {};
    }

    if (!acc[dateKey][typeLabel]) {
      acc[dateKey][typeLabel] = [];
    }

    acc[dateKey][typeLabel].push({
      id: m.id,
      value: `${m.value}`,
      timestamp: formattedTimestamp,
      type: m.type,
      summary: m.summary ? m.summary : "",
      typeLabel: getMeasurementTypeLabel(m.type)
    });

    return acc;
  }, {});
};

export const formatMeasurements = (measurements: GroupedMeasurement[]) => {
  const groupedByTimestamp = measurements.reduce(
    (
      acc: Record<string, FormattedMeasurement>,
      measurement: GroupedMeasurement
    ): Record<string, FormattedMeasurement> => {
      if (!acc[measurement.timestamp]) {
        acc[measurement.timestamp] = {
          systolic: "",
          diastolic: "",
          value: "",
          label: getLabel(measurement.type, measurement.summary)
        };
      }

      if (bloodPressureTypes.has(measurement.type)) {
        if (measurement.type.endsWith("SYS")) {
          acc[measurement.timestamp].systolic = measurement.value;
        } else if (measurement.type.endsWith("DIA")) {
          acc[measurement.timestamp].diastolic = measurement.value;
        }
      } else if (pulseTypes.has(measurement.type)) {
        acc[measurement.timestamp].pulse = measurement.value;
        if (measurement.type.endsWith("PUL")) {
          acc[measurement.timestamp].label = getLabel(
            measurement.type,
            measurement.summary
          );
        } else {
          acc[measurement.timestamp].pulse = measurement.value;
        }
      } else {
        acc[measurement.timestamp].value = measurement.value;
      }

      return acc;
    },
    {}
  );

  return groupedByTimestamp;
};

export const formatObservations = (observations: GroupedMeasurement[]) => {
  const groupedByTimestamp = observations.reduce(
    (
      acc: Record<string, FormattedMeasurement>,
      observation: GroupedMeasurement
    ): Record<string, FormattedMeasurement> => {
      if (!acc[observation.timestamp]) {
        acc[observation.timestamp] = {
          value: "",
          label: observation.summary
        };
      }

      acc[observation.timestamp].value = observation.value;

      return acc;
    },
    {}
  );

  return groupedByTimestamp;
};

export const generateMeasurementRows = (
  uniqueTypes: Set<string>,
  dates: string[],
  groupedByDateAndType: GroupedByDateAndType
): MeasurementRow[] => {
  return Array.from(uniqueTypes)
    .map(type => ({
      key: type,
      type,
      ...dates.reduce((acc: Record<string, string>, date) => {
        const valuesForTypeOnDate =
          groupedByDateAndType[date]?.[type]?.map(
            measurement => measurement.value
          ) || [];

        acc[date] = valuesForTypeOnDate.join("\n");
        return acc;
      }, {})
    }))
    .sort((a, b) => {
      const aIsClinicalTool = clinicalTools.has(
        normalizeLabel(getMeasurementTypeLabel(a.type))
      );

      const bIsClinicalTool = clinicalTools.has(
        normalizeLabel(getMeasurementTypeLabel(b.type))
      );

      if (aIsClinicalTool && !bIsClinicalTool) return 1;
      if (!aIsClinicalTool && bIsClinicalTool) return -1;
      return 0;
    });
};

export const groupObservationsByDateAndType = (
  observations: Observation[]
): GroupedByDateAndType => {
  return observations.reduce((acc: GroupedByDateAndType, o) => {
    const dateKey = DateTime.fromISO(o.timestamp).toFormat("dd/MM/yy");
    const typeLabel = getMeasurementTypeLabel(o.type);

    const formattedTimestamp = DateTime.fromISO(o.timestamp).toFormat(
      "h:mm:ss a"
    );

    if (!acc[dateKey]) {
      acc[dateKey] = {};
    }

    if (!acc[dateKey][typeLabel]) {
      acc[dateKey][typeLabel] = [];
    }

    acc[dateKey][typeLabel].push({
      id: o.id,
      value: getObservationValue(o),
      timestamp: formattedTimestamp,
      type: o.type,
      summary: o.summary ? o.summary : "",
      typeLabel: getMeasurementTypeLabel(o.type)
    });

    return acc;
  }, {});
};

const getMetaValue = (key: string, metaData: ObservationMetadataItem[]) => {
  return metaData.find(x => x.key === key)?.value;
};

const getObservationValue = (observation: Observation) => {
  if (observation.type === "BP") {
    const systolic = observation.values.find(
      x => getMetaValue("Type", x.metadata ?? []) === BloodPressureType.Systolic
    )?.value;

    const diastolic = observation.values.find(
      x =>
        getMetaValue("Type", x.metadata ?? []) === BloodPressureType.Diastolic
    )?.value;
    return `${systolic}/${diastolic}`;
  } else {
    const allValues = observation.values.map(x =>
      Number.isInteger(x.value) ? x.value : x.value.toFixed(1)
    );

    return allValues.join(", ");
  }
};

export const normalizeLabel = (label: string) =>
  label.replace(/\s+/g, "").toUpperCase();

// Function to map Measurement to GroupedMeasurement
const mapToGroupedMeasurement = (
  measurement: Measurement
): GroupedMeasurement => {
  return {
    id: measurement.id,
    value: measurement.value.toString(),
    timestamp: measurement.timestamp,
    type: measurement.type,
    summary: measurement.summary || "",
    typeLabel: getMeasurementTypeLabel(measurement.type, true),
    label: getLabel(measurement.type, measurement.summary)
  };
};

export const groupMeasurementsByDateTypeAndTimeStamp = (
  measurements: Measurement[]
): Record<string, Record<string, GroupedMeasurement[]>> => {
  return measurements
    .map(mapToGroupedMeasurement)
    .reduce((acc: Record<string, Record<string, GroupedMeasurement[]>>, m) => {
      const dateKey = DateTime.fromISO(m.timestamp).toFormat("d LLLL yyyy");
      const typeKey = getMeasurementTypeLabel(m.type, true);

      if (!acc[dateKey]) acc[dateKey] = {};
      if (!acc[dateKey][typeKey]) acc[dateKey][typeKey] = [];

      acc[dateKey][typeKey].push(m);
      return acc;
    }, {});
};

export const groupMeasurementsByExaminationAndDate = (
  measurements: Measurement[]
): Record<string, Record<string, GroupedMeasurement[]>> => {
  return measurements
    .map(mapToGroupedMeasurement)
    .reduce((acc: Record<string, Record<string, GroupedMeasurement[]>>, m) => {
      const typeKey = getMeasurementTypeLabel(m.type, true);
      const dateKey = DateTime.fromISO(m.timestamp).toFormat("dd MMM yyyy");

      if (!acc[typeKey]) acc[typeKey] = {};
      if (!acc[typeKey][dateKey]) acc[typeKey][dateKey] = [];

      acc[typeKey][dateKey].push(m);
      return acc;
    }, {});
};
export const EN_DASH: string = " – ";

export const processBloodPressureType = (
  data: GroupedMeasurement[]
): GroupedMeasurement[] => {
  const result: GroupedMeasurement[] = [];
  const combinedMap: Record<
    string,
    {
      SYS?: string;
      DIA?: string;
      id: string;
      typeLabel?: string;
      label?: string;
      type: string;
    }
  > = {};

  data.forEach(item => {
    const key = item.timestamp;

    if (bloodPressureTypes.has(item.type)) {
      if (!combinedMap[key]) {
        combinedMap[key] = {
          id: item.id,
          type: item.type
        };
      }

      if (item.type.endsWith("SYS") || item.type.startsWith("SYS")) {
        //to do
        combinedMap[key].SYS = item.value;
      } else if (item.type.endsWith("DIA") || item.type.startsWith("DIA")) {
        //to do
        combinedMap[key].DIA = item.value;
      }
    }
  });

  // Step 2: Process the combined SYS/DIA and push them as single records
  Object.keys(combinedMap).forEach(timeStamp => {
    const { SYS, DIA, id, type } = combinedMap[timeStamp];
    let value = "";
    let bpType = type;
    if (SYS && DIA) {
      value = `${SYS}/${DIA}`;
    } else if (SYS) {
      value = SYS;
      bpType = "SYS";
    } else if (DIA) {
      value = DIA;
      bpType = "DIA";
    }

    result.push({
      id,
      value,
      timestamp: timeStamp,
      summary: "",
      type: bpType,
      typeLabel: ObservationsLabels.BloodPressure,
      label: getLabel(bpType)
    });
  });

  return result;
};

export const getGroupsAndItems = (
  groupedByDateAndType: Record<string, Record<string, GroupedMeasurement[]>>,
  groupedByExaminationAndDate: Record<
    string,
    Record<string, GroupedMeasurement[]>
  >,
  selectedPivotKey: string
) => {
  let index = 0;
  const items: GroupedMeasurementItem[] = [];
  const groups: IGroup[] = [];
  const uniqueGroups = new Set<string>();

  const data =
    selectedPivotKey === ObservationGroup.GroupByDateAndTypeKey
      ? groupedByDateAndType
      : groupedByExaminationAndDate;
  // Collect unique date keys from the groupedByDateAndType object
  Object.keys(data).forEach(key => {
    uniqueGroups.add(key);
  });

  // Convert the unique date keys into group objects
  Array.from(uniqueGroups).forEach(primaryKey => {
    let groupItemsCount = 0;
    const typeItems: GroupedMeasurement[] = [];
    const group: IGroup = {
      key: primaryKey,
      name: primaryKey,
      startIndex: index,
      level: 1,
      count: 1,
      isCollapsed: true,
      data: 0
    };

    // For each date key, process different examination types
    selectedPivotKey === ObservationGroup.GroupByDateAndTypeKey
      ? Object.keys(data[primaryKey]).forEach(typeKey => {
          const measurements = data[primaryKey][typeKey];
          switch (typeKey) {
            case ObservationsLabels.BloodPressure:
              const bpItems = processBloodPressureType(measurements);
              groupItemsCount += bpItems.length;
              bpItems.map(x => typeItems.push(x));
              break;
            default:
              measurements.map(x => typeItems.push(x));
              groupItemsCount += measurements.length;
              break;
          }
        })
      : Object.keys(data[primaryKey]).forEach(dateKey => {
          const measurements = data[primaryKey][dateKey];
          switch (primaryKey) {
            case ObservationsLabels.BloodPressure:
              const bpItems = processBloodPressureType(measurements);
              groupItemsCount += bpItems.length;
              bpItems.map(x => typeItems.push(x));
              break;
            default:
              measurements.map(x => typeItems.push(x));
              groupItemsCount += measurements.length;
              break;
          }
        });

    items.push({
      key: primaryKey,
      groupedMeasurements: typeItems
    });

    // Update the startIndex for the next group
    index += 1;

    // Create the group object
    group.data = groupItemsCount;
    groups.push(group);
  });

  return {
    groups,
    items
  };
};

// Helper function to group measurements
export const getGroupMeasurements = (
  item: GroupedMeasurementItem,
  pivotKey: string
): Record<string, GroupedMeasurement[]> => {
  return item.groupedMeasurements.reduce(
    (acc, measurement) => {
      const key =
        pivotKey === ObservationGroup.GroupByDateAndTypeKey
          ? measurement.typeLabel
          : DateTime.fromISO(measurement.timestamp).toDayDefaultFormat();

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(measurement);
      return acc;
    },
    {} as Record<string, GroupedMeasurement[]>
  );
};

/**
 * Converts a date string to a formatted time string.
 * Handles ISO date strings and custom date strings.
 *
 * @param dateString - The date string to format
 * @param format - The desired format for the output string. Default is "h:mm a".
 * @returns The formatted time string.
 */
export const formatDateStringToTime = (
  dateString: string,
  format: string = "h:mm a"
): string => {
  return DateTime.fromISO(dateString).toFormat(format);
};

export const getAddAnotherOnClickForExamination = (
  key: string,
  clinical: ClinicalStore
): (() => void) => {
  const measurementType = (() => {
    switch (key) {
      case ObservationsLabels.DASH11:
        return ClinicalDataType.DASH;
      case ObservationsLabels.DASS21:
        return ClinicalDataType.DASS21;
      default:
        return getMeasurementTypeLabel(key);
    }
  })();

  return () => {
    const type = (() => {
      switch (measurementType) {
        case ClinicalDataType.K10:
        case ClinicalDataType.RAND36:
        case ClinicalDataType.NPRS:
        case ClinicalDataType.OREBRO:
        case ClinicalDataType.GRCS:
        case ClinicalDataType.PSFS:
        case ClinicalDataType.EPDS:
        case ClinicalDataType.DASS21:
        case ClinicalDataType.DASH:
          return measurementType;
        default:
          return ClinicalDataType.GeneralExamination;
      }
    })();

    clinical.ui.setPatientClinicalContent({
      type
    });
  };
};

export const isBloodPressureOrPulse = (primaryKey: string): boolean => {
  return (
    primaryKey === ObservationsLabels.BloodPressure ||
    primaryKey === ObservationsLabels.PulseBpmInTreeView
  );
};

export const formatTimeWithoutSeconds = (timeString?: string): string => {
  return timeString ? timeString.replace(/:\d{2} /, " ") : "";
};
