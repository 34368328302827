import { Field } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  Slider,
  SpinButton,
  Stack,
  Text
} from "@bps/fluent-ui";
import { QuestionnaireDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SliderField } from "@ui-components/form/SliderField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import {
  ClinicalToolAnswer,
  findClinicalToolAnswer,
  getSliderConstraints
} from "../utils/ClinicalToolAnswerFinder.ts";
import { MAX_INPUT } from "../utils/clinicalToolsUtils.ts";

interface NPRSQuestionProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
}

export const NPRSQuestions: React.FunctionComponent<NPRSQuestionProps> = ({
  questionnaire,
  answers
}) => {
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const getAnswer = (questionId: string, value: string): string | undefined => {
    if (value === "") return;

    const question = questionnaire.items.find(q => {
      return q.id === Number(questionId.replace("q", ""));
    })!;
    if (question) {
      const option = question.answerOptions?.find(
        o => Number(o.value) === Number(value)
      );
      if (option) {
        return option.text;
      }
    }
    return undefined;
  };

  return (
    <Stack
      tokens={{ childrenGap: 24 }}
      styles={{ root: { overflowY: "auto", paddingRight: 8 } }}
    >
      <Stack.Item>
        Indicate the intensity of current, best, and worse pain levels over the
        past 24 hours on a scale of 0 (no pain) to 10 (worst pain imaginable).
      </Stack.Item>
      {questionnaire.items.map(item => {
        const constraints = getSliderConstraints(item);
        const elementName = `q${item.id}`;
        const readyAnswer = findClinicalToolAnswer(elementName, answers);
        return (
          <Stack key={item.id} horizontal tokens={{ childrenGap: 8 }}>
            {!isViewOnlyOrDischarged && (
              <Stack.Item styles={{ root: { width: "100%" } }}>
                {answers && readyAnswer ? (
                  <Slider
                    label={item.text}
                    disabled={true}
                    value={Number(readyAnswer.answerValue)}
                    showValue={false}
                  />
                ) : (
                  <SliderField
                    name={elementName}
                    {...constraints}
                    label={item.text}
                    snapToStep
                  />
                )}
              </Stack.Item>
            )}
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
              tokens={{ childrenGap: 8 }}
            >
              <Stack.Item>
                {answers && readyAnswer ? (
                  <SpinButton
                    disabled={true}
                    value={readyAnswer.answerValue}
                    styles={{ root: { maxWidth: 82 } }}
                    {...dataAttribute(DataAttributes.Element, elementName)}
                  />
                ) : (
                  <SpinNumberInputField
                    name={elementName}
                    styles={{ spinButtonWrapper: { maxWidth: 82 } }}
                    {...constraints}
                    {...dataAttribute(DataAttributes.Element, elementName)}
                    parse={value =>
                      Number(value) > MAX_INPUT ? MAX_INPUT : value
                    }
                  />
                )}
              </Stack.Item>
              <Stack.Item styles={{ root: { minWidth: 65 } }}>
                {answers && readyAnswer ? (
                  <Text>{readyAnswer.answerText}</Text>
                ) : (
                  <Field name={elementName} subscription={{ value: true }}>
                    {({ input }) => (
                      <Text>{getAnswer(input.name, input.value)}</Text>
                    )}
                  </Field>
                )}
              </Stack.Item>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
