import { CSSProperties } from "react";

import {
  FontIcon,
  FontSizes,
  FontWeights,
  IStyle,
  mergeStyles,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";

export interface ClaimStatusBadgeBaseProps {
  displayIcon: boolean;
  badgeColor: TextBadgeColor;
  hasBorder: boolean;
  icon: string;
  textColor?: string;
  text?: string;
  styles?: {
    root?: IStyle;
    badge?: IStyle;
    icon?: CSSProperties;
  };
  badgeSize?: TextBadgeSize;
}

export const ClaimStatusBadgeBase: React.FC<
  ClaimStatusBadgeBaseProps
> = props => {
  const theme = useTheme();

  const wrapBadge = (children: JSX.Element) => (
    <Stack
      horizontal
      horizontalAlign="center"
      styles={{ root: props.styles?.root }}
    >
      {children}
    </Stack>
  );

  if (props.displayIcon) {
    const badgeStyles = mergeStyles(
      {
        width: 105,
        justifyContent: "center",
        color: theme.palette.neutralSecondary,
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.regular
      },
      props.styles?.badge
    );

    return wrapBadge(
      <Stack horizontal styles={{ root: badgeStyles }}>
        <FontIcon
          iconName={props.icon}
          styles={{
            root: {
              color: props.textColor,
              marginRight: 8,
              fontSize: FontSizes.size18,
              ...props.styles?.icon
            }
          }}
        />
        {props.text}
      </Stack>
    );
  }

  const badgeStyles = mergeStyles(
    {
      width: 105,
      padding: "6px 11px"
    },
    props.styles?.badge
  );

  return wrapBadge(
    <TextBadge
      badgeColor={props.badgeColor}
      badgeSize={props.badgeSize}
      hasBorder={props.hasBorder}
      styles={{ root: badgeStyles }}
    >
      {props.text}
    </TextBadge>
  );
};
