import { action, observable } from "mobx";

import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { NonCustomTemplateName } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { FormsStore } from "@stores/forms/FormsStore.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

export class ClaimsScreenHelper {
  private forms: FormsStore;

  constructor(private root: RootStore) {
    this.forms = root.forms;
  }

  @observable public deletingClaim: Claim | undefined;

  @observable disableBulkLodge = true;
  @observable selectionCount = 0;

  selection: Selection = new Selection({
    onSelectionChanged: () => {
      const selectedItems = this.selection.getSelection() as Claim[];
      const selectedCount = selectedItems.length;

      const canBeLodged = selectedItems.every(
        x =>
          x.claimStatus === ClaimStatuses.Ready ||
          x.claimStatus === ClaimStatuses.Error
      );

      this.setDisableBulkLodge(selectedCount === 0 || !canBeLodged);
      this.setSelectionCount(selectedCount);
    }
  });

  @action
  setDeletingClaim = (claim: Claim | undefined) => {
    this.deletingClaim = claim;
  };

  @action
  setDisableBulkLodge = (disableBulkLodge: boolean) => {
    this.disableBulkLodge = disableBulkLodge;
  };

  @action
  setSelectionCount = (selectionCount: number) => {
    this.selectionCount = selectionCount;
  };

  public previewAcc45PatientClaim = async (claimId: string) => {
    try {
      this.root.notification.warn(notificationMessages.formIsBeingPrinted);

      const templates = await this.root.document.getTemplates({
        name: NonCustomTemplateName.ACC45Claim
      });

      const template = templates.find(
        x => x.name === NonCustomTemplateName.ACC45Claim
      );

      const claim = await this.root.acc.getClaim(claimId);

      if (template) {
        const result = await this.root.document.renderTemplate(template.id, {
          contentType: DocumentContentType.Pdf,

          context: {
            ClaimId: claimId
          }
        });
        if (claim.patientId) {
          this.root.acc.ui.setClaimPreviewDocumentData({
            show: true,
            documentId: template.id,
            patientId: claim.patientId,
            templateUriContent: result.content
          });
        }
      }
    } catch (e) {
      this.root.notification.error(e.message);
    }
  };
}
