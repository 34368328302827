import { FunctionComponent } from "react";
import { Field } from "react-final-form";

import { ITag } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  CalendarEventType,
  Frequency
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { toRecurrenceOptions } from "@libs/utils/calendar/calendar.utils.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ContactPickerFieldWithAdd } from "@modules/practice/screens/shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { PatientActivation } from "@modules/practice/screens/shared-components/PatientActivation.tsx";
import { Labels } from "@modules/practice/screens/shared-components/types/labels.enums.types.ts";
import { getFrequencyKeyTextOptions } from "@modules/settings/screens/users/components/utils.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { WeekdaysDropdownField } from "@ui-components/form/WeekdaysDropdownField.tsx";

import { isAfter, isMonth, isOnDate, isWeek, isYear } from "../../utils.tsx";
import {
  RecurrenceFormLabels,
  RecurrenceFormValues
} from "./RecurrenceForm.types.tsx";

const showPatientPicker = (value: CalendarEventType) =>
  value && value !== CalendarEventType.Unavailable;

const nameOf = nameOfFactory<RecurrenceFormValues>();

export const RecurrenceFormFields: FunctionComponent = () => {
  const dataSource = (): ITag[] => {
    return [
      {
        key: CalendarEventType.Unavailable,
        name: "Unavailable"
      }
    ];
  };

  return (
    <Fieldset>
      <TextInputField
        label={RecurrenceFormLabels.purpose}
        name={nameOf("purpose")}
      />
      <FieldCondition when={nameOf("calendarEventType")} is={showPatientPicker}>
        <ContactPickerFieldWithAdd
          name={nameOf("patientId")}
          quickAddProps={{
            addButtonLabel: Labels.newPatient,
            typeToAdd: ContactType.Patient,
            permission: [
              Permission.ContactRead,
              Permission.ContactWrite,
              Permission.PatientRead,
              Permission.PatientWrite
            ]
          }}
          filter={{ types: [ContactType.Patient] }}
          actionButton={{
            linkProps: {
              children: (
                <Field<string | undefined> name={nameOf("patientId")}>
                  {props => {
                    if (!props.input.value) {
                      return null;
                    }
                    return <PatientActivation patientId={props.input.value} />;
                  }}
                </Field>
              )
            }
          }}
        />
      </FieldCondition>

      <Field<string | undefined> name={nameOf("patientId")}>
        {props => {
          if (!props.input.value) {
            return null;
          }
          return <PatientActivation patientId={props.input.value} />;
        }}
      </Field>
      <Fieldset horizontal>
        <StaticPickerField
          name={nameOf("calendarEventType")}
          label={RecurrenceFormLabels.type}
          fetchDataSource={dataSource}
          fieldItemStyles={{ root: { flexGrow: 1 } }}
        />
      </Fieldset>

      <Fieldset horizontal>
        <DatePickerField
          label={RecurrenceFormLabels.startSchedule}
          name={nameOf("startDate")}
        />
        <DropdownField
          label={RecurrenceFormLabels.endSchedule}
          styles={{ root: { flexGrow: 1 } }}
          name={nameOf("endScheduleType")}
          options={[
            { key: "ondate", text: "On date" },
            { key: "after", text: "After" },
            { key: "never", text: "Never" }
          ]}
        />
        <FieldCondition when={nameOf("endScheduleType")} is={isOnDate}>
          <DatePickerField
            styles={{ root: { flexGrow: 1 } }}
            placeholder="Until"
            name={nameOf("until")}
            label="&nbsp;"
          />
        </FieldCondition>
        <FieldCondition when={nameOf("endScheduleType")} is={isAfter}>
          <Field name={nameOf("count")} subscription={{ value: true }}>
            {({ input }) => {
              return (
                <SpinNumberInputField
                  label={
                    input.value > 1
                      ? `${RecurrenceFormLabels.occurrence}s`
                      : RecurrenceFormLabels.occurrence
                  }
                  name={nameOf("count")}
                  min={1}
                  max={99}
                  step={1}
                  fieldItemStyles={{
                    itemWrapper: {
                      alignItems: "baseline"
                    },
                    item: { flexGrow: 0 }
                  }}
                  styles={{ spinButtonWrapper: { width: 120 } }}
                />
              );
            }}
          </Field>
        </FieldCondition>
      </Fieldset>

      <Fieldset horizontal>
        <TimePickerField
          label={RecurrenceFormLabels.startTime}
          suggestionInterval={30}
          name={nameOf("startTime")}
        />
        <TimePickerField
          label={RecurrenceFormLabels.endTime}
          suggestionInterval={30}
          name={nameOf("endTime")}
        />

        <SpinNumberInputField
          label={RecurrenceFormLabels.recurEvery}
          name={nameOf("interval")}
          min={0}
          max={99}
          step={1}
          fieldItemStyles={{
            itemWrapper: { alignItems: "baseline" },
            item: { flexGrow: 0 }
          }}
          // SpinNumberInput component has a min width of 86
          styles={{ spinButtonWrapper: { width: 86 } }}
        />

        <Field name={nameOf("interval")} subscription={{ value: true }}>
          {({ input }) => (
            <DropdownField
              styles={{ root: { flexGrow: 1 } }}
              label="&nbsp;"
              name={nameOf("frequency")}
              options={getFrequencyKeyTextOptions(input.value)}
            />
          )}
        </Field>
      </Fieldset>
      <Fieldset>
        <FieldCondition when={nameOf("frequency")} is={isWeek}>
          <WeekdaysDropdownField
            styles={{ root: { flexGrow: 1 } }}
            required
            name={nameOf("dayRecur")}
            label={RecurrenceFormLabels.on}
          />
        </FieldCondition>

        <FieldCondition when={nameOf("frequency")} is={isMonth}>
          <Field name={nameOf("startDate")} subscription={{ value: true }}>
            {({ input }) => {
              return (
                <DropdownField
                  styles={{ root: { flexGrow: 1 } }}
                  required
                  label="On"
                  name={nameOf("monthYearRecurrence")}
                  options={toRecurrenceOptions(
                    DateTime.fromISO(input.value),
                    Frequency.Month
                  )}
                />
              );
            }}
          </Field>
        </FieldCondition>
        <FieldCondition when={nameOf("frequency")} is={isYear}>
          <Field name={nameOf("startDate")} subscription={{ value: true }}>
            {({ input }) => {
              return (
                <DropdownField
                  styles={{ root: { flexGrow: 1 } }}
                  required
                  label="On"
                  name={nameOf("monthYearRecurrence")}
                  options={toRecurrenceOptions(
                    DateTime.fromISO(input.value),
                    Frequency.Year
                  )}
                />
              );
            }}
          </Field>
        </FieldCondition>
      </Fieldset>
    </Fieldset>
  );
};
