import { useState } from "react";
import { useField, useForm } from "react-final-form";

import { Checkbox, Stack, toKebabCase } from "@bps/fluent-ui";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

interface OtherTreatmentFieldProps {
  name: string;
  label: string;
}

export const OtherTreatmentField: React.FC<OtherTreatmentFieldProps> = ({
  name,
  label
}) => {
  const {
    input: { value: spinValue }
  } = useField(`${name}`, {
    subscription: { value: true }
  });

  const { change } = useForm();
  const [checked, setChecked] = useState(!!spinValue);

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Checkbox
        automationAttribute={toKebabCase(`${label}-checkbox`)}
        label={label}
        checked={checked}
        onChange={() => {
          if (checked) {
            change(name, undefined);
            setChecked(prev => !prev);
          } else {
            change(name, 1);
            setChecked(prev => !prev);
          }
        }}
      />
      {checked && (
        <SpinNumberInputField
          styles={{
            root: { marginLeft: 28 },
            spinButtonWrapper: { width: 110 }
          }}
          parse={value => Number(value)}
          max={10}
          min={1}
          name={name}
        />
      )}
    </Stack>
  );
};
