import { FilterBarProps } from "@bps/fluent-ui";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

import { EventType, EventVerb } from "./RecentPatientsList.types.ts";

type RecentPatientsFilterProps = Pick<
  FilterBarProps<RecentPatientsFilterState>,
  "children"
>;

interface RecentPatientsFilterState {
  patientSearch?: string;
  eventCodes?: string[];
  incompleteNotesOnly?: string;
}

export const options = [
  {
    key: EventType.consult.concat(EventVerb.encounterClosed),
    text: "Consult (finalised)"
  },
  {
    key: EventType.consult.concat(EventVerb.encounterStarted),
    text: "Consult (incomplete)"
  },
  { key: EventType.appointment.concat(EventVerb.create), text: "Appointment" },
  { key: EventType.invoice.concat(EventVerb.create), text: "Invoice" },
  {
    key: EventType.recordUpdate.concat(EventVerb.encounterClosed),
    text: "Record update"
  }
];

export const RecentPatientsFilter: React.FC<RecentPatientsFilterProps> = ({
  children
}) => {
  return (
    <FilterBar<RecentPatientsFilterState>
      items={[
        {
          type: "searchBox",
          name: "patientSearch",
          stickItem: true,
          props: {
            id: "recent-patients-search",
            placeholder: "Search for patient",
            styles: { root: { maxWidth: 250, minWidth: 250 } }
          }
        },
        {
          type: "optionsSelect",
          name: "eventCodes",
          props: {
            id: "recent-patients-events",
            multiSelect: true,
            hideSearchOption: true,
            options,
            placeholder: "Event",
            calloutWidth: 200
          }
        }
      ]}
      presets={[
        {
          text: "Incomplete notes",
          name: "incompleteNotesOnly",
          id: "recent-patients-incomplete",
          iconName: "BpDocEdit",
          tooltip: "Only show Incomplete notes",
          tooltipPresses: "Only showing Incomplete notes",
          valuesToBeSetOnToggleOn: {
            eventCodes: [EventType.consult.concat(EventVerb.encounterStarted)]
          },
          valuesToBeSetOnToggleOff: {
            eventCodes: []
          }
        }
      ]}
    >
      {children}
    </FilterBar>
  );
};
