import { useForm, useFormState } from "react-final-form";

import {
  Accordion,
  AccordionItem,
  FontIcon,
  FontSizes,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { NO, YES } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { FormItemField } from "@ui-components/form/FormItemField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";

import { SleepFormValues } from "./SleepFormValues.ts";

export const SleepFormFields: React.FC = () => {
  const nameOf = nameOfFactory<SleepFormValues>();
  const { clinical } = useStores();

  const theme = useTheme();

  const form = useForm();
  const { isViewOnly } = usePatientRecordScreenContext();

  const getButtonOptions = [
    { key: YES, text: "Yes" },
    { key: NO, text: "No" }
  ];

  const itemStyle = {
    root: { fontSize: "18px", borderTop: 0, paddingTop: 8 },
    content: {
      backgroundColor: theme.semanticColors.bodyBackground,
      borderTop: 0,
      paddingBottom: 16
    },
    contentWrapper: { padding: 0 },
    heading: { borderTop: 0, borderBottom: 0, paddingBottom: 8 }
  };

  const { values } = useFormState<SleepFormValues>({
    subscription: { values: true }
  });

  const renderTitle = (title: string, showModified: boolean) => {
    return (
      <Text variant="large" bold>
        {title}

        {showModified && !isViewOnly && (
          <FontIcon
            iconName="PageEdit"
            styles={{
              root: { fontSize: FontSizes.size12, paddingLeft: "8px" }
            }}
          />
        )}
      </Text>
    );
  };

  const generalModified = () => {
    return (
      values.sleepQuality !== undefined || values.sleepPosition !== undefined
    );
  };

  const sleepHabitsModified = () => {
    return (
      (values.numOfHours !== undefined && values.numOfHours > 0) ||
      values.sleepTime !== undefined ||
      values.wakeSameTime !== undefined ||
      values.weekendDiffer !== undefined ||
      values.snore !== undefined ||
      values.electronicDevices !== undefined
    );
  };

  const environmentModified = () => {
    return (
      values.environment !== undefined ||
      values.disrupted !== undefined ||
      values.comfortable !== undefined
    );
  };

  const thoughtsModified = () => {
    return (
      values.troubleFallingAsleep !== undefined ||
      values.sleepDisturbances !== undefined ||
      values.wakeDuringNight !== undefined ||
      values.troubleGoingBackToSleep !== undefined ||
      values.wakeEarly !== undefined
    );
  };

  const dayTimeModified = () => {
    return (
      values.restedWhenAwake !== undefined ||
      values.daytimeSleepiness !== undefined ||
      values.concentrationAndMemory !== undefined
    );
  };

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <Accordion multiple styles={{ root: { borderLeft: 0 } }}>
        <AccordionItem
          title="General"
          styles={itemStyle}
          extendedByDefault
          onRenderTitle={x => renderTitle(x, generalModified())}
        >
          <Fieldset>
            <ButtonsGroupSingleChoiceField
              label="Sleep quality"
              options={clinical.ref.sleepQualities.keyTextValues}
              name={nameOf("sleepQuality")}
            />
            <ButtonsGroupSingleChoiceField
              label="Sleep position"
              options={clinical.ref.sleepPositions.keyTextValues}
              name={nameOf("sleepPosition")}
            />
          </Fieldset>
        </AccordionItem>
        <AccordionItem
          title="Sleep habits"
          styles={itemStyle}
          onRenderTitle={x => renderTitle(x, sleepHabitsModified())}
        >
          <Fieldset>
            <SpinNumberInputField
              name={nameOf("numOfHours")}
              label="How many hours on average do you get per night?"
              styles={{ spinButtonWrapper: { width: 100 } }}
              max={24}
              min={0}
            />
            <ButtonsGroupSingleChoiceField
              label="Do you go to sleep at a regular time?"
              options={clinical.ref.sleepTimes.keyTextValues}
              name={nameOf("sleepTime")}
            />
            <FormItemField
              name={nameOf("wakeSameTime")}
              label="Do you wake up at the same time each day?"
            >
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <ButtonsGroupSingleChoiceField
                  options={getButtonOptions}
                  name={nameOf("wakeSameTime")}
                />
                <FieldSpy
                  name={nameOf("wakeSameTime")}
                  onChange={value => {
                    if (value === NO) {
                      form.change(nameOf("wakeTime"), undefined);
                    }
                  }}
                />
                <FieldCondition when={nameOf("wakeSameTime")} is={YES}>
                  <FormItemField name={nameOf("wakeTime")} label="What time?" />
                  <TimePickerField
                    suggestionInterval={15}
                    name={nameOf("wakeTime")}
                  />
                </FieldCondition>
              </Stack>
            </FormItemField>

            <ButtonsGroupSingleChoiceField
              label="Does your sleep schedule differ on the weekend?"
              options={getButtonOptions}
              name={nameOf("weekendDiffer")}
            />

            <FieldSpy
              name={nameOf("weekendDiffer")}
              onChange={value => {
                if (value === NO) {
                  form.change(nameOf("weekendDifferComment"), undefined);
                }
              }}
            />
            <FieldCondition when={nameOf("weekendDiffer")} is={YES}>
              <TextInputField
                label="How does it differ?"
                name={nameOf("weekendDifferComment")}
                multiline={true}
                rows={3}
                autoAdjustHeight
                resizable={false}
                styles={{
                  fieldGroup: { minHeight: 60, width: 509 }
                }}
              />
            </FieldCondition>
            <ButtonsGroupSingleChoiceField
              label="Do you snore?"
              options={clinical.ref.snoreOptions.keyTextValues}
              name={nameOf("snore")}
            />
            <ButtonsGroupSingleChoiceField
              label="Do you use electronic devices within 1 hour of going to sleep?"
              options={getButtonOptions}
              name={nameOf("electronicDevices")}
            />
          </Fieldset>
        </AccordionItem>
        <AccordionItem
          title="Environment"
          styles={itemStyle}
          onRenderTitle={x => renderTitle(x, environmentModified())}
        >
          <Fieldset>
            <TextInputField
              label="How is the environment in your room?"
              name={nameOf("environment")}
              readOnly={isViewOnly}
              multiline={true}
              rows={3}
              autoAdjustHeight
              resizable={false}
              styles={{
                fieldGroup: { minHeight: 60, width: 509 }
              }}
            />
            <ButtonsGroupSingleChoiceField
              label="Are you disrupted by your partner / kids / pet?"
              options={getButtonOptions}
              name={nameOf("disrupted")}
            />
            <ButtonsGroupSingleChoiceField
              label="Is your bedroom comfortable for sleeping (temperature, brightness, noise)?"
              options={getButtonOptions}
              name={nameOf("comfortable")}
            />
            <FieldSpy
              name={nameOf("comfortable")}
              onChange={value => {
                if (value === YES) {
                  form.change(nameOf("comfortableComment"), undefined);
                }
              }}
            />
            <FieldCondition when={nameOf("comfortable")} is={NO}>
              <TextInputField
                label="Describe what makes it uncomfortable?"
                name={nameOf("comfortableComment")}
                multiline={true}
                rows={3}
                autoAdjustHeight
                resizable={false}
                styles={{
                  fieldGroup: { minHeight: 60, width: 509 }
                }}
              />
            </FieldCondition>
          </Fieldset>
        </AccordionItem>
        <AccordionItem
          title="Thoughts or worries at night"
          styles={itemStyle}
          onRenderTitle={x => renderTitle(x, thoughtsModified())}
        >
          <Fieldset>
            <ButtonsGroupSingleChoiceField
              label="Do you have trouble falling asleep?"
              options={getButtonOptions}
              name={nameOf("troubleFallingAsleep")}
            />
            <FieldSpy
              name={nameOf("troubleFallingAsleep")}
              onChange={value => {
                if (value === NO) {
                  form.batch(() => {
                    form.change(nameOf("stopsSleep"), undefined);
                    form.change(nameOf("whatHelpsSleep"), undefined);
                  });
                }
              }}
            />
            <FieldCondition when={nameOf("troubleFallingAsleep")} is={YES}>
              <TextInputField
                label="What stops you falling asleep?"
                name={nameOf("stopsSleep")}
                multiline={true}
                rows={3}
                autoAdjustHeight
                resizable={false}
                styles={{
                  fieldGroup: { minHeight: 60, width: 509 }
                }}
              />
              <TextInputField
                label="What do you do to help yourself fall asleep?"
                name={nameOf("whatHelpsSleep")}
                multiline={true}
                rows={3}
                autoAdjustHeight
                resizable={false}
                styles={{
                  fieldGroup: { minHeight: 60, width: 509 }
                }}
              />
            </FieldCondition>

            <ButtonsGroupSingleChoiceField
              label="Do you experience frequent sleep disturbances?"
              options={getButtonOptions}
              name={nameOf("sleepDisturbances")}
            />
            <FieldSpy
              name={nameOf("sleepDisturbances")}
              onChange={value => {
                if (value === NO) {
                  form.change(nameOf("kindOfDisturbances"), undefined);
                }
              }}
            />

            <FieldCondition when={nameOf("sleepDisturbances")} is={YES}>
              <TextInputField
                label="What kind of disturbances?"
                name={nameOf("kindOfDisturbances")}
                multiline={true}
                rows={3}
                autoAdjustHeight
                resizable={false}
                styles={{
                  fieldGroup: { minHeight: 60, width: 509 }
                }}
              />
            </FieldCondition>
            <FormItemField
              name={nameOf("wakeDuringNight")}
              label="Do you wake throughout the night?"
            >
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <ButtonsGroupSingleChoiceField
                  options={getButtonOptions}
                  name={nameOf("wakeDuringNight")}
                />
                <FieldSpy
                  name={nameOf("wakeDuringNight")}
                  onChange={value => {
                    if (value === NO) {
                      form.change(nameOf("timesWakeDuringNight"), undefined);
                    }
                  }}
                />
                <FieldCondition when={nameOf("wakeDuringNight")} is={YES}>
                  <FormItemField
                    name={nameOf("timesWakeDuringNight")}
                    label="How many times?"
                  />
                  <SpinNumberInputField
                    name={nameOf("timesWakeDuringNight")}
                    styles={{ spinButtonWrapper: { width: 100 } }}
                  />
                </FieldCondition>
              </Stack>
            </FormItemField>
            <ButtonsGroupSingleChoiceField
              label="Do you have trouble going back to sleep?"
              options={getButtonOptions}
              name={nameOf("troubleGoingBackToSleep")}
            />
            <ButtonsGroupSingleChoiceField
              label="Do you wake up earlier than intended?"
              options={getButtonOptions}
              name={nameOf("wakeEarly")}
            />
            <FieldSpy
              name={nameOf("wakeEarly")}
              onChange={value => {
                if (value === NO) {
                  form.batch(() => {
                    form.change(nameOf("wakeEarlyAtSameTime"), undefined);
                    form.change(nameOf("wakeEarlyTime"), undefined);
                  });
                }
              }}
            />

            <FieldCondition when={nameOf("wakeEarly")} is={YES}>
              <FormItemField
                name={nameOf("wakeEarlyAtSameTime")}
                label="Does this happen at a regular time?"
              >
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                  <ButtonsGroupSingleChoiceField
                    options={getButtonOptions}
                    name={nameOf("wakeEarlyAtSameTime")}
                  />
                  <FieldSpy
                    name={nameOf("wakeEarlyAtSameTime")}
                    onChange={value => {
                      if (value === NO) {
                        form.change(nameOf("wakeEarlyTime"), undefined);
                      }
                    }}
                  />
                  <FieldCondition when={nameOf("wakeEarlyAtSameTime")} is={YES}>
                    <TimePickerField
                      suggestionInterval={15}
                      name={nameOf("wakeEarlyTime")}
                    />
                  </FieldCondition>
                </Stack>
              </FormItemField>
            </FieldCondition>
          </Fieldset>
        </AccordionItem>
        <AccordionItem
          title="Daytime functioning"
          styles={itemStyle}
          onRenderTitle={x => renderTitle(x, dayTimeModified())}
        >
          <Fieldset>
            <ButtonsGroupSingleChoiceField
              label="Do you feel rested when awake?"
              options={getButtonOptions}
              name={nameOf("restedWhenAwake")}
            />
            <ButtonsGroupSingleChoiceField
              label="Do you suffer from daytime sleepiness?"
              options={getButtonOptions}
              name={nameOf("daytimeSleepiness")}
            />
            <ButtonsGroupSingleChoiceField
              label="Do you have problems with concentration and memory?"
              options={getButtonOptions}
              name={nameOf("concentrationAndMemory")}
            />
          </Fieldset>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};
