import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { Stack, TopBarDefaultHeader } from "@bps/fluent-ui";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimPivotTabs } from "@modules/acc/screens/shared-components/ClaimPivotTabs.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { PromiseObservableButton } from "@ui-components/PromiseObservableButton/PromiseObservableButton.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { SubmitOptions } from "../../claim/types/claim.types.ts";

export const ClaimManagementFormHeader: FC = observer(() => {
  const { acc, notification, routing } = useStores();
  const claimContext = useContext(ClaimContext);

  const setSubmitOptions = (submitOptions: SubmitOptions) => {
    claimContext.submitOptions = submitOptions;
  };

  const handleLodgeOnClick = async () => {
    await acc.lodgeBulkAccClaims([claimContext.claim.id]);
    routing.push(routes.claims.basePath.pattern);
  };

  const queueClaimStatus = async () => {
    await acc.queueClaimStatus(claimContext.claim.id);
    notification.success("Claim status has been queued.");
  };

  const queueClaimStatusDisabled =
    claimContext.claim.claimStatus !== ClaimStatuses.Pending &&
    claimContext.claim.claimStatus !== ClaimStatuses.NotVerified &&
    claimContext.claim.claimStatus !== ClaimStatuses.GetStatusError;

  const rightContainer = (
    <When permission={Permission.ClaimWrite}>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <PromiseObservableButton
          hasSameButtonCalloutWidth
          primary
          text="Lodge"
          onClick={handleLodgeOnClick}
          disabled={
            claimContext.claim.claimStatus !== ClaimStatuses.Ready &&
            claimContext.claim.claimStatus !== ClaimStatuses.Error
          }
          borderless
          disableContextMenuButton={queueClaimStatusDisabled}
          items={[
            {
              key: "queueClaimStatus",
              text: "Get status",
              onClick: queueClaimStatus,
              disabled: queueClaimStatusDisabled
            }
          ]}
        />

        <FormSubmitButtons
          styles={{
            root: {
              marginTop: 0,
              borderTopColor: "none",
              backgroundColor: "inherit",
              paddingTop: 0
            }
          }}
          hideButtonsSeparator
          submitButtonProps={{
            onClick: () =>
              setSubmitOptions({
                redirect: false,
                isLodge: false
              }),
            iconProps: { hidden: true }
          }}
          disableSubmitOnPristine
          onCancel={() => claimContext.cancel()}
        />
      </Stack>
    </When>
  );

  return (
    <TopBarDefaultHeader
      hideBackButton
      leftContainer={<ClaimPivotTabs />}
      leftContainerStyles={{ root: { paddingLeft: 0 } }}
      rightContainer={rightContainer}
    />
  );
});
