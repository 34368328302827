import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect } from "react";

import {
  IconButton,
  PersonaSize,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { OrganisationCategoryText } from "@ui-components/RefText.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { OrganisationCardIds } from "../../../shared-components/types/organisation-card-ids.enum.ts";
import { AccountsInfoCard } from "../../patient/view/AccountsInfoCard.tsx";
import { CardWrapper } from "../../shared-components/view/CardWrapper.tsx";
import { ContactMethodList } from "../../shared-components/view/contact-method-list/ContactMethodList.tsx";
import { ContactLayoutWrapper } from "../../shared-components/view/ContactLayoutWrapper.tsx";
import { ContactRelationshipList } from "../../shared-components/view/ContactRelationshipList.tsx";
import { HeaderWrapper } from "../../shared-components/view/HeaderWrapper.tsx";
import { orgMenuItems } from "../edit/utils.ts";
import { OrganisationLabels } from "../organisation.types.ts";
import { OrganisationTypeList } from "./OrganisationTypeList.tsx";

export interface OrganisationDetailsProps {
  organisation: Contact;
}

export const OrganisationDetails: FunctionComponent<OrganisationDetailsProps> =
  observer(props => {
    const theme = useTheme();
    const { practice } = useStores();
    const {
      ui: { setSelectedEditCard, showEditContact, showContactDetails }
    } = practice;

    const { breakLongText, iconButtonStyles, personaStyle } =
      getPeopleScreenStylesSet(theme);

    useEffect(() => {
      return () => {
        setSelectedEditCard(OrganisationCardIds.OrganisationHeader);
      };
    }, [setSelectedEditCard]);

    const onHandleEditModal = (cardId: string) => {
      showEditContact(cardId, props.organisation.id);
    };

    const getPersonaSecondaryText =
      (organisation: Contact) => (): JSX.Element | null => {
        const partOfId = organisation.relationships
          .filter(x => x.relationship === RelationshipType.Managing)
          .map(x => x.relatedContactId)[0];

        const categories = organisation.categories.length > 0;

        return categories || partOfId ? (
          <Stack
            verticalAlign="center"
            verticalFill
            tokens={{ childrenGap: 24 }}
            styles={{ root: { ...breakLongText } }}
          >
            {organisation.categories.length &&
              ((
                <Stack.Item className="org-categories">
                  <OrganisationCategoryText code={organisation.categories} />
                </Stack.Item>
              ) ??
                Labels.noCategoryRecorded)}
            {partOfId && (
              <DataFetcher<Contact | undefined>
                key={partOfId}
                fetch={({ practice }) => practice.getContact(partOfId)}
              >
                {organisation => {
                  return (
                    organisation && (
                      <Stack
                        horizontal
                        styles={{ root: { marginTop: 24 } }}
                        verticalAlign="center"
                      >
                        <Text>
                          Linked to &nbsp;
                          <Navigate
                            id="linked-org-link"
                            onClick={() => showContactDetails(partOfId)}
                          >
                            {organisation.name}
                          </Navigate>
                          &nbsp;
                        </Text>
                        <TooltipHost
                          content={OrganisationLabels.GoToLinkedOrganisation}
                        >
                          <IconButton
                            id="linked-org-button"
                            iconProps={{ iconName: "Share" }}
                            styles={iconButtonStyles}
                            onClick={() => showContactDetails(partOfId)}
                          />
                        </TooltipHost>
                      </Stack>
                    )
                  );
                }}
              </DataFetcher>
            )}
          </Stack>
        ) : null;
      };

    const renderPrimaryText = (organisation: Contact) => (): JSX.Element => (
      <Stack>{organisation.name}</Stack>
    );

    const { organisation } = props;

    const isContactMethodsExist =
      organisation.addresses.length > 0 ||
      organisation.communications.length > 0;

    const isRelationshipsExist = organisation.relationships.length > 0;

    const organisationRolesExist = organisation.organisationRoles.length > 0;

    return (
      <ContactLayoutWrapper menuItems={orgMenuItems}>
        <Stack tokens={{ childrenGap: 16 }} grow>
          <HeaderWrapper
            contact={organisation}
            onHandleEditModal={onHandleEditModal}
            persona={
              <Persona
                id={organisation.id}
                contactType={organisation.type}
                size={PersonaSize.size100}
                imageInitials={organisation.initials}
                imageUrl={organisation.profilePictureUrl}
                styles={personaStyle}
                onRenderPrimaryText={renderPrimaryText(organisation)}
                onRenderSecondaryText={getPersonaSecondaryText(organisation)}
              />
            }
          />
          <>
            <CardWrapper
              showEditIcon={organisationRolesExist}
              title={Labels.organisationType}
              onButtonClick={onHandleEditModal}
              cardId={OrganisationCardIds.OrganisationType}
            >
              <OrganisationTypeList
                showEditIcon={organisationRolesExist}
                organisation={organisation}
                onHandleEditModal={onHandleEditModal}
              />
            </CardWrapper>
            <When permission={[Permission.AccountHistoryAllowed]}>
              <AccountsInfoCard
                contact={organisation}
                cardId={OrganisationCardIds.OrganisationAccount}
                onHandleEditModal={onHandleEditModal}
                hideStatement
              />
            </When>
            <CardWrapper
              showEditIcon={isContactMethodsExist}
              title={Labels.location}
              onButtonClick={onHandleEditModal}
              cardId={OrganisationCardIds.OrganisationLocation}
            >
              <ContactMethodList
                showEditIcon={isContactMethodsExist}
                patient={organisation}
                onHandleEditModal={onHandleEditModal}
                isOrganisation
              />
            </CardWrapper>
            <CardWrapper
              showEditIcon={isRelationshipsExist}
              title={Labels.people}
              onButtonClick={onHandleEditModal}
              cardId={OrganisationCardIds.OrganisationPeople}
              marginBottom={16}
            >
              <ContactRelationshipList
                showEditIcon={isRelationshipsExist}
                contact={organisation}
                onHandleEditModal={onHandleEditModal}
              />
            </CardWrapper>
          </>
        </Stack>
      </ContactLayoutWrapper>
    );
  });
