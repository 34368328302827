import { observer } from "mobx-react-lite";

import {
  FontIcon,
  Heading,
  ITooltipProps,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ErrorMessageDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { AccScheduleTransactionStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { AccSchedule } from "@stores/billing/models/AccSchedule.ts";

import { TransactionTransmissionStatusCellAuthorised } from "./TransactionTransmissionStatusCellAuthorised.tsx";
import { extractIndexAndField } from "./utils.ts";

const errorTextMapper: { [key: string]: string } = {
  invoiceNumber: "invoice number",
  serviceCodes: "service code",
  providerId: "provider Id",
  declarationDate: "declaration date",
  purchaseOrderNumber: "PO number"
};

export const TransactionTransmissionStatusCell: React.FC<{
  accSchedule: AccSchedule;
  showPartPaidIcon?: boolean;
}> = observer(({ accSchedule, showPartPaidIcon }) => {
  const theme = useTheme();

  const { transactionStatus, submittedDate, errorMessages } = accSchedule;

  let badgeColor: TextBadgeColor | undefined,
    hasBorder: boolean | undefined,
    label: string | undefined,
    iconName: string | undefined;

  switch (transactionStatus) {
    case AccScheduleTransactionStatuses.Ready:
      badgeColor = TextBadgeColor.yellow;
      label = "Ready";
      break;
    case AccScheduleTransactionStatuses.NotReady:
      badgeColor = TextBadgeColor.lightGrey;
      label = "Not ready";
      break;
    case AccScheduleTransactionStatuses.Queued:
      badgeColor = TextBadgeColor.lightGrey;
      label = "Queued";
      break;
    case AccScheduleTransactionStatuses.SentToAR:
      badgeColor = TextBadgeColor.grey;
      label = "A/R";
      break;
    case AccScheduleTransactionStatuses.Cancelled:
      badgeColor = TextBadgeColor.grey;
      label = "Cancelled";
      break;
    case AccScheduleTransactionStatuses.Paid:
      badgeColor = TextBadgeColor.green;
      label = "Paid";
      iconName = "lock";
      break;
    case AccScheduleTransactionStatuses.PartiallyPaid:
      badgeColor = TextBadgeColor.red;
      label = "Part-paid";
      iconName = "lock";
      break;
    case AccScheduleTransactionStatuses.Incomplete:
      badgeColor = TextBadgeColor.yellow;
      label = "Incomplete";
      break;
    case AccScheduleTransactionStatuses.Invalid:
      badgeColor = TextBadgeColor.lightGrey;
      label = "Invalid";
      hasBorder = true;
      break;
    case AccScheduleTransactionStatuses.InException:
      badgeColor = TextBadgeColor.red;
      label = "Exception";
      hasBorder = true;
      break;
    case AccScheduleTransactionStatuses.Pending:
      label = "Pending";
      iconName = "HourGlass";
      break;
    case AccScheduleTransactionStatuses.CompleteAndAwaitingValidation:
      label = "Validating";
      iconName = "HourGlass";
      break;
    case AccScheduleTransactionStatuses.AwaitingAuthorisation:
      badgeColor = TextBadgeColor.red;
      label = "Awaiting auth";
      iconName = "Info";
      break;
  }

  const tooltipProps: ITooltipProps = {
    calloutProps: {
      styles: {
        beak: { background: theme.semanticColors.errorBackground },
        beakCurtain: { background: theme.semanticColors.errorBackground },
        calloutMain: { background: theme.semanticColors.errorBackground }
      }
    }
  };

  if (
    transactionStatus === AccScheduleTransactionStatuses.PartiallyPaid &&
    !showPartPaidIcon
  ) {
    return (
      <TooltipHost
        tooltipProps={tooltipProps}
        content="Unpaid: ACC have a query on this invoice, as per their comment"
      >
        <TextBadge
          badgeSize={TextBadgeSize.medium}
          badgeColor={TextBadgeColor.red}
          horizontal
          horizontalAlign="center"
          tokens={{ childrenGap: 4 }}
        >
          <FontIcon iconName="info" styles={{ root: { fontSize: 16 } }} />
          <span>{label}</span>
        </TextBadge>
      </TooltipHost>
    );
  }

  if (
    transactionStatus === AccScheduleTransactionStatuses.AwaitingAuthorisation
  ) {
    return (
      <TooltipHost
        tooltipProps={tooltipProps}
        content={
          <Stack horizontalAlign="center">
            <Heading
              styles={{ root: { color: theme.semanticColors.errorText } }}
            >
              Awaiting authorisation
            </Heading>

            <Text>
              This state is reflecting internal ACC processing. Invoice is
              waiting upon authorisation within ACC.
            </Text>
          </Stack>
        }
      >
        <TextBadge
          badgeSize={TextBadgeSize.medium}
          badgeColor={badgeColor}
          hasBorder={hasBorder}
          horizontal
          horizontalAlign="center"
        >
          {iconName && (
            <FontIcon
              iconName={iconName}
              styles={{ root: { marginRight: 4, fontSize: 16 } }}
            />
          )}
          {label}
        </TextBadge>
      </TooltipHost>
    );
  }

  if (label) {
    // https://dev.azure.com/bpcloud-dev/BpCloud/_workitems/edit/29211
    const iconSize = iconName === "lock" ? 15 : 16;

    return (
      <TextBadge
        badgeSize={TextBadgeSize.medium}
        badgeColor={badgeColor}
        hasBorder={hasBorder}
        horizontal
        horizontalAlign="center"
      >
        {iconName && (
          <FontIcon
            iconName={iconName}
            styles={{ root: { marginRight: 4, fontSize: iconSize } }}
          />
        )}
        {label}
      </TextBadge>
    );
  }

  const fieldJSX = (msg: ErrorMessageDto) => {
    if (msg.field) {
      const { fieldName, index } = extractIndexAndField(msg.field);
      return (
        <>
          {" "}
          <Text>
            There is an issue with {errorTextMapper[fieldName] ?? fieldName} of
            the item {index + 1}
          </Text>
        </>
      );
    }
    return null;
  };

  if (transactionStatus === AccScheduleTransactionStatuses.Error) {
    const submittedDateText = submittedDate
      ? DateTime.fromISO(submittedDate).toDayDefaultFormat()
      : undefined;
    return (
      <TooltipHost
        tooltipProps={tooltipProps}
        content={
          <Stack horizontalAlign="center">
            <Heading
              styles={{ root: { color: theme.semanticColors.errorText } }}
            >
              Error
            </Heading>
            {errorMessages?.map(msg => {
              return (
                <Stack key={JSON.stringify(msg)}>
                  <Text>{msg.message || ""}</Text>
                  <Text>{msg.details || ""}</Text>
                  {fieldJSX(msg)}
                </Stack>
              );
            })}
            {submittedDateText && <Text>Submitted: {submittedDateText}</Text>}
          </Stack>
        }
      >
        <TextBadge
          badgeSize={TextBadgeSize.medium}
          badgeColor={TextBadgeColor.red}
          horizontal
          horizontalAlign="center"
          tokens={{ childrenGap: 4 }}
        >
          <FontIcon iconName="info" styles={{ root: { fontSize: 16 } }} />
          <span>Error</span>
        </TextBadge>
      </TooltipHost>
    );
  }

  if (transactionStatus === AccScheduleTransactionStatuses.Authorised) {
    return <TransactionTransmissionStatusCellAuthorised label="Authorised" />;
  }

  if (label && badgeColor) {
    return (
      <TextBadge
        badgeSize={TextBadgeSize.medium}
        badgeColor={badgeColor}
        horizontal
        horizontalAlign="center"
      >
        {iconName && (
          <FontIcon iconName={iconName} styles={{ root: { marginRight: 4 } }} />
        )}
        {label}
      </TextBadge>
    );
  }

  return null;
});
