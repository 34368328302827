import { isEmpty } from "lodash";
import { action, observable, runInAction } from "mobx";

import { DateTime, newGuid } from "@bps/utils";
import {
  ClaimDto,
  ClaimStatuses,
  DiagnosisSides
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  AccClinicalTab,
  DiagnosisDataItemDto,
  SideOfBody,
  TerminologyConceptDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  AddressDto,
  AddressType,
  EmployerDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { isDeepEmptyUtils } from "@libs/utils/isDeepEmpty.utils.ts";
import { ClaimAddressValidator } from "@modules/acc/screens/claim-adjustment/validators/ClaimAddressValidator.ts";
import { getAddClaimManagementModalDto } from "@modules/acc/screens/claim-management/components/utils.tsx";
import {
  addNewClaim,
  convertToClaimPatient,
  getClaimFormValues,
  patchExistingClaim
} from "@modules/acc/screens/claim/components/utils.ts";
import {
  getDiagnoses,
  getMappedDiagnoses,
  getReasonForVisit
} from "@modules/clinical/screens/patient-record/components/SOTAP/SOTAP.utils.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { ClaimManagementModalFormValues } from "@shared-types/acc/claim-management-modal-values.type.ts";
import { DiagnosisSideData } from "@shared-types/clinical/diagnosis-side-data.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccStore } from "@stores/acc/AccStore.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { PurchaseOrder } from "@stores/acc/models/PurchaseOrder.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { EncounterClinicalData } from "@stores/clinical/models/EncounterClinicalData.ts";
import { User } from "@stores/core/models/User.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Provider } from "@stores/practice/models/Provider.ts";
import { FormApiWithMutators } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

import { isDiagnosisNotEmpty } from "../../utils.ts";
import { claimFormNameOf } from "../claim.utils.ts";
import { ClaimFormManagementValues } from "../types/claim-form-management-values.types.ts";
import { AllClaimFormValues, SubmitOptions } from "../types/claim.types.ts";
import { AccidentAndEmploymentDetailsFormValidator } from "../validators/AccidentAndEmploymentDetailsFormValidator.tsx";
import { ClaimExtraFieldsValidator } from "../validators/ClaimExtraFieldsValidator.tsx";
import { InjuryDiagnosisAndAssistanceFormValidator } from "../validators/InjuryDiagnosisAndAssistanceFormValidator.tsx";
import { PatientDeclarationFormValidator } from "../validators/PatientDeclarationFormValidator.tsx";
import { PersonalDetailsFormValidator } from "../validators/PersonalDetailsFormValidator.tsx";
import { ProviderDeclarationFormValidator } from "../validators/ProviderDeclarationFormValidator.tsx";
import { ReferralsOutFormValidator } from "../validators/ReferralsOutFormValidator.tsx";

export class ClaimHelper {
  constructor(
    private root: IRootStore,
    public claim: Claim,
    private options: {
      onSubmit?: (options: {
        values: ClaimFormValues | ClaimFormManagementValues;
        submitOptions: SubmitOptions;
        acc: AccStore;
        claimDtoValues?: Omit<ClaimDto, "id" | "eTag">;
        isReferral?: boolean;
      }) => Promise<string>;
      onSubmitSucceeded?: (claim: Claim, submitOptions?: SubmitOptions) => void;
      clinicalRecord?: ClinicalRecord;
    }
  ) {
    this._insurerContactId = claim.dto.insurerContactId;
    this.allocated = claim.dto.allocated;
    runInAction(() => {
      this.provider = claim.provider;
      this.providerUser = claim.user;
    });
  }

  private _submitOptions: SubmitOptions;
  private _insurerContactId: string | undefined;
  private _allocated: number | undefined;

  public get isAccDirty() {
    return (
      this.clinicalRecord &&
      this.root.clinical.ui.clinicalTabs.currentPatientRecordTab?.dirtyTabs.find(
        x => x.type === AccClinicalTab.Injury
      )?.isDirty
    );
  }

  @observable
  public provider: Provider | undefined;

  @observable
  public providerUser: User | undefined;

  @observable
  public hiddenClaimManagementModal: boolean = true;

  @observable
  public providerTypeCode?: string;

  public claimFormValues: ClaimFormValues | undefined;

  public get clinicalRecord() {
    return this.options.clinicalRecord;
  }

  @observable
  public claimPurchaseOrders: PurchaseOrder[];

  @action
  setPOs = (purchaseOrders: PurchaseOrder[]) => {
    this.claimPurchaseOrders = purchaseOrders;
  };

  public getClaimPurchaseOrders = async () => {
    const purchaseOrders = await this.root.acc.getPurchaseOrdersByClaimId(
      this.claim.id
    );
    this.setPOs(purchaseOrders);
    return purchaseOrders;
  };

  public get claimAdjustments() {
    return this.claim.claimAdjustments;
  }

  public getIsFormValid = (values: any) => {
    const personalDetailsFormValidator = new PersonalDetailsFormValidator();
    const patientDeclarationFormValidator =
      new PatientDeclarationFormValidator();

    const claimExtraFieldsValidator = new ClaimExtraFieldsValidator();
    const providerDeclarationFormValidator =
      new ProviderDeclarationFormValidator(this.root.core);

    const accidentAndEmploymentDetailsFormValidator =
      new AccidentAndEmploymentDetailsFormValidator();

    const injuryDiagnosisAndAssistanceFormValidator =
      new InjuryDiagnosisAndAssistanceFormValidator();

    const referralsOutValidator = new ReferralsOutFormValidator();

    return isDeepEmptyUtils({
      ...accidentAndEmploymentDetailsFormValidator.validate(values),
      ...injuryDiagnosisAndAssistanceFormValidator.validate(values),
      ...patientDeclarationFormValidator.validate(values),
      ...personalDetailsFormValidator.validate(values),
      ...providerDeclarationFormValidator.validate(values),
      ...claimExtraFieldsValidator.validate(values),
      ...referralsOutValidator.validate(values)
    });
  };

  public getDraftStatus = (values: ClaimFormValues) => {
    if (this.getIsFormValid(values)) {
      return ClaimStatuses.Ready;
    } else {
      return ClaimStatuses.Incomplete;
    }
  };

  public getIsLodgeEnabled = (
    values: AllClaimFormValues,
    submitting: boolean
  ) =>
    this.getIsFormValid(values) &&
    values.claimStatusCode !== ClaimStatuses.Queued &&
    values.claimStatusCode !== ClaimStatuses.Pending &&
    !submitting &&
    !values.private &&
    !values.referralIn;

  public set submitOptions(submitOption: SubmitOptions) {
    this._submitOptions = submitOption;
  }

  public get insurerContactId() {
    return this._insurerContactId;
  }

  public set insurerContactId(insurerContactId: string | undefined) {
    this._insurerContactId = insurerContactId;
  }

  public set allocated(allocated: number | undefined) {
    this._allocated = allocated;
  }

  @action
  public setSelectedPatient = (patient: Contact | undefined) => {
    this.claim.setPatient(patient);
  };

  @action
  public setHiddenClaimManagementModal = (value: boolean) => {
    this.hiddenClaimManagementModal = value;
  };

  @action
  public setProviderById = async (providerId: string | undefined) => {
    if (providerId) {
      const provider = await this.root.practice.getProvider(providerId);
      const providerUser = await this.root.core.getUser(providerId);

      const providerTypes = this.root.acc.getAvailableProviderTypes(
        provider.contractTypes
      );

      runInAction(() => {
        this.provider = provider;
        this.providerUser = providerUser;

        if (providerTypes.length === 1) {
          this.providerTypeCode = providerTypes[0].key;
        } else {
          this.providerTypeCode = undefined;
        }
      });
    } else {
      this.providerTypeCode = undefined;
      this.provider = undefined;
      this.providerUser = undefined;
    }
  };

  public handleSubmit = async (values: ClaimFormValues) => {
    if (this.options.onSubmit) {
      //set claimDto values that are not relevant to the form.
      const claimDtoValues: Omit<ClaimDto, "id" | "eTag"> = {};
      const employer = values.employerId
        ? await this.root.practice.getContact(values.employerId)
        : undefined;
      claimDtoValues.employerName = values.employerId
        ? employer?.name
        : values.employerName;
      claimDtoValues.insurerContactId = this._insurerContactId;
      if (this._insurerContactId !== this.claim.insurerContactId) {
        const insurer = this._insurerContactId
          ? await this.root.practice.getContact(this._insurerContactId)
          : undefined;
        claimDtoValues.insurerName = insurer ? insurer.name : undefined;
      }
      claimDtoValues.allocated = this._allocated;

      if (this.provider?.id) {
        if (
          this.provider.id !== this.claim.provider?.id ||
          !this.claim.providerFirstName ||
          !this.claim.providerLastName
        ) {
          const providerUser = await this.root.core.getUser(this.provider.id);
          claimDtoValues.providerFirstName = providerUser.firstName;
          claimDtoValues.providerMiddleName = providerUser.middleName;
          claimDtoValues.providerLastName = providerUser.lastName;
        }
      }

      claimDtoValues.claimNumber = values.claimNumber?.trim();
      claimDtoValues.hpiCpn = this.provider?.cpn;
      const submitValues = {
        values: {
          ...values,
          claimDiagnosis: values.claimDiagnosis?.filter(isDiagnosisNotEmpty)
        },
        submitOptions: this._submitOptions,
        acc: this.root.acc,
        claimDtoValues
      };
      await this.options.onSubmit(submitValues);

      await this.updateReasonForVisitFromDiagnosis(values);
    }
  };

  public updateReasonForVisitFromDiagnosis = async (
    values: ClaimFormValues
  ) => {
    const diagnoses = this.getConvertedDiagnosesToClinicalDiagnoses(
      values.primaryDiagnosis,
      values.claimDiagnosis
    );

    const diagnosisSideData: DiagnosisSideData[] = diagnoses.map(x => ({
      ...x.diagnosisCode,
      side: x.diagnosisSide,
      isPrimaryDiagnosis: x.isPrimaryDiagnosis,
      diagnosis: x.diagnosisCode?.code
    }));

    const reasonForVisit = getReasonForVisit(
      diagnosisSideData,
      this.options?.clinicalRecord?.clinicalData?.reasonForVisit
    );
    await this.clinicalRecord?.saveClinicalData({
      reasonForVisit
    });
  };

  public handleSubmitClaimManagement = async (
    values: ClaimFormManagementValues
  ) => {
    if (this.options.onSubmit) {
      const submitValues = {
        values,
        submitOptions: this._submitOptions,
        acc: this.root.acc,
        isReferral: this.claim.referralIn
      };

      await this.options.onSubmit(submitValues);
    }
  };

  @action
  public handleSubmitSucceeded = async (
    values: ClaimFormValues | ClaimFormManagementValues,
    onSubmitSucceededOverride?: () => void
  ) => {
    const updateVisits = this.claim.dto.allocated !== this._allocated;
    if (updateVisits && this.claim.dto.employerId) {
      const employer = await this.root.practice.getContact(
        this.claim.dto.employerId
      );
      this.root.notification.success(
        `Allocated consults updated to ${this.claim.dto.allocated} based on ${employer.name} settings`
      );
    }
    if (values.id) {
      this.root.notification.success("Claim has been updated");
    } else {
      this.root.notification.success("A new claim has been created");
    }

    this.setHiddenClaimManagementModal(true);

    if (onSubmitSucceededOverride) {
      return onSubmitSucceededOverride();
    }

    if (this.options.onSubmitSucceeded) {
      this.options.onSubmitSucceeded(this.claim, this._submitOptions);
    }
  };

  public submitClaimManagementModal = async (
    values: ClaimManagementModalFormValues
  ) => {
    const patient = this.claim.patient
      ? this.claim.patient
      : await this.root.practice.getContact(values.patientId!);

    const claimDto = getAddClaimManagementModalDto(
      values,
      convertToClaimPatient({
        practice: this.root.practice,
        contact: patient
      })
    );

    const insurer = claimDto.insurerContactId
      ? await this.root.practice.getContact(claimDto.insurerContactId)
      : undefined;
    claimDto.insurerName = insurer ? insurer.name : undefined;
    claimDto.id = values.id;
    if (!values.id) {
      const {
        hpiOrganisationNumber,
        providerAddress,
        practiceName,
        hpiFacilityNumber
      } = this.claim.getOrgUnitData();
      claimDto.hpiOrganisationNumber = hpiOrganisationNumber;
      claimDto.providerAddress = providerAddress;
      claimDto.practiceName = practiceName;
      claimDto.hpiFacilityNumber = hpiFacilityNumber;
    }

    claimDto.providerFirstName = this.providerUser?.firstName;
    claimDto.providerMiddleName = this.providerUser?.middleName;
    claimDto.providerLastName = this.providerUser?.lastName;
    claimDto.providerTypeCode = this.providerTypeCode;
    claimDto.hpiCpn = this.provider?.cpn;
    claimDto.claimNumber = values.claimNumber?.trim();
    claimDto.statusCode = values.claimStatusCode;

    if (this.claim.id) {
      this.claim = await patchExistingClaim(
        { ...claimDto, id: this.claim.id },
        this.root.acc
      );
    } else {
      this.claim = await addNewClaim(claimDto, this.root.acc);
    }

    this.claim.sendAccForm = values.sendAccForm;
  };

  public submitClaimManagementModalView = async (
    values: ClaimManagementModalFormValues
  ) => {
    const claimDto = {
      id: values.id,
      discharged: values.isDischarged
        ? values.discharged || DateTime.now().toISODate()
        : undefined
    };
    if (this.claim.id) {
      this.claim = await patchExistingClaim(
        { ...claimDto, id: this.claim.id },
        this.root.acc
      );
    } else {
      this.claim = await addNewClaim(claimDto, this.root.acc);
    }
  };

  public updateAllocatedVisit = (values: EmployerDto, employerId: string) => {
    const { accreditedEmployer, privateInsurerContactId, visitsPerClaim } =
      values;
    if (accreditedEmployer && privateInsurerContactId === undefined) {
      this.insurerContactId = employerId;
    }
    if (
      accreditedEmployer &&
      privateInsurerContactId &&
      this.claimFormValues?.workInjury
    ) {
      this.insurerContactId = privateInsurerContactId;
    }
    if (accreditedEmployer) {
      this.allocated = visitsPerClaim;
    }
  };

  public cancel = () =>
    this.root.routing.goToFromState(routes.claims.basePath.pattern);

  public getInitialFormValues = (): ClaimFormValues => {
    const initialValues = getClaimFormValues(this.claim);

    const accOccupationCode = this.root.acc.ref.occupations.values.find(
      x =>
        x.anzscoCode &&
        this.claim.patient?.occupation &&
        x.anzscoCode.split(",").includes(this.claim.patient.occupation)
    )?.code;

    if (!initialValues.occupation && accOccupationCode) {
      initialValues.occupation = accOccupationCode;
    }

    const {
      hpiFacilityNumber,
      hpiOrganisationNumber,
      providerAddress,
      practiceName
    } = this.claim.getOrgUnitData();

    if (!initialValues.hpiFacilityNumber) {
      initialValues.hpiFacilityNumber = hpiFacilityNumber;
    }
    if (!initialValues.hpiOrganisationNumber) {
      initialValues.hpiOrganisationNumber = hpiOrganisationNumber;
    }
    if (!initialValues.providerAddress) {
      initialValues.providerAddress = providerAddress;
    }
    if (!initialValues.practiceName) {
      initialValues.practiceName = practiceName;
    }

    if (this.claimFormValues) {
      const currentDiagnoses = this.claimFormValues?.claimDiagnosis;
      initialValues.claimDiagnosis = currentDiagnoses;
    }

    return initialValues;
  };

  /**
   * Method for stashed clinical data preloading.
   * @param initialValues
   */
  public getStashedClinicalClaimInitialValues = async (
    initialValues: ClaimFormValues
  ): Promise<Partial<ClaimFormValues> | undefined> => {
    const stashedData = this.options.clinicalRecord?.stashedClinicalData;

    // If the Claim form is from clinical record Injury (ACC45) form, we check for stashed (sync with SOTAP Form) clinical data
    // and use the stashed data as initial Claim form values.
    if (this.options.clinicalRecord && stashedData) {
      const {
        primaryDiagnoses: clinicalPrimaryDiagnoses,
        additionalDiagnoses: clinicalAdditionalDiagnoses
      } = getDiagnoses(
        this.options.clinicalRecord,
        stashedData.diagnoses,
        true
      );

      // getting missing ACC diagnoses terminology data
      const { primaryDiagnoses, additionalDiagnoses } =
        await this.getDiagnosesWithTerminologyData(
          clinicalPrimaryDiagnoses,
          clinicalAdditionalDiagnoses
        );

      const occupation =
        stashedData.patientDemographicUpdate?.occupation &&
        this.clinicalRecord?.getAccOccupationWithOccupationId(
          stashedData.patientDemographicUpdate?.occupation
        );

      return {
        accidentDate: stashedData.currentInjury?.dateOfInjury
          ? DateTime.jsDateFromISO(stashedData.currentInjury.dateOfInjury)
          : initialValues.accidentDate,
        causeOfAccident: stashedData.currentInjury?.mechanismOfInjury
          ? stashedData.currentInjury?.mechanismOfInjury
          : initialValues.causeOfAccident,
        primaryDiagnosis: primaryDiagnoses[0]
          ? primaryDiagnoses[0]
          : initialValues.primaryDiagnosis,
        claimDiagnosis: additionalDiagnoses.length
          ? additionalDiagnoses
          : initialValues.claimDiagnosis,
        hasAdditionalDiagnoses: additionalDiagnoses.length
          ? additionalDiagnoses.length > 0
          : initialValues.hasAdditionalDiagnoses,
        workType: stashedData.workHistory?.workType ?? initialValues.workType,
        occupation: this.isAccDirty ? occupation : initialValues.occupation,
        inPaidEmployment:
          !!stashedData.workHistory?.workType || initialValues.inPaidEmployment
      };
    }
    return undefined;
  };

  private convertDiagnosisSideToClinicalSide = (value: string | undefined) => {
    switch (value) {
      case DiagnosisSides.Left:
        return SideOfBody.Left;

      case DiagnosisSides.Right:
        return SideOfBody.Right;

      case DiagnosisSides.NotApplicable:
        return SideOfBody.Neither;

      case DiagnosisSides.Bilateral:
        return SideOfBody.Both;

      default:
        return undefined;
    }
  };

  private convertClinicalSideToDiagnosisSide = (value: string | undefined) => {
    switch (value) {
      case SideOfBody.Left:
        return DiagnosisSides.Left;

      case SideOfBody.Right:
        return DiagnosisSides.Right;

      case SideOfBody.Neither:
        return DiagnosisSides.NotApplicable;

      case SideOfBody.Both:
        return DiagnosisSides.Bilateral;

      default:
        return undefined;
    }
  };

  private getConvertedDiagnosesToClinicalDiagnoses = (
    primaryDiagnosis: Omit<ClaimDiagnosisFormValues, "id"> | undefined,
    claimDiagnosis: ClaimDiagnosisFormValues[] | undefined
  ): DiagnosisDataItemDto[] => {
    let convertedAdditionalDiagnoses: DiagnosisSideData[] = [];

    const convertedPrimaryDiagnosis: DiagnosisSideData[] = primaryDiagnosis
      ? [
          {
            diagnosisKey: primaryDiagnosis.diagnosisKey,
            diagnosis: primaryDiagnosis.diagnosisCode,
            originalText: primaryDiagnosis.diagnosisDescription,
            side: this.convertDiagnosisSideToClinicalSide(
              primaryDiagnosis.diagnosisSide
            ),
            isPrimaryDiagnosis: true,
            readCodes: [primaryDiagnosis.readCode ?? ""],
            codeSystem: primaryDiagnosis.codeSystem,
            version: primaryDiagnosis.version
          }
        ]
      : [
          {
            diagnosisKey: undefined,
            diagnosis: undefined,
            originalText: undefined,
            side: undefined,
            isPrimaryDiagnosis: true,
            readCodes: [""],
            codeSystem: undefined,
            version: undefined
          }
        ];

    if (claimDiagnosis && claimDiagnosis.length > 0) {
      convertedAdditionalDiagnoses = claimDiagnosis.map(x => ({
        diagnosisKey: x.diagnosisKey,
        diagnosis: x.diagnosisCode,
        originalText: x.diagnosisDescription,
        side: this.convertDiagnosisSideToClinicalSide(x.diagnosisSide),
        isPrimaryDiagnosis: false,
        readCodes: [x.readCode ?? ""],
        codeSystem: x.codeSystem,
        version: x.version
      }));
    }

    return getMappedDiagnoses(
      convertedPrimaryDiagnosis,
      convertedAdditionalDiagnoses
    );
  };

  public getStashedClinicalData = (
    values: ClaimFormValues
  ): Pick<
    EncounterClinicalData,
    "currentInjury" | "diagnoses" | "workHistory" | "patientDemographicUpdate"
  > => {
    if (!this.claim.isViewMode) {
      const diagnoses = this.getConvertedDiagnosesToClinicalDiagnoses(
        values.primaryDiagnosis,
        values.claimDiagnosis
      );

      const clinicalOccupation =
        this.clinicalRecord?.getInitialClinicalOccupation(values.occupation);

      return {
        currentInjury: {
          dateOfInjury: DateTime.fromJSDate(values.accidentDate)?.toISO(),
          mechanismOfInjury: values.causeOfAccident
        },
        diagnoses: {
          diagnoses
        },
        workHistory: { workType: values.workType },
        patientDemographicUpdate: {
          occupation: this.isAccDirty ? clinicalOccupation : values.occupation
        }
      };
    }
    return {
      currentInjury: undefined,
      diagnoses: undefined,
      workHistory: undefined,
      patientDemographicUpdate: undefined
    };
  };

  public getEmployerAddress = (employerAddress: AddressDto | undefined) => {
    if (!employerAddress) {
      return {
        city: undefined,
        type: AddressType.Physical,
        street1: undefined,
        street2: undefined,
        suburb: undefined,
        postCode: undefined,
        country: undefined
      };
    }

    const addressType =
      employerAddress && employerAddress?.type === AddressType.Both
        ? AddressType.Physical
        : employerAddress?.type;

    return { ...employerAddress, type: addressType };
  };

  public onEmployerSelected = async (
    employerId: string,
    form: FormApiWithMutators<ClaimFormValues>
  ) => {
    if (!employerId) {
      return;
    }

    const employer = await this.root.practice.getContact(employerId);
    const employerAddress = employer.defaultAddress;
    const address = this.getEmployerAddress(employerAddress);

    form.batch(() => {
      form.change(claimFormNameOf("employerAddress"), address);
      form.change(claimFormNameOf("isQuickAddEmployer"), false);
      form.change(claimFormNameOf("employerName"), undefined);
      form.change(
        claimFormNameOf("hasInvalidEmployerAddress"),
        !this.getIsClaimAddressValid(address)
      );
    });

    if (employer.employer) {
      this.updateAllocatedVisit(employer.employer, employerId);
    }
  };

  public onQuickAddSelected = (form: FormApiWithMutators<ClaimFormValues>) => {
    const { isQuickAddEmployer } = form.getState().values;
    if (!isQuickAddEmployer) {
      form.change(claimFormNameOf("isQuickAddEmployer"), true);
      form.change(claimFormNameOf("employerId"), undefined);
      form.change(claimFormNameOf("employerAddress"), undefined);
    }
  };

  public getIsClaimAddressValid = (address: AddressDto) => {
    const addressValidator = new ClaimAddressValidator();
    return isEmpty(addressValidator.validate(address));
  };

  private getDiagnosesWithTerminologyData = async (
    primaryDiagnosis: DiagnosisSideData[],
    additionalDiagnosis: DiagnosisSideData[]
  ): Promise<{
    primaryDiagnoses: Omit<ClaimDiagnosisFormValues, "id">[];
    additionalDiagnoses: ClaimDiagnosisFormValues[];
  }> => {
    const diagnoses = [...primaryDiagnosis, ...additionalDiagnosis];
    if (!diagnoses.length)
      return { primaryDiagnoses: [], additionalDiagnoses: [] };

    const terminologyConcepts: TerminologyConceptDto[] = await Promise.all(
      //getTerminologiesLookUp() will only return one item if parameter is duplicate diagnosis.
      diagnoses.map(async d => {
        const terminology = await this.root.clinical.getTerminologiesLookup([
          {
            code: d.diagnosis,
            codeSystem: d.codeSystem,
            version: d.version
          }
        ]);

        return {
          ...terminology[0],
          isPrimaryDiagnosis: d.isPrimaryDiagnosis,
          diagnosisSide: d.side
        };
      })
    );

    return terminologyConcepts.reduce(
      (acc, terminologyConcept) => {
        const {
          termNames,
          code,
          aCCAcceptable,
          aCC32Acceptable,
          readCode,
          codeSystem,
          version,
          isPrimaryDiagnosis,
          diagnosisSide
        } = terminologyConcept;

        const primaryDiagnoses = primaryDiagnosis.find(
          p =>
            p.diagnosis === terminologyConcept.code &&
            isPrimaryDiagnosis &&
            p.side === diagnosisSide
        );

        const additionalDiagnoses = additionalDiagnosis.find(
          a =>
            a.diagnosis === terminologyConcept.code &&
            !isPrimaryDiagnosis &&
            a.side === diagnosisSide
        );

        const diagnoses = primaryDiagnoses ?? additionalDiagnoses;

        if (diagnoses) {
          const diagnosisSide = this.convertClinicalSideToDiagnosisSide(
            diagnoses.side
          );

          const diagnosisDescription =
            termNames.find(x => x === diagnoses.originalText) ?? termNames[0];

          const diagnose = {
            diagnosisDescription,
            diagnosisCode: code,
            diagnosisKey: code + diagnosisDescription,
            aCCAcceptable,
            aCC32Acceptable,
            readCode,
            codeSystem,
            version,
            diagnosisSide
          };

          if (primaryDiagnoses) {
            return { ...acc, primaryDiagnoses: [diagnose] };
          } else {
            return {
              ...acc,
              // ⚠️⚠️⚠️ id is required for AnimatedList only. Should not be saved.
              additionalDiagnoses: [
                ...acc.additionalDiagnoses,
                { ...diagnose, id: newGuid() }
              ]
            };
          }
        }

        return acc;
      },
      { primaryDiagnoses: [], additionalDiagnoses: [] }
    );
  };
}
