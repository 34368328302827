import { decode } from "base64-arraybuffer";

import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { TemplateRenderDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ExportPatientRecordDocumentData } from "@stores/practice/utils/practice.utils.ts";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

export type ExportPatientRecordViewerDialogProps = {
  data: ExportPatientRecordDocumentData;
  onCloseDocumentViewer: () => void;
};

export const ExportPatientRecordViewerDialog: React.FC<
  ExportPatientRecordViewerDialogProps
> = ({ data, onCloseDocumentViewer }) => {
  const { document } = useStores();

  const getDocument = async ({
    patientId
  }: ExportPatientRecordDocumentData): Promise<Document> => {
    const templates = await document.getTemplates({
      name: "Patient Export - Demographic"
    });

    const documentTemplate: TemplateRenderDto = await document.renderTemplate(
      templates.length ? templates[0].id : "",
      {
        contentType: DocumentContentType.Pdf,
        skipMerge: false,
        isPreview: false,
        context: {
          PatientId: patientId,
          EnrichForPatientExport: true
        }
      }
    );

    return {
      documentId: "",
      extension: DocumentContentType.Pdf,
      entityId: patientId,
      entityType: DocumentEntityType.Patient,
      downloadUri: undefined,
      previewUri: new Uint8Array(decode(documentTemplate.content)),
      name: "Patient Record Export"
    };
  };

  return (
    <DocumentViewerDialog
      getDocument={(): Promise<Document> => getDocument(data)}
      downloadInNewTab
      closeDocumentViewer={onCloseDocumentViewer}
    />
  );
};
