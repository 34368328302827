import { computed } from "mobx";

import { DateTime, upsertItem } from "@bps/utils";
import {
  ClaimGoalsDataItemDto,
  DischargeClinicalDataDto,
  DischargeDataItemDto,
  DisplayOutcome,
  DisplayOutcomeDetail,
  GoalDataItemDto,
  GoalsClinicalDataDto,
  GoalsDataItemDto,
  PatientTreatmentPlanClinicalDataDto,
  PatientTreatmentPlanDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { TreatmentPlanLinkType } from "../../treatmentAndManagement/management/ManagementForm.Types.ts";
import { DischargeFormValues } from "../discharge-form/DischargeForm.types.ts";
import { checkGoalsHasValues } from "../SOTAP.utils.ts";

export class SOTAPMultiProviderClinicalDataHelper {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private businessRoleCode?: string | undefined
  ) {
    if (!businessRoleCode) {
      this.businessRoleCode = this.clinicalRecord.openEncounter?.businessRole;
    }
  }

  private get clinicalData() {
    return this.clinicalRecord.clinicalData;
  }

  private get stashedClinicalData() {
    return this.clinicalRecord.stashedClinicalData;
  }

  private get core() {
    return this.clinicalRecord.core;
  }

  @computed
  get multiProviderClaimsAllowed() {
    return this.core.hasPermissions(Permission.MultiProviderClaimsAllowed);
  }

  getNewTreatmentPlanData = (
    values: SOTAPFormValues
  ): PatientTreatmentPlanClinicalDataDto => {
    const fullTreatmentPlanClinicalData =
      this.clinicalData?.patientTreatmentPlan;

    const allTreatmentPlans = this.getTreatementPlanDataItems(values);

    return {
      eTag: fullTreatmentPlanClinicalData?.eTag,
      treatmentPlans: allTreatmentPlans
    };
  };

  getInitialValueTreatmentPlanData = () => {
    if (!this.stashedClinicalData?.patientTreatmentPlan?.treatmentPlans) {
      return;
    }

    const treatmentPlans =
      this.stashedClinicalData?.patientTreatmentPlan.treatmentPlans;

    if (this.multiProviderClaimsAllowed) {
      return treatmentPlans?.find(
        d =>
          d.businessRoleCode === this.businessRoleCode &&
          d.linkId === this.clinicalRecord.openEncounter?.episodeOfCareId &&
          !d.isDeleted
      );
    } else {
      return treatmentPlans?.find(
        d =>
          d.linkId === this.clinicalRecord.openEncounter?.episodeOfCareId &&
          !d.isDeleted
      );
    }
  };

  private getTreatmentPlanDataItem = (values: SOTAPFormValues) => {
    let treatmentPlan: PatientTreatmentPlanDataItemDto | undefined;

    if (this.clinicalRecord.openEncounter?.episodeOfCareId) {
      treatmentPlan =
        this.clinicalData?.patientTreatmentPlan?.treatmentPlans?.find(
          d =>
            d.businessRoleCode === this.businessRoleCode &&
            d.linkId === this.clinicalRecord.openEncounter?.episodeOfCareId &&
            !d.isDeleted
        );
    }

    const treatments = this.getTreatments(values);

    const treatmentPlanData: PatientTreatmentPlanDataItemDto = {
      ...treatmentPlan,
      linkId: treatmentPlan
        ? treatmentPlan.linkId
        : this.clinicalRecord.openEncounter?.episodeOfCareId,
      treatmentPlanLinkType: TreatmentPlanLinkType.episodeOfCare,
      treatments: !!treatments?.length ? treatments : undefined,
      educationOptions: values.planEducationOptions,
      otherEducationComment: values.planOtherEducationComment,
      educationComment: values.planEducationComment,
      otherTreatments: values.planHasOtherTreatments
        ? values.planOtherTreatments
        : undefined,
      plan: values.planReview,
      businessRoleCode: this.businessRoleCode,
      goals: checkGoalsHasValues(values.goals ?? []),
      treatmentPlanDiscussed: values.treatmentPlanDiscussed,
      warningExplained: values.warningExplained,
      consentObtained: values.consentObtained,
      culturalNeedsIdentified: values.culturalNeedsIdentified,
      additionalDiscussions: values.additionalDiscussions
    };
    return treatmentPlanData;
  };

  private getTreatementPlanDataItems = (values: SOTAPFormValues) => {
    const alltreatmentPlans =
      this.clinicalData?.patientTreatmentPlan?.treatmentPlans;

    const currentPlan = this.getTreatmentPlanDataItem(values);

    const newDataItems = upsertItem({
      item: currentPlan,
      array: alltreatmentPlans ?? [],
      predicate: x => x.id === currentPlan.id
    });

    return newDataItems;
  };

  private getTreatments = (values: SOTAPFormValues) => {
    return values.planTreatments
      ?.filter(x => !!x.treatment)
      .map(x => {
        return {
          treatment: x.treatment,
          comment: x.comment
        };
      });
  };

  getInitialValueGoalsData = () => {
    if (!this.stashedClinicalData?.goals?.dataItems) {
      return;
    }

    const goals = this.stashedClinicalData?.goals;
    if (this.multiProviderClaimsAllowed) {
      return (
        goals &&
        goals.dataItems?.find(d => d.businessRoleCode === this.businessRoleCode)
      );
    } else {
      return goals?.dataItems && goals?.dataItems?.length > 0
        ? goals?.dataItems[0]
        : undefined;
    }
  };

  getInitialValuePatientTreatmentPlanGoalsData = () => {
    if (!this.stashedClinicalData?.patientTreatmentPlan?.treatmentPlans) {
      return;
    }

    const treatmentPlans =
      this.stashedClinicalData.patientTreatmentPlan.treatmentPlans;

    let relevantPlan: PatientTreatmentPlanDataItemDto | undefined;

    if (this.multiProviderClaimsAllowed) {
      relevantPlan = treatmentPlans.find(
        d =>
          d.businessRoleCode === this.businessRoleCode &&
          d.linkId === this.clinicalRecord.openEncounter?.episodeOfCareId &&
          !d.isDeleted
      );
    } else {
      relevantPlan = treatmentPlans.find(
        d =>
          d.linkId === this.clinicalRecord.openEncounter?.episodeOfCareId &&
          !d.isDeleted
      );
    }
    return relevantPlan;
  };

  getNewDischargeData = (
    values: DischargeFormValues
  ): DischargeClinicalDataDto => {
    const dischargeData = this.clinicalData?.discharge;
    const dataItems = this.getDischargeDataItems(values);
    return {
      eTag: dischargeData?.eTag,
      dataItems
    };
  };

  get initialDischargeData() {
    if (!this.clinicalRecord.currentConditionDishargeDataItems) {
      return undefined;
    }

    const dataItems =
      this.clinicalRecord.currentConditionDishargeDataItems.filter(
        x => x.dischargeStatus !== DischargeStatus.Reversed
      );

    let dischargeDataItem: DischargeDataItemDto | undefined;

    if (dataItems.length > 0) {
      dischargeDataItem = dataItems.find(
        d =>
          (this.multiProviderClaimsAllowed &&
            d.businessRoleCode === this.businessRoleCode) ||
          !this.multiProviderClaimsAllowed
      );
    }

    return dischargeDataItem;
  }

  private getDischargeDataItems(values: DischargeFormValues) {
    const dataItems = this.clinicalData?.discharge?.dataItems;
    const dataItem = this.getDischargeDataItem(values);

    const newDataItems = [...(dataItems || [])];
    const dataItemIndex = dataItems?.findIndex(
      d =>
        d.dischargeStatus !== DischargeStatus.Reversed &&
        ((this.multiProviderClaimsAllowed &&
          d.businessRoleCode === this.businessRoleCode) ||
          !this.multiProviderClaimsAllowed)
    );

    if (dataItemIndex === undefined || dataItemIndex < 0) {
      newDataItems.push(dataItem);
    } else {
      const existingDataItem = newDataItems[dataItemIndex];

      newDataItems[dataItemIndex] = {
        ...existingDataItem,
        ...dataItem
      };
    }
    return newDataItems;
  }

  private getDischargeDataItem(values: DischargeFormValues) {
    const dataItem = this.clinicalData?.discharge?.dataItems?.find(
      d =>
        d.dischargeStatus !== DischargeStatus.Reversed &&
        ((this.multiProviderClaimsAllowed &&
          d.businessRoleCode === this.businessRoleCode) ||
          !this.multiProviderClaimsAllowed)
    );

    let comment: string | undefined;
    let detail: string[] | undefined;

    switch (values.dischargeOutcome) {
      case DisplayOutcome.PartiallyResolved:
        if (!!values.dischargeOutcomePartiallyResolved)
          detail = values.dischargeOutcomePartiallyResolved;

        if (
          values.dischargeOutcomePartiallyResolved?.find(
            x => x === DisplayOutcomeDetail.SpecialistReferral
          )
        ) {
          comment = values.specialistInformationPartiallyResolved;
        }
        break;
      case DisplayOutcome.Unresolved:
        if (!!values.dischargeOutcomeUnresolved)
          detail = [values.dischargeOutcomeUnresolved];

        if (
          values.dischargeOutcomeUnresolved ===
          DisplayOutcomeDetail.WillBenefitFromSpecialistReferral
        ) {
          comment = values.specialistInformationUnresolved;
        }
        break;
      case DisplayOutcome.Resolved:
        if (!!values.dischargeOutcomeResolved)
          detail = [values.dischargeOutcomeResolved];
    }

    const mergedOutcomdetail = {
      comment,
      detail,
      outcome: values.dischargeOutcome
    };

    const newDischargeDataItem: DischargeDataItemDto = {
      ...dataItem,
      dischargeDate: values.dischargeDate
        ? DateTime.jsDateToISODate(values.dischargeDate)
        : undefined,
      discharger: values.discharger,
      outcomeDetail: mergedOutcomdetail,
      referralFollowUp: {
        followUpNotes: values.followUpNotes,
        followUpDueDate: values.followUpDueDate
          ? DateTime.jsDateToISODate(values.followUpDueDate)
          : undefined
      },
      businessRoleCode: this.businessRoleCode
    };

    return newDischargeDataItem;
  }

  getNewGoalsData = (
    goals: GoalDataItemDto[],
    psfsGoalsAdded?: boolean
  ): GoalsClinicalDataDto => {
    const goalsData = this.clinicalRecord.clinicalData?.goals;
    const dataItems = this.getGoalsDataItems(goals, psfsGoalsAdded);
    return {
      eTag: goalsData?.eTag,
      dataItems
    };
  };

  private getGoalsDataItems(
    goals: GoalDataItemDto[],
    psfsGoalsAdded?: boolean
  ) {
    const dataItems = this.clinicalData?.goals?.dataItems;
    const dataItem = this.getGoalsDataItem(goals, psfsGoalsAdded);

    if (!this.multiProviderClaimsAllowed) {
      return [dataItem];
    }

    const newDataItems = [...(dataItems || [])];
    const dataItemIndex = dataItems?.findIndex(
      d => d.businessRoleCode === this.businessRoleCode
    );

    if (dataItemIndex === undefined || dataItemIndex < 0) {
      newDataItems.push(dataItem);
    } else {
      newDataItems[dataItemIndex] = {
        ...dataItem
      };
    }
    return newDataItems;
  }

  private getGoalsDataItem(
    goals: GoalDataItemDto[],
    psfsGoalsAdded: boolean | undefined
  ) {
    const dataItem = this.clinicalData?.goals?.dataItems?.find(
      d => d.businessRoleCode === this.businessRoleCode
    );

    const claimGoals: ClaimGoalsDataItemDto = {
      psfsGoalsAdded,
      goals
    };

    const newGoalsClinicalData: GoalsDataItemDto = {
      ...dataItem,
      claimGoals: [claimGoals],
      businessRoleCode: this.businessRoleCode
    };
    return newGoalsClinicalData;
  }

  public getTreatmentPlanNewGoalsData(goals: GoalDataItemDto[]) {
    const treatmentPlan = this.getInitialValueTreatmentPlanData();

    const newTreatmentPlanData: PatientTreatmentPlanDataItemDto = {
      ...treatmentPlan,
      linkId: this.clinicalRecord.openEncounter?.episodeOfCareId,
      treatmentPlanLinkType: TreatmentPlanLinkType.episodeOfCare,
      businessRoleCode: this.clinicalRecord.openEncounter?.businessRole,
      goals
    };

    const alltreatmentPlans =
      this.clinicalData?.patientTreatmentPlan?.treatmentPlans;

    const newDataItems = upsertItem({
      item: newTreatmentPlanData,
      array: alltreatmentPlans ?? [],
      predicate: x => x.id === newTreatmentPlanData.id
    });

    const updatedTreatmentPlanData: PatientTreatmentPlanClinicalDataDto = {
      eTag: this.clinicalData?.patientTreatmentPlan?.eTag,
      treatmentPlans: newDataItems
    };

    return updatedTreatmentPlanData;
  }
}
