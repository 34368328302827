import { useField } from "react-final-form";

import {
  FieldItemStyleProps,
  FieldItemStyles,
  FontWeights,
  Heading,
  IStyleFunctionOrObject,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import {
  SpinNumberInputField,
  SpinNumberInputFieldProps
} from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import {
  formatDiagnosis,
  getDiagnosisChangesOverviewText,
  getSideChangeOverviewText,
  isDiagnosisChangeFull,
  isSideChangeFull
} from "../../utils.ts";
import { claimAdjustmentResponseFormNameOf } from "./ClaimAdjustmentResponseModalForm.types.ts";

export const ClaimAdjustmentResponseFields: React.FC = () => {
  const { claimAdjustment } = useClaimAdjustmentContext();

  const { acc } = useStores();

  const {
    input: { value: isDeclined }
  } = useField(claimAdjustmentResponseFormNameOf("isDeclined"));

  const {
    treatmentsRequired,
    followUpTreatmentsNeeded,
    handSplintingCost,
    diagnosisChanges,
    diagnosisAdditions,
    initialAssessmentRequested,
    sideChanges,
    abscessHematomaTreatmentsNeeded,
    nailRemovalResectionTreatmentsNeeded,
    nailSimpleRemovalTreatmentsNeeded
  } = claimAdjustment;

  const requestedTreatments = treatmentsRequired ?? followUpTreatmentsNeeded;

  const regularLabel: IStyleFunctionOrObject<
    FieldItemStyleProps,
    FieldItemStyles
  > = {
    headerWrapper: { padding: "0 0 4px" },
    subComponentStyles: {
      label: {
        root: { fontWeight: FontWeights.regular, padding: 0 }
      }
    }
  };

  const podiatristSpinInputProps: Omit<SpinNumberInputFieldProps, "name"> = {
    styles: {
      spinButtonWrapper: { width: 110 }
    },
    fieldItemStyles: regularLabel,
    parse: value => Number(value),
    max: 10,
    min: 0
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Stack.Item grow={0}>
          <TextInputField
            label="PO number"
            name={claimAdjustmentResponseFormNameOf("purchaseOrderNumber")}
          />
        </Stack.Item>
        <DatePickerField
          name={claimAdjustmentResponseFormNameOf("responseDate")}
          label="Date"
          required
          maxDate={DateTime.jsDateNow()}
          minDate={claimAdjustment?.createdDate?.toJSDate()}
        />
      </Stack>
      <TextInputField
        label="Notes"
        name={claimAdjustmentResponseFormNameOf("responseNotes")}
        multiline
      />
      <YesNoToggleField
        label="Fully declined"
        name={claimAdjustmentResponseFormNameOf("isDeclined")}
        styles={{ root: { marginBottom: 0 } }}
      />
      {(!!treatmentsRequired || !!followUpTreatmentsNeeded) && (
        <SpinNumberInputField
          label={`Approved consults ${
            requestedTreatments ? `(requested ${requestedTreatments})` : ""
          }`}
          name={claimAdjustmentResponseFormNameOf("approvedVisits")}
          max={99}
          min={0}
          styles={{ root: { spinButtonWrapper: 120 } }}
          parse={value => Number(value)}
          disabled={isDeclined}
        />
      )}
      {(!!abscessHematomaTreatmentsNeeded ||
        !!nailRemovalResectionTreatmentsNeeded ||
        !!nailSimpleRemovalTreatmentsNeeded) && (
        <div>
          <Heading labelPaddings>Other treatments requested</Heading>
          <Stack tokens={{ childrenGap: 8 }}>
            {!!abscessHematomaTreatmentsNeeded && (
              <SpinNumberInputField
                {...podiatristSpinInputProps}
                label={`Abscess or haematoma: drainage with incision (requested ${abscessHematomaTreatmentsNeeded})`}
                name={claimAdjustmentResponseFormNameOf(
                  "abscessHematomaTreatmentsNeededApproved"
                )}
              />
            )}
            {!!nailSimpleRemovalTreatmentsNeeded && (
              <SpinNumberInputField
                {...podiatristSpinInputProps}
                label={`Nails, simple removal of (requested ${nailSimpleRemovalTreatmentsNeeded})`}
                name={claimAdjustmentResponseFormNameOf(
                  "nailSimpleRemovalTreatmentsNeededApproved"
                )}
              />
            )}
            {!!nailRemovalResectionTreatmentsNeeded && (
              <SpinNumberInputField
                {...podiatristSpinInputProps}
                label={`Nail removal with wedge resection (requested ${nailRemovalResectionTreatmentsNeeded})`}
                name={claimAdjustmentResponseFormNameOf(
                  "nailRemovalResectionTreatmentsNeededApproved"
                )}
              />
            )}
          </Stack>
        </div>
      )}
      {!!handSplintingCost && (
        <SpinNumberInputField
          label={`Splinting cost (requested $${handSplintingCost})`}
          styles={{ root: { spinButtonWrapper: 120 } }}
          name={claimAdjustmentResponseFormNameOf("handSplintingCostApproved")}
          prefix="$"
          step={0.01}
          precision={2}
          disabled={isDeclined}
        />
      )}
      {!!initialAssessmentRequested && (
        <Stack>
          <Heading styles={{ root: { padding: "5px 0" } }}>
            Initial assessment requested
          </Heading>
          <CheckboxField
            label="Initial assessment"
            name={claimAdjustmentResponseFormNameOf(
              "initialAssessmentsApproved"
            )}
            disabled={isDeclined}
          />
        </Stack>
      )}
      {!!diagnosisAdditions.length && (
        <CheckboxGroupField
          label="Diagnosis requested"
          name={claimAdjustmentResponseFormNameOf("diagnosisKeys")}
          options={diagnosisAdditions.map(diagnosis => ({
            label: formatDiagnosis(
              diagnosis,
              acc.ref.diagnosisSides.keyTextValues
            ),
            value: diagnosis.terminology?.diagnosisKey
          }))}
          disabled={isDeclined}
        />
      )}
      {!!diagnosisChanges.length && (
        <CheckboxGroupField
          label="Diagnosis changes"
          name={claimAdjustmentResponseFormNameOf("diagnosisChangesKeys")}
          options={diagnosisChanges
            .filter(isDiagnosisChangeFull)
            .map(diagnosisChange => ({
              label: getDiagnosisChangesOverviewText(
                diagnosisChange,
                acc.ref.diagnosisSides.keyTextValues
              ),
              value: diagnosisChange.newDiagnosis.terminology?.diagnosisKey
            }))}
          disabled={isDeclined}
        />
      )}
      {!!sideChanges.length && (
        <CheckboxGroupField
          label="Side change requested"
          name={claimAdjustmentResponseFormNameOf("sideChangesKeys")}
          options={sideChanges.filter(isSideChangeFull).map(sideChange => ({
            label: getSideChangeOverviewText(
              sideChange,
              acc.ref.diagnosisSides.keyTextValues
            ),
            value: sideChange.oldDiagnosis.terminology?.diagnosisKey
          }))}
          disabled={isDeclined}
        />
      )}
    </Stack>
  );
};
