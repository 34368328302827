import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import { Heading, Stack, ToolTipButton } from "@bps/fluent-ui";

interface ObservationsResponsiveHeaderProps {
  heading?: string;
  onClose: () => void;
  pivotTab?: ReactNode;
}

export const ObservationsResponsiveHeader: React.FC<ObservationsResponsiveHeaderProps> =
  observer(({ heading, onClose, pivotTab }) => {
    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { width: "100%" } }}
      >
        <Stack horizontal verticalAlign="center">
          <Heading variant="section-heading">{heading}</Heading>
          <Stack
            horizontal
            styles={{
              root: { marginTop: 10, paddingBottom: 8, paddingLeft: 8 }
            }}
          >
            {pivotTab}
          </Stack>
        </Stack>

        <Stack horizontal verticalAlign="center">
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: "8" }}
          >
            <ToolTipButton
              toolTipContent="Close"
              buttonProps={{
                text: "Close",
                onClick: onClose
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  });
