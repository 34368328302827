import { FieldValidator } from "final-form";

import { DateTime } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import {
  ACCSex,
  AddClaimDto,
  ClaimDiagnosisDto,
  ClaimDto,
  ClaimStatuses,
  PatchClaimDto,
  ServiceItemsDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  AccProviderType,
  Sex
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { boolToYesNo } from "@libs/utils/utils.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { ClaimPatientFormValues } from "@shared-types/acc/claim-patient-values.type.ts";
import { ProviderDeclarationValuesType } from "@shared-types/acc/provider-declaration-values.type.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccStore } from "@stores/acc/AccStore.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { nzAddressText } from "@stores/core/models/Address.ts";
import { User } from "@stores/core/models/User.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import { capitaliseAddress } from "@stores/practice/utils/practice.utils.ts";

import { ClaimAddressValidator } from "../../claim-adjustment/validators/ClaimAddressValidator.ts";
import {
  mapToClaimDiagnosisDto,
  mapToClaimDiagnosisFormValues,
  mapToPrimaryDiagnosisFormValues,
  mergeClaimDiagnosis,
  splitClaimDiagnosis
} from "../../utils.ts";
import {
  AccidentAndEmploymentDetailsViewValues,
  InjuryDiagnosisViewValues,
  PatientDeclarationViewValues,
  PatientDetailsViewValues,
  ProviderDeclarationViewValues,
  ReferralOutViewValues,
  SubmitOptions
} from "../types/claim.types.ts";
import { ReferralDetailsAccTemplate } from "../types/referral-details-acc-template.type.ts";

export const convertLevelFourEthnicityCodeToLevelTwo = (
  levelFourEthnicity: string
) => levelFourEthnicity.substring(0, 2);

export const emptyServiceItem: ServiceItemsDto = {
  spent: "",
  serviceItemCode: ""
};

export const getClaimFormValues = (claim?: Claim): ClaimFormValues => {
  const defaultValues = getClaimFormDefaultValues(claim);

  const personalDetailsValues = {
    patient: {
      patientId: claim?.dto.patientId,
      patientFirstName: claim?.dto.patientFirstName,
      patientMiddleName: claim?.dto.patientMiddleName,
      patientLastName: claim?.dto.patientLastName,
      patientDateOfBirth: claim?.patientDateOfBirth?.toJSDate(),
      patientSex: claim?.dto.patientSex,
      patientNhiNumber: claim?.dto.patientNhiNumber,
      addressType: claim?.dto.addressType,
      patientEmail: claim?.dto.patientEmail,
      patientEthnicityCode: claim?.dto.patientEthnicityCode,
      patientAddress: claim?.dto.patientAddress,
      patientMobileNumber: claim?.dto.patientMobileNumber,
      patientHomePhoneNumber: claim?.dto.patientHomePhoneNumber,
      patientWorkPhoneNumber: claim?.dto.patientWorkPhoneNumber
    }
  };

  const providerDeclarationValues = {
    providerId: claim?.dto.providerId,
    hpiCpn: claim?.dto.hpiCpn,
    providerDeclaration:
      claim?.providerDeclaration?.toJSDate() || DateTime.jsDateNow(),
    providerDeclared: claim?.dto.providerDeclared,
    providerTypeCode: claim?.dto.providerTypeCode,
    providerTypeAllowAcc45: claim?.providerTypeAllowAcc45,
    telehealthConsultation: claim?.dto.telehealthConsultation,
    telehealthDeclared: claim?.dto.telehealthDeclared,
    telehealthAuthorisedHealthProfessional:
      claim?.dto.telehealthAuthorisedHealthProfessional,
    telehealthAuthorisedRecords: claim?.dto.telehealthAuthorisedRecords
  };

  const patientDeclarationValues = {
    patientDeclared: claim?.dto.patientDeclared,
    patientClaimAuthorised: claim?.dto.patientClaimAuthorised,
    patientInfoAuthorised: claim?.dto.patientInfoAuthorised,
    patientAccContactAuthorised: claim?.dto.patientAccContactAuthorised,
    patientDeclaration:
      claim?.patientDeclaration?.toJSDate() || DateTime.jsDateNow()
  };

  const accidentAndEmploymentDetailsValues = {
    accidentDate: claim?.accidentDate?.toJSDate(),
    accidentSceneCode: claim?.dto.accidentSceneCode,
    accidentLocationCode: claim?.dto.accidentLocationCode,
    causeOfAccident: claim?.dto.causeOfAccident,
    medicalTreatmentInjury: claim?.dto.medicalTreatmentInjury,
    involvesVehicle: claim?.dto.involvesVehicle,
    workInjury: claim?.dto.workInjury,
    sportingInjury: claim?.dto.sportingInjury,
    sport: claim?.dto.sport,
    occupation: claim?.dto.occupation,
    inPaidEmployment: claim?.dto.inPaidEmployment,
    employmentStatus: claim?.dto.employmentStatus,
    otherEmployment: claim?.dto.otherEmployment,
    workType: claim?.dto.workType,
    employerId: claim?.dto.employerId,
    employerAddress: claim?.dto.employerAddress,
    isQuickAddEmployer: !claim?.dto.employerId && !!claim?.dto.employerName,
    employerName: claim?.dto.employerId ? undefined : claim?.dto.employerName
  };

  const claimDiagnosisSplit = splitClaimDiagnosis(
    claim?.dto.claimDiagnosis || []
  );

  const injuryDiagnosisAndAssistanceValues = {
    primaryDiagnosis:
      claimDiagnosisSplit.primaryDiagnosis &&
      mapToPrimaryDiagnosisFormValues(claimDiagnosisSplit.primaryDiagnosis),
    claimDiagnosis: claimDiagnosisSplit.claimDiagnosis.map(
      mapToClaimDiagnosisFormValues
    ),
    hasAdditionalDiagnoses: (claim?.dto.claimDiagnosis?.length ?? 0) > 1,
    gradualProcessInjury: claim?.dto.gradualProcessInjury,
    injuryComments: claim?.dto.injuryComments,
    assistanceRequired: claim?.dto.assistanceRequired,
    accContactProvider: claim?.dto.accContactProvider
  };

  const referralsOutValues = {
    enableReferralsOut: claim?.dto.referralOut,
    referralDetails: claim?.dto.referralDetails ?? []
  };

  const orgUnitData = claim?.getOrgUnitData();

  return {
    ...defaultValues,
    ...personalDetailsValues,
    ...providerDeclarationValues,
    ...patientDeclarationValues,
    ...accidentAndEmploymentDetailsValues,
    ...injuryDiagnosisAndAssistanceValues,
    ...referralsOutValues,
    ...orgUnitData,
    serviceItems: claim?.dto.serviceItems ?? []
  };
};

export const getClaimFormDefaultValues = (claim?: Claim) => {
  return {
    claimStatusCode: claim?.dto.statusCode,
    id: claim?.dto.id,
    claimNumber: claim?.dto.claimNumber
  };
};

export const getAddClaimDto = (options: {
  values: ClaimFormValues;
  claimStatus?: ClaimStatuses;
}): AddClaimDto => {
  const {
    patient,
    patientDeclaration,
    providerDeclaration,
    accidentDate,
    employerAddress,
    enableReferralsOut,
    primaryDiagnosis,
    claimDiagnosis,
    serviceItems,
    referralDate,
    ...rest
  } = options.values;

  const { patientDateOfBirth } = patient;

  const diagnoses = primaryDiagnosis
    ? mergeClaimDiagnosis(primaryDiagnosis, claimDiagnosis || [])
    : claimDiagnosis;

  return {
    ...rest,
    ...patient,
    patientAddress:
      patient.patientAddress && capitaliseAddress(patient.patientAddress),
    patientDateOfBirth: DateTime.jsDateToISODate(patientDateOfBirth),
    patientDeclaration: DateTime.jsDateToISODate(patientDeclaration),
    providerDeclaration: DateTime.jsDateToISODate(providerDeclaration),
    accidentDate: DateTime.jsDateToISODate(accidentDate),
    employerAddress: employerAddress && capitaliseAddress(employerAddress),
    statusCode: options.claimStatus,
    claimDiagnosis: diagnoses?.map(mapToClaimDiagnosisDto),
    referralOut: !!enableReferralsOut,
    referralDate: DateTime.jsDateToISODate(referralDate)
  };
};

export const getPatientDetailsViewValues = async (options: {
  values: ClaimFormValues;
  root: IRootStore;
}): Promise<PatientDetailsViewValues> => {
  const { values, root } = options;
  const { patientDetails } = await getClaimFormViewValues({
    values,
    root
  });
  return {
    patientFullName: patientDetails.patientFullName,
    patientDateOfBirthFormatted: patientDetails.patientDateOfBirthFormatted,
    patientSexText: patientDetails.patientSexText,
    patientNhiNumber: patientDetails.patientNhiNumber,
    patientMobileNumber: patientDetails.patientMobileNumber,
    patientHomePhoneNumber: patientDetails.patientHomePhoneNumber,
    patientWorkPhoneNumber: patientDetails.patientWorkPhoneNumber,
    patientEmail: patientDetails.patientEmail,
    patientEthnicityText: patientDetails.patientEthnicityText,
    patientAddressFormatted: patientDetails.patientAddressFormatted,
    patientAddressTypeText: patientDetails.patientAddressTypeText
  };
};

export const getAccidentAndEmploymentDetailsViewValues = async (options: {
  values: ClaimFormValues;
  root: IRootStore;
}): Promise<AccidentAndEmploymentDetailsViewValues> => {
  const { values, root } = options;
  const { accidentAndEmploymentDetails } = await getClaimFormViewValues({
    values,
    root
  });

  return {
    accidentDateFormatted: accidentAndEmploymentDetails.accidentDateFormatted,
    accidentSceneText: accidentAndEmploymentDetails.accidentSceneText,
    accidentLocationText: accidentAndEmploymentDetails.accidentLocationText,
    causeOfAccident: accidentAndEmploymentDetails.causeOfAccident,
    involvesVehicleText: accidentAndEmploymentDetails.involvesVehicleText,
    medicalTreatmentInjuryText:
      accidentAndEmploymentDetails.medicalTreatmentInjuryText,
    workInjuryText: accidentAndEmploymentDetails.workInjuryText,
    sportingInjuryText: accidentAndEmploymentDetails.sportingInjuryText,
    sportText: accidentAndEmploymentDetails.sportText,
    inPaidEmploymentText: accidentAndEmploymentDetails.inPaidEmploymentText,
    occupationText: accidentAndEmploymentDetails.occupationText,
    employmentStatusText: accidentAndEmploymentDetails.employmentStatusText,
    workTypeText: accidentAndEmploymentDetails.workTypeText,
    employerName: accidentAndEmploymentDetails.employerName,
    employerAddressFormatted:
      accidentAndEmploymentDetails.employerAddressFormatted,
    employerAddressTypeText:
      accidentAndEmploymentDetails.employerAddressTypeText
  };
};

export const getPatientDeclarationViewValues = async (options: {
  values: ClaimFormValues;
  root: IRootStore;
}): Promise<PatientDeclarationViewValues> => {
  const { values, root } = options;
  const { patientDeclaration } = await getClaimFormViewValues({
    values,
    root
  });

  return {
    declarationDateFormatted: patientDeclaration.declarationDateFormatted
  };
};

export const getInjuryDiagnosisViewValues = async (options: {
  values: ClaimFormValues;
  root: IRootStore;
}): Promise<InjuryDiagnosisViewValues> => {
  const { values, root } = options;
  const { injuryDiagnosis } = await getClaimFormViewValues({
    values,
    root
  });

  return {
    claimDiagnosis: injuryDiagnosis.claimDiagnosis,
    injuryComments: injuryDiagnosis.injuryComments,
    gradualProcessInjuryText: injuryDiagnosis.gradualProcessInjuryText,
    assistanceRequiredText: injuryDiagnosis.assistanceRequiredText,
    accContactProviderText: injuryDiagnosis?.accContactProviderText
  };
};

export const getProviderDeclarationViewValues = async (options: {
  values: ClaimFormValues;
  root: IRootStore;
}): Promise<ProviderDeclarationViewValues> => {
  const { values, root } = options;
  const { providerDeclaration } = await getClaimFormViewValues({
    values,
    root
  });

  return {
    providerName: providerDeclaration.providerName,
    providerDeclarationDateFormatted:
      providerDeclaration.providerDeclarationDateFormatted,
    providerTypeText: providerDeclaration.providerTypeText,
    patientConsentForTelehealth: providerDeclaration.patientConsentForTelehealth
  };
};

export const getReferralOutViewValues = async (options: {
  values: ClaimFormValues;
  root: IRootStore;
}): Promise<ReferralOutViewValues> => {
  const { values, root } = options;
  const { referralOut } = await getClaimFormViewValues({
    values,
    root
  });

  return {
    referralDetails: referralOut.referralDetails
  };
};

export const getClaimFormViewValues = async (options: {
  values: ClaimFormValues;
  root: IRootStore;
}) => {
  const { values, root } = options;
  const { acc, practice, core } = root;

  const employer = values.employerId
    ? await practice.getContact(values.employerId)
    : undefined;

  //swap the merge so it maps to terminology
  const claimDiagnosis = mergeClaimDiagnosis(
    values.primaryDiagnosis || ({} as ClaimDiagnosisDto),
    values.claimDiagnosis || []
  ).map(mapToClaimDiagnosisDto);

  const provider = values.providerId
    ? await core.getUser(values.providerId)
    : undefined;

  const patientDetails = {
    patientFirstName: values.patient.patientFirstName,
    patientMiddleName: values.patient.patientMiddleName,
    patientLastName: values.patient.patientLastName,
    patientFullName: `${values.patient.patientFirstName || ""} ${
      values.patient.patientMiddleName || ""
    } ${values.patient.patientLastName || ""}`,
    patientDateOfBirthFormatted: DateTime.fromJSDate(
      values.patient.patientDateOfBirth
    )?.toDayDefaultFormat(),
    patientSex:
      values.patient.patientSex === Sex.Other
        ? ACCSex.Other
        : values.patient.patientSex,
    patientSexText:
      values.patient.patientSex === Sex.Other
        ? "Other"
        : acc.ref.accSexes.keyTextValues.find(
            x => x.key === values.patient.patientSex
          )?.text,
    patientNhiNumber: values.patient.patientNhiNumber,
    patientMobileNumber: values.patient.patientMobileNumber,
    patientHomePhoneNumber: values.patient.patientHomePhoneNumber,
    patientWorkPhoneNumber: values.patient.patientWorkPhoneNumber,
    patientEmail: values.patient.patientEmail,
    patientEthnicityCode: values.patient.patientEthnicityCode,
    patientEthnicityText: acc.ref.levelTwoEthnicities.keyTextValues.find(
      x => x.key === values.patient.patientEthnicityCode
    )?.text,
    patientAddress: values.patient.patientAddress,
    patientAddressFormatted: values.patient.patientAddress
      ? nzAddressText({
          ...values.patient.patientAddress,
          country: Country.NewZealand
        })
      : "",
    patientAddressTypeText: practice.ref.addressTypes.keyTextValues
      .find(x => x.key === values.patient.patientAddress?.type)
      ?.text.replace("address", "")
      .trim()
  };

  const accidentAndEmploymentDetails = {
    accidentDateFormatted: DateTime.fromJSDate(
      values.accidentDate
    )?.toDayDefaultFormat(),
    accidentSceneText: acc.ref.accidentScenes.keyTextValues.find(
      x => x.key === values.accidentSceneCode
    )?.text,
    accidentLocationText: acc.ref.accidentLocations.keyTextValues.find(
      x => x.key === values.accidentLocationCode
    )?.text,
    causeOfAccident: values.causeOfAccident,
    involvesVehicle: values.involvesVehicle,
    involvesVehicleText: boolToYesNo(values.involvesVehicle),
    medicalTreatmentInjury: values.medicalTreatmentInjury,
    medicalTreatmentInjuryText: boolToYesNo(values.medicalTreatmentInjury),
    workInjury: values.workInjury,
    workInjuryText: boolToYesNo(values.workInjury),
    sportingInjury: values.sportingInjury,
    sportingInjuryText: boolToYesNo(values.sportingInjury),
    sportText: acc.ref.sports.keyTextValues.find(x => x.key === values.sport)
      ?.text,
    inPaidEmploymentText: boolToYesNo(values.inPaidEmployment),
    inPaidEmployment: values.inPaidEmployment,
    occupationText: acc.ref.occupations.keyTextValues.find(
      x => x.key === values.occupation
    )?.text,
    employmentStatusText: acc.ref.employmentStatuses.keyTextValues.find(
      x => x.key === values.employmentStatus
    )?.text,
    employmentStatus: values.employmentStatus,
    workTypeText: acc.ref.workTypes.keyTextValues.find(
      x => x.key === values.workType
    )?.text,
    workType: values.workType,
    employerName: values.employerId ? employer?.name : values.employerName,
    employerAddress: values.employerAddress,
    employerAddressFormatted: values.employerAddress
      ? nzAddressText({
          ...values.employerAddress,
          country: Country.NewZealand
        })
      : "",
    employerAddressTypeText: practice.ref.addressTypes.keyTextValues
      .find(x => x.key === values.employerAddress?.type)
      ?.text.replace("address", "")
      .trim()
  };

  const patientDeclaration = {
    declarationDateFormatted: DateTime.fromJSDate(
      values.patientDeclaration
    )?.toDayDefaultFormat()
  };

  const injuryDiagnosis = {
    claimDiagnosis,
    claimDiagnosisWithText: claimDiagnosis.map(diag => ({
      ...diag,
      diagnosisSide: acc.ref.diagnosisSides.keyTextValues.find(
        x => x.key === diag.diagnosisSide
      )?.text,
      diagnosisDescription: diag.terminology?.text
    })),
    injuryComments: values.injuryComments,
    gradualProcessInjuryText: boolToYesNo(values.gradualProcessInjury),
    assistanceRequiredText: boolToYesNo(values.assistanceRequired),
    accContactProviderText: boolToYesNo(values?.accContactProvider)
  };

  const providerDeclaration = {
    providerName: provider?.fullName,
    providerTypeText: practice.ref.accProviderTypes.keyTextValues.find(
      x => x.key === values.providerTypeCode
    )?.text,
    providerDeclarationDateFormatted: DateTime.fromJSDate(
      values.providerDeclaration
    )?.toDayDefaultFormat(),
    patientConsentForTelehealth: boolToYesNo(values.telehealthConsultation)
  };

  const referralOut = {
    referralDetails: values.referralDetails?.map(
      detail =>
        ({
          ...detail,
          providerTypeText: acc.ref.providerTypes.keyTextValues.find(
            x => x.key === detail.providerTypeCode
          )?.text
        }) as ReferralDetailsAccTemplate
    )
  };

  return {
    patientDetails,
    accidentAndEmploymentDetails,
    patientDeclaration,
    injuryDiagnosis,
    providerDeclaration,
    referralOut
  };
};

export const onSubmitAccForm = async (options: {
  values: ClaimFormValues;
  submitOptions: SubmitOptions;
  acc: AccStore;
  onSubmitRedirect?: (claimId?: string) => void;
  claimDtoValues?: Omit<ClaimDto, "id" | "eTag">;
}): Promise<string> => {
  const { values, submitOptions, acc, claimDtoValues } = options;

  const claimDto = getAddClaimDto({
    values,
    claimStatus: submitOptions.claimStatus
  });
  let submittedClaim;
  if (values.id) {
    submittedClaim = await acc.patchClaim({
      ...claimDto,
      id: values.id,
      ...claimDtoValues
    });
  } else {
    submittedClaim = await acc.addClaim({
      ...claimDto,
      ...claimDtoValues
    });
  }

  if (submitOptions.isLodge) {
    await acc.addLodgeAccClaim(submittedClaim.id);
  }

  return submittedClaim.id;
};

export const convertToClaimPatient = (options: {
  practice: PracticeStore;
  contact?: Contact;
}) => {
  const { contact } = options;

  if (!contact) return undefined;

  const address = contact.defaultAddress;

  const addressValidator = new ClaimAddressValidator();

  const patient: ClaimPatientFormValues = {
    patientFirstName: contact.firstName,
    patientMiddleName: contact.middleName,
    patientLastName: contact.lastName,
    patientEmail: contact.email,
    patientMobileNumber: contact.mobilePhone,
    patientHomePhoneNumber: contact.homePhone,
    patientWorkPhoneNumber: contact.workPhone,
    patientDateOfBirth: contact.birthDate
      ? contact.birthDate.toJSDate()
      : undefined,
    patientEthnicityCode:
      contact.ethnicities?.length === 1
        ? convertLevelFourEthnicityCodeToLevelTwo(contact.ethnicities[0])
        : undefined,
    patientNhiNumber: contact.nhi?.number,
    addressType: undefined,
    patientAddress: undefined,
    patientSex: contact.sex === Sex.Other ? ACCSex.Other : contact.sex
  };

  if (address) {
    const errors: object = addressValidator.validate(address);
    const isValid: boolean = !Object.keys(errors).length;
    if (isValid) {
      patient.addressType = "";
      patient.patientAddress = address;
    } else {
      patient.addressType = "";
      patient.patientAddress = undefined;
    }
  }

  return patient;
};

export const createNewClaimDto = async (options: {
  root: IRootStore;
  providerUser: User;
  patient: Contact;
}) => {
  const { root, providerUser, patient } = options;

  const { practice } = root;

  const [providerTypeCodes, provider, defaultNZInsurer] = await Promise.all([
    practice.ref.accProviderTypes.keyTextValues,
    practice.getProvider(providerUser.id),
    practice.getDefaultInsurers()
  ]);

  const insurerId = defaultNZInsurer.defaultNzPublicInsurerId;
  const insurer = await practice.getContact(insurerId);

  const claimPatient = convertToClaimPatient({
    practice,
    contact: patient
  });

  let providerTypeCode = "";
  if (providerTypeCodes.length === 1) {
    providerTypeCode = providerTypeCodes[0].key;
  }

  const claim: AddClaimDto = {
    providerId: providerUser.id,
    providerFirstName: providerUser?.firstName,
    providerMiddleName: providerUser?.middleName,
    providerLastName: providerUser?.lastName,
    insurerContactId: insurerId,
    insurerName: insurer.name,
    providerTypeCode,
    referralIn: false,
    private: false,
    hpiCpn: provider.cpn,
    patientId: patient.id,
    ...claimPatient,
    patientDateOfBirth: claimPatient
      ? DateTime.jsDateToISODate(claimPatient.patientDateOfBirth)
      : undefined
  };

  return claim;
};

export const isClaimStatusDeletable = (claimStatus: string) => {
  return (
    claimStatus === ClaimStatuses.Incomplete ||
    claimStatus === ClaimStatuses.Ready ||
    claimStatus === ClaimStatuses.NotVerified ||
    claimStatus === ClaimStatuses.Error
  );
};

export const canClaimBeLodged = (claim: Claim) => {
  return (
    claim.claimStatus !== ClaimStatuses.Incomplete &&
    claim.claimStatus !== ClaimStatuses.Accepted &&
    claim.claimStatus !== ClaimStatuses.Pending
  );
};

export const addNewClaim = async (claim: AddClaimDto, acc: AccStore) => {
  return await acc.addClaim({
    ...claim,
    claimNumber: claim.claimNumber?.trim()
  });
};

export const patchExistingClaim = async (
  claim: PatchClaimDto,
  acc: AccStore
) => {
  return await acc.patchClaim({
    ...claim,
    id: claim.id,
    claimNumber: claim.claimNumber?.trim()
  });
};

export const displayClaimHeading = (
  patientFullName: string,
  claimNumber?: string
) => {
  return claimNumber
    ? `${patientFullName} - ${claimNumber}`
    : `${patientFullName} - Claim`;
};

export const checkIfProviderCanDiagnose =
  (): FieldValidator<string> =>
  (value, parent: ProviderDeclarationValuesType) =>
    value &&
    (parent.providerTypeCode === AccProviderType.Acupuncturist ||
      parent.providerTypeCode === AccProviderType.OccupationalTherapist ||
      parent.providerTypeCode === AccProviderType.Orthotist)
      ? "Provider can't diagnose"
      : undefined;

export const trimHpiCpn = (cpnNumber?: string) => {
  if (cpnNumber === undefined) {
    return;
  }
  return cpnNumber.substring(0, 6);
};
