import { FC, ReactNode } from "react";

import { ITag, Stack } from "@bps/fluent-ui";
import { nameOfFieldArray } from "@libs/utils/name-of.utils.ts";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ServiceItemFormValues } from "@shared-types/acc/service-item-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { minimumSearchLengthFilter } from "@ui-components/form/utils.ts";

interface ServiceItemFieldProps {
  name: string;
  button?: ReactNode;
}

export const ServiceItemFieldBase: FC<ServiceItemFieldProps> = ({
  name,
  button
}) => {
  const { acc } = useStores();
  const fieldName = nameOfFieldArray<ServiceItemFormValues>(name);

  const allServiceitems = acc.ref.serviceItems.values.map<ITag>(item => ({
    key: item.code,
    name: `${item.code} - ${item.text}`
  }));

  const handleOnFilterItems = (searchText: string) => {
    return minimumSearchLengthFilter(searchText, 2, allServiceitems);
  };

  return (
    <Stack
      horizontal
      styles={{
        root: {
          padding: 8
        }
      }}
      tokens={{ childrenGap: 8 }}
    >
      <Stack.Item
        grow={1}
        styles={{
          root: { width: 0 }
        }}
      >
        <StaticPickerField
          name={fieldName("serviceItemCode")}
          required
          resolveDelay={300}
          fetchDataSource={() => allServiceitems}
          onFilterItems={handleOnFilterItems}
          onEmptySearchItems={() => []}
          styles={{ itemsWrapper: { display: "block" } }}
          inputProps={{
            placeholder: ClaimFormLabels.serviceItemsInputPlaceholder
          }}
        />
      </Stack.Item>
      <Stack.Item
        styles={{
          root: { flexBasis: 80, marginLeft: 0 }
        }}
      >
        <SpinNumberInputField
          styles={{ spinButtonWrapper: { width: 120 } }}
          name={fieldName("spent")}
          prefix="$"
          parse={value => value ?? ""}
          step={0.01}
          precision={2}
        />
      </Stack.Item>
      {button}
    </Stack>
  );
};

export const ServiceItemField = withFetch(
  x => [x.acc.ref.diagnosisSides.load()],
  ServiceItemFieldBase
);
