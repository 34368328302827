import { FC } from "react";
import { useForm } from "react-final-form";

import {
  CenteredLargeSpinner,
  FontWeights,
  Heading,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, TIME_FORMATS } from "@bps/utils";
import { ClinicalTaskDescription } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClaimPickerField } from "@modules/acc/screens/shared-components/claim-picker/ClaimPickerField.tsx";
import { splitClaimKeyString } from "@modules/acc/screens/shared-components/claim-picker/utils.ts";
import { ConfidentialToggleField } from "@modules/clinical/screens/document-writer/components/ConfidentialToggleField.tsx";
import { ClinicalTaskFormValues } from "@shared-types/clinical/clinical-task-values.type.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { RecordUpdateCheckedLog } from "../../../shared-components/RecordUpdateCheckedLog.tsx";
import { ClinicalTaskDue } from "./types/clinical-task-due.enum.ts";
import { ClinicalTaskType } from "./types/clinical-task.type.ts";

enum Labels {
  claim = "Claim",
  description = "Description",
  due = "Due",
  priority = "Priority",
  comment = "Comment",
  lockText = "Only I can make changes to this task"
}

const nameOf = nameOfFactory<ClinicalTaskFormValues>();

export interface ClinicalTaskFormProps {
  clinicalTask?: ClinicalTask;
  clinicalRecordId?: string;
}

const ClinicalTaskFormFieldsComponent: FC<ClinicalTaskFormProps> = ({
  clinicalTask,
  clinicalRecordId
}) => {
  const { clinical, core } = useStores();
  const form = useForm<ClinicalTaskFormValues>();
  const formValues = form.getState().values;
  const required = formValues.taskType === ClinicalTaskType.Other;
  const fixedOrderItems = [
    "2mth Immunisation",
    "4mth Immunisation",
    "6mth Immunisation",
    "12mth Immunisation",
    "4-5y Immunisation"
  ];

  return (
    <>
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack.Item>
          <ComboBoxField
            disabled={formValues.isSystemGenerated}
            label={Labels.description}
            name={nameOf("taskType")}
            required
            options={clinical.ref.clinicalTaskTypes.keyTextValues.sort(
              (a, b) => {
                const aIndex = fixedOrderItems.indexOf(a.text);
                const bIndex = fixedOrderItems.indexOf(b.text);

                if (aIndex !== -1 && bIndex !== -1) {
                  return aIndex - bIndex;
                }

                if (aIndex !== -1) {
                  return -1;
                }

                if (bIndex !== -1) {
                  return 1;
                }

                return a.text.localeCompare(b.text);
              }
            )}
            useComboBoxAsMenuWidth
            calloutProps={{
              calloutMaxHeight: 500
            }}
            placeholder={
              core.isNZTenant
                ? "e.g Follow-up request"
                : "e.g Influenza vaccination"
            }
          />
        </Stack.Item>
        <FieldCondition
          when={nameOf("taskType")}
          is={(value: ClinicalTaskDescription) =>
            value === ClinicalTaskDescription.ACC32 ||
            value === ClinicalTaskDescription.RecordOutcomeMeasure ||
            value === ClinicalTaskDescription.ClaimReview
          }
        >
          <Stack.Item>
            <ClaimPickerField
              label={Labels.claim}
              name={nameOf("selectedClaim")}
              required
              disabled={formValues.isSystemGenerated}
              patientId={clinicalRecordId}
              pickByClaimNumber={true}
              showBusinessRole={true}
            />
          </Stack.Item>
        </FieldCondition>
        <Stack.Item
          styles={{
            root: {
              paddingBottom: 8
            }
          }}
        >
          <ChoiceGroupField
            horizontal
            styles={{
              root: {
                selectors: {
                  "div.ms-ChoiceFieldGroup-flexContainer": {
                    justifyContent: "space-between"
                  },
                  "div.ms-ChoiceField": {
                    marginTop: 0
                  },
                  label: {
                    marginTop: 5
                  }
                }
              }
            }}
            label={Labels.due}
            name={nameOf("dueChoice")}
            required
            disabled={formValues.isSystemGenerated}
            options={[
              {
                key: ClinicalTaskDue.Date,
                text: "Date",
                onRenderField: (props, render) => {
                  const disabled = !props || !props.checked;
                  return (
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                      {render && render(props)}
                      <DatePickerField
                        name={nameOf("dueDate")}
                        disabled={disabled}
                        minDate={DateTime.jsDateNow()}
                      />
                    </Stack>
                  );
                }
              },
              {
                key: ClinicalTaskDue.Consult,
                text: "in",
                onRenderField: (props, render) => {
                  const disabled =
                    !props || !props.checked || formValues.isSystemGenerated;
                  return (
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                      {render && render(props)}
                      <SpinNumberInputField
                        disabled={disabled}
                        name={nameOf("dueInVisits")}
                        min={1}
                        max={99}
                        parse={value => Number(value)}
                        styles={{
                          spinButtonWrapper: {
                            maxWidth: 90
                          }
                        }}
                      />
                      <Text
                        styles={{
                          root: {
                            marginTop: 5
                          }
                        }}
                      >
                        consults
                      </Text>
                    </Stack>
                  );
                }
              }
            ]}
          />
        </Stack.Item>
        <Stack.Item>
          <ChoiceGroupField
            label={Labels.priority}
            name={nameOf("priority")}
            fieldItemStyles={{ headerWrapper: { padding: 0 } }}
            required
            horizontal
            disabled={formValues.isSystemGenerated}
            options={clinical.ref.clinicalTaskPriorities.keyTextValues}
          />
        </Stack.Item>
        <Stack.Item>
          <ConfidentialToggleField name={nameOf("confidential")} />
        </Stack.Item>
        <Stack.Item>
          <TextInputField
            label={Labels.comment}
            name={nameOf("comment")}
            placeholder="250 characters max"
            resizable={false}
            rows={4}
            maxLength={250}
            required={required}
            multiline
          />
        </Stack.Item>
        <Stack.Item>
          <Heading
            variant="section-sub-heading"
            styles={{ root: { marginBottom: 8, marginTop: 5 } }}
          >
            Lock task
          </Heading>
          <CheckboxField
            label={Labels.lockText}
            name={nameOf("isLocked")}
            disabled={
              formValues.isSystemGenerated ||
              !(
                (formValues.lockedBy
                  ? formValues.lockedBy === core.userId
                  : true) ||
                core.permissions.includes(Permission.ClinTaskUnlock)
              )
            }
          />
        </Stack.Item>
        {formValues.isSystemGenerated &&
          clinicalTask?.changeLog?.createdDate && (
            <Stack.Item>
              <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>
                System generated
              </Text>
              {` on ${DateTime.fromISO(
                clinicalTask.changeLog.createdDate
              ).toFormat(
                DATE_FORMATS.DAY_TEXT_MONTH_YEAR
              )} at ${DateTime.fromISO(
                clinicalTask.changeLog.createdDate
              ).toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT)}`}
            </Stack.Item>
          )}

        {!formValues.isSystemGenerated &&
          clinicalTask?.changeLog?.createdBy && (
            <RecordUpdateCheckedLog
              createdBy={clinicalTask.changeLog.createdBy}
              createdDate={clinicalTask.changeLog.createdDate}
              updatedBy={clinicalTask.changeLog.updatedBy}
              updatedDate={clinicalTask.changeLog.updatedDate}
              styles={{
                root: {
                  justifyContent: "flex-start",
                  paddingTop: 8
                }
              }}
            />
          )}
      </Stack>
      <FieldSpy
        name={nameOf("isLocked")}
        onChange={value => {
          form.change(nameOf("lockedBy"), value ? core.userId : undefined);
        }}
      />
      <FieldSpy
        name={nameOf("dueChoice")}
        onChange={(value: string) =>
          value === ClinicalTaskDue.Date
            ? form.change(nameOf("dueInVisits"), 0)
            : form.change(nameOf("dueDate"), undefined)
        }
      />
      <FieldSpy
        name={nameOf("selectedClaim")}
        onChange={(value: string) => {
          const keySplitValues = splitClaimKeyString(value);
          form.change(nameOf("claimNumber"), keySplitValues.keyId);
          form.change(nameOf("businessRole"), keySplitValues.keyBusinessCode);
        }}
      />
      <FieldSpy
        name={nameOf("taskType")}
        onChange={(value: string, values) => {
          if (
            value !== ClinicalTaskDescription.ACC32 &&
            value !== ClinicalTaskDescription.RecordOutcomeMeasure &&
            values?.claimNumber
          ) {
            form.change(nameOf("claimNumber"), undefined);
            // change claimNumber to empty
          }
        }}
      />

      <FieldSpy
        name={nameOf("confidential")}
        onChange={(value: boolean) => {
          form.change(nameOf("isLocked"), value);
        }}
      />
    </>
  );
};

export const ClinicalTaskFormFields = withFetch(
  x => [
    x.clinical.ref.clinicalTaskTypes.load(),
    x.clinical.ref.clinicalTaskPriorities.load()
  ],
  ClinicalTaskFormFieldsComponent,
  {
    fallback: (
      <CenteredLargeSpinner
        centeredBoxProps={{ styles: { root: { height: 480 } } }}
      />
    )
  }
);
