import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Stack, Text } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { routes } from "@libs/routing/routes.ts";
import { catchNotFoundError } from "@libs/utils/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import {
  EventVerb,
  RecentPatientsDetailsProps
} from "./RecentPatientsList.types.ts";

export const RecentConsultLink: FC<RecentPatientsDetailsProps> = observer(
  ({ recentPatient }) => {
    const { clinical, routing } = useStores();

    return (
      <DataFetcher
        fetch={() =>
          clinical.getEncounter(recentPatient.typeId).catch(catchNotFoundError)
        }
      >
        {encounter => {
          if (!encounter) return <i>Unknown consult</i>;

          let link: React.ReactNode;

          if (recentPatient.eventVerb === EventVerb.encounterClosed) {
            link = (
              <Navigate
                to={routes.records.recordUpdate.path({
                  id: recentPatient.patientId
                })}
                state={routing.getStateWithFromQuery()}
              >
                Record update
              </Navigate>
            );
          } else {
            link = (
              <Navigate
                to={routes.records.encounter.path({
                  id: recentPatient.patientId,
                  encounterId: encounter.id
                })}
                state={routing.getStateWithFromQuery()}
              >
                Complete notes
              </Navigate>
            );
          }

          return (
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <Text>
                {encounter.startDateTime.toFormat(
                  DATE_FORMATS.LONG_DATE_TIME_FORMAT
                )}
              </Text>{" "}
              {link}
            </Stack>
          );
        }}
      </DataFetcher>
    );
  }
);
