import { observer, Observer } from "mobx-react-lite";
import React, { FC, useState } from "react";

import {
  ActionButton,
  Badge,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  FontSizes,
  FontWeights,
  Heading,
  IColumn,
  IconButton,
  IContextualMenuItem,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  Spinner,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost
} from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";
import { DiagnosisSides } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  MedicalCertainty,
  MedicalHistoryClinicalDataDto,
  MedicalHistoryClinicalDataItemDto,
  MedicalHistoryTestElements,
  TerminologyLookupRequest
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  AddSecGroupAccessRequestDto,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { TerminologyText } from "@modules/clinical/screens/patient-record/components/TerminologyText.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { MedicalCertaintiesText } from "@ui-components/RefText.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { ConfidentialToolTipFontIcon } from "../ConfidentialToolTipFontIcon.tsx";
import { DeleteMedicalHistoryDialog } from "./DeleteMedicalHistoryDialog.tsx";
import { MedicalHistoryFilterType } from "./MedicalHistoryFilter.types.ts";
import { MedicalHistoryFilter } from "./MedicalHistoryFilters.tsx";
import { MedicalHistoryFormDialog } from "./MedicalHistoryFormDialog.tsx";
import {
  deleteMedicalHistoryItem,
  editMedicalHistoryItem,
  filterData,
  sortMedicalHistoryClinicalDates
} from "./utils.ts";

export const getDate = (diagnosisDate: string | undefined) => {
  if (!diagnosisDate) {
    return "N/A";
  }

  const [year, month, day] = diagnosisDate.split("-");

  let date = year;
  if (day) {
    date = `${day}/${month}/${year}`;
  } else if (month) {
    date = `${month}/${year}`;
  }
  return date;
};

export interface MedicalHistoryProps {
  clinicalRecord: ClinicalRecord;
}

export interface MedicalHistoryBaseProps extends MedicalHistoryProps {
  users: User[];
}

const MedicalHistoryBase: FC<MedicalHistoryBaseProps> = observer(
  ({ clinicalRecord, users }) => {
    const { clinical, core } = useStores();
    const primaryDiagnoses =
      clinicalRecord.clinicalData?.primaryDiagnoses ??
      clinicalRecord.episodeOfCare?.diagnoses?.find(
        item => item.isPrimaryDiagnosis
      );

    let medicalHistories =
      clinicalRecord.clinicalData?.medicalHistory?.medicalHistories ?? [];

    //Confidential feature toggle
    if (!core.hasPermissions(Permission.ConfidentialDataAllowed)) {
      medicalHistories = medicalHistories.filter(x => !x.secGroupId);
    }

    const getFilteredItems = (
      dataSource: MedicalHistoryClinicalDataItemDto[],
      filter: MedicalHistoryFilterType
    ): MedicalHistoryClinicalDataItemDto[] =>
      sortMedicalHistoryClinicalDates(
        dataSource.filter(i =>
          filterData(i, filter, {
            terminology: i.diagnosis
              ? clinical.terminologyMap.get(i.diagnosis)
              : undefined,
            hasAccessToSecGroup: core.hasAccessToSecGroup(i.secGroupId)
          })
        )
      );

    const [isMedicalHistoryDialogVisible, setIsMedicalHistoryDialogVisible] =
      useState<boolean>(false);

    const [isDeleteDialogVisible, setIsDeleteDialogVisible] =
      useState<boolean>(false);

    const [selectedDiagnosis, setSelectedDiagnosis] = useState<
      MedicalHistoryClinicalDataItemDto | undefined
    >();

    const openMedicalHistoryDialog = (
      item?: MedicalHistoryClinicalDataItemDto
    ) => {
      setSelectedDiagnosis(item);
      setIsMedicalHistoryDialogVisible(true);
    };

    const closeMedicalHistoryDialog = () => {
      setSelectedDiagnosis(undefined);
      setIsMedicalHistoryDialogVisible(false);
    };

    const onDeleteCancel = () => {
      setIsDeleteDialogVisible(false);
    };

    const onDeleteDiagnosis = (item: MedicalHistoryClinicalDataItemDto) => {
      setSelectedDiagnosis(item);
      setIsDeleteDialogVisible(true);
    };

    const onDeleteConfirm = async (
      reasonForDelete: string,
      reasonForDeleteComment?: string
    ) => {
      if (selectedDiagnosis?.id) {
        const clinicalData = await deleteMedicalHistoryItem({
          clinicalRecord,
          id: selectedDiagnosis?.id,
          reasonForDelete,
          deletedComment: reasonForDeleteComment
        });
        await clinicalRecord.saveClinicalData(clinicalData);
        onDeleteCancel();
      }
    };

    const onSignificantMedicalHistoryChange = async () => {
      const medicalHistory: MedicalHistoryClinicalDataDto = {
        eTag: clinicalRecord.clinicalData?.medicalHistory?.eTag,
        noSignificantHistory: true,
        medicalHistories: []
      };

      await clinicalRecord.saveClinicalData({
        medicalHistory
      });
    };

    const onStatusChange = async (item: MedicalHistoryClinicalDataItemDto) => {
      if (item.id) {
        const currentMedicalHistoryItem = clinicalRecord.medicalHistories.find(
          x => x.id === item.id
        );

        if (currentMedicalHistoryItem) {
          const clinicalData = editMedicalHistoryItem({
            clinicalRecord,
            id: item.id,
            medicalHistoryItem: {
              ...currentMedicalHistoryItem,
              active: !currentMedicalHistoryItem.active
            }
          });

          await clinicalRecord.saveClinicalData(clinicalData);
        }
      }
    };

    const onUpdateConfidentiality = async (
      item: MedicalHistoryClinicalDataItemDto
    ) => {
      if (item.id) {
        const currentMedicalHistoryItem = clinicalRecord.medicalHistories.find(
          x => x.id === item.id
        );

        if (currentMedicalHistoryItem) {
          const clinicalData = editMedicalHistoryItem({
            clinicalRecord,
            id: item.id,
            medicalHistoryItem: {
              ...currentMedicalHistoryItem,
              secGroupId: !!currentMedicalHistoryItem.secGroupId
                ? undefined
                : core.user?.privateSecGroupId
            }
          });

          await clinicalRecord.saveClinicalData(clinicalData);
        }
      }
    };

    const onItemContextMenu = (_item: any): IContextualMenuItem[] => {
      const items: IContextualMenuItem[] = [
        {
          key: "Edit",
          name: "Edit details",
          onClick: () => {
            openMedicalHistoryDialog(_item);
          }
        },
        {
          key: "active",
          name: `Make ${_item.active ? "Inactive" : "Active"}`,
          onClick: () => {
            onStatusChange(_item);
          }
        }
      ];

      if (core.hasPermissions(Permission.ConfidentialDataAllowed)) {
        items.push({
          key: "Confidential",
          name: !!_item.secGroupId
            ? "Remove confidentiality"
            : "Make confidential",
          onClick: () => {
            onUpdateConfidentiality(_item);
          }
        });
      }

      items.push({
        key: "Delete",
        name: "Delete",
        onClick: () => {
          onDeleteDiagnosis(_item);
        }
      });

      return items;
    };

    const isPrimaryDiagnosis = (item: MedicalHistoryClinicalDataItemDto) =>
      clinicalRecord.episodeOfCare?.id &&
      clinicalRecord.episodeOfCare?.id === item.episodeOfCareId &&
      item.diagnosisCode?.originalText ===
        primaryDiagnoses?.diagnosisCode?.originalText &&
      item.diagnosisCode?.code === primaryDiagnoses?.diagnosisCode?.code &&
      ((primaryDiagnoses?.diagnosisSide === DiagnosisSides.NotApplicable &&
        item.diagnosisSide === undefined) ||
        item.diagnosisSide === primaryDiagnoses?.diagnosisSide);

    const hasNoSignificantHistory =
      clinicalRecord.clinicalData?.medicalHistory?.noSignificantHistory;

    const isEmptyList = !medicalHistories.length;

    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

    const columns: IColumn[] = [];

    if (!isViewOnlyOrDischarged) {
      columns.push({
        key: "button",
        onRender: (item: MedicalHistoryClinicalDataItemDto) => {
          return (
            <Stack horizontalAlign="center" verticalAlign="center">
              {!core.hasAccessToSecGroup(item.secGroupId) ? (
                <Observer>
                  {() => {
                    let itemDisabled = false;

                    if (!!item.secGroupId && !!core.user?.id) {
                      core.secGroupAccessRequestMap.forEach(
                        secGroupAccessRequest => {
                          if (
                            secGroupAccessRequest.userId === core.user?.id &&
                            secGroupAccessRequest.secGroupId ===
                              item.secGroupId &&
                            !secGroupAccessRequest.status
                          ) {
                            itemDisabled = true;
                          }
                        }
                      );
                    }

                    return (
                      <TooltipHost
                        content={
                          itemDisabled
                            ? "User already has an active request to access this data"
                            : "Request access"
                        }
                      >
                        <IconButton
                          iconProps={{ iconName: "ShieldAlert" }}
                          disabled={itemDisabled}
                          onClick={async () => {
                            if (!core?.user?.id) {
                              throw new Error("User undefined");
                            }

                            if (!item.secGroupId) {
                              throw new Error("SecGroup undefined");
                            }

                            const args: AddSecGroupAccessRequestDto = {
                              userId: core.user.id,
                              secGroupId: item.secGroupId
                            };

                            await core.addSecGroupAccessRequestStatus(args);
                          }}
                          styles={{
                            root: {
                              margin: "auto 4px",
                              fontSize: FontSizes.size16
                            }
                          }}
                        />
                      </TooltipHost>
                    );
                  }}
                </Observer>
              ) : (
                <IconButton
                  {...dataAttribute(
                    DataAttributes.Element,
                    MedicalHistoryTestElements.MoreButton
                  )}
                  iconProps={{
                    iconName: "More"
                  }}
                  onRenderMenuIcon={() => null}
                  menuProps={{ items: onItemContextMenu(item) }}
                  styles={{
                    root: { width: "32px", height: "36px", padding: 0 },
                    flexContainer: { width: "32px", height: "36px" }
                  }}
                />
              )}
            </Stack>
          );
        },
        name: "",
        className: RESET_CELLS_PADDING_CLASSNAME,
        minWidth: 30,
        maxWidth: 30
      });
    }

    columns.push({
      key: "date",
      name: "Date",
      minWidth: 100,
      maxWidth: 100,
      isMultiline: true,
      onRender: item => {
        return (
          <Stack>
            <Text
              styles={{ root: { paddingBottom: 4 } }}
              {...dataAttribute(
                DataAttributes.Element,
                MedicalHistoryTestElements.DateColumn
              )}
            >
              {getDate(item.diagnosisDate)}
            </Text>
            {item.certainty &&
              item.certainty !== MedicalCertainty.Confirmed && (
                <Badge
                  styles={{
                    root: { borderRadius: 2 }
                  }}
                  {...dataAttribute(
                    DataAttributes.Element,
                    MedicalHistoryTestElements.CertaintyColumn
                  )}
                >
                  <MedicalCertaintiesText code={item.certainty} />
                </Badge>
              )}
          </Stack>
        );
      }
    });

    const getSideText = (item: { diagnosisSide: any }) => {
      if (item.diagnosisSide) {
        const sideOfBody = clinical.ref.sidesOfBody.keyTextValues.find(
          x => x.key === item.diagnosisSide
        )?.text;

        if (sideOfBody) {
          return `(${sideOfBody})`;
        }
      }

      return "";
    };

    columns.push({
      key: "details",
      name: "Diagnosis / Procedure",
      minWidth: 150,
      maxWidth: 150,
      isMultiline: true,
      onRender: item => {
        const providerName = users.find(
          x => x.privateSecGroupId === item.secGroupId
        )?.fullName;

        return core.hasAccessToSecGroup(item.secGroupId) ? (
          <>
            <Stack
              horizontal
              wrap
              styles={{
                root: {
                  paddingBottom: 4,
                  display: "flex"
                }
              }}
            >
              <Text
                {...dataAttribute(
                  DataAttributes.Element,
                  MedicalHistoryTestElements.DiagnosisColumn
                )}
              >
                <TerminologyText
                  codedTexts={[
                    {
                      code: item.diagnosisCode?.code,
                      text: `${item.diagnosisCode?.originalText} ${getSideText(
                        item
                      )}`
                    }
                  ]}
                />
              </Text>

              <ConfidentialToolTipFontIcon
                isShowConfidentialIcon={item.secGroupId}
                content="Confidential. Use action menu to release"
              />

              {!item.active && (
                <Text
                  styles={{
                    root: { fontWeight: FontWeights.bold, paddingLeft: "4px" }
                  }}
                >
                  (inactive)
                </Text>
              )}
            </Stack>
            {isPrimaryDiagnosis(item) && (
              <TextBadge
                badgeColor={TextBadgeColor.yellow}
                badgeSize={TextBadgeSize.small}
                styles={{ root: { maxWidth: 57 } }}
              >
                Primary
              </TextBadge>
            )}
          </>
        ) : (
          <Stack horizontal>
            <Text>{`Confidential by ${providerName}`}</Text>
          </Stack>
        );
      }
    });

    const isExistConfidentialHxOfOthers = !!medicalHistories.find(
      x => x.secGroupId && !core.hasAccessToSecGroup(x.secGroupId)
    );

    return (
      <>
        <Heading
          variant="section-heading-light"
          styles={{ root: { padding: "5px 0" } }}
        >
          Medical history
        </Heading>

        {!isViewOnlyOrDischarged && (
          <ActionButton
            text="Add Medical Hx"
            iconProps={{
              iconName: "Add"
            }}
            onClick={() => openMedicalHistoryDialog()}
            styles={{ root: { alignSelf: "flex-start" } }}
          />
        )}
        {!isViewOnlyOrDischarged && isEmptyList && !hasNoSignificantHistory && (
          <ActionButton
            {...dataAttribute(
              DataAttributes.Element,
              MedicalHistoryTestElements.NoHistoryButton
            )}
            styles={{ root: { alignSelf: "flex-start" } }}
            text="No significant history"
            iconProps={{
              iconName: "Blocked12"
            }}
            onClick={onSignificantMedicalHistoryChange}
          />
        )}

        {!isEmptyList && (
          <MedicalHistoryFilter
            isExistConfidentialHx={isExistConfidentialHxOfOthers}
          >
            {(state, actions) => {
              if (
                !isExistConfidentialHxOfOthers &&
                !!state.values.confidential
              ) {
                actions.setValue("confidential", false);
              }
              return (
                <div style={{ position: "relative", flexGrow: 1 }}>
                  <ScrollablePane
                    styles={{ root: { height: "100%", marginTop: 8 } }}
                  >
                    <Observer>
                      {() => (
                        <ShimmeredDetailsList
                          setKey="medicalHistoryDetailList"
                          columns={columns.map(c => ({
                            ...c,
                            onRender: (item, index) => (
                              <div
                                {...dataAttribute(
                                  DataAttributes.Element,
                                  c.key
                                )}
                              >
                                {c.onRender ? c.onRender(item, index) : c.name}
                              </div>
                            )
                          }))}
                          items={getFilteredItems(
                            medicalHistories,
                            state.values
                          )}
                        />
                      )}
                    </Observer>
                  </ScrollablePane>
                </div>
              );
            }}
          </MedicalHistoryFilter>
        )}

        {isEmptyList && !hasNoSignificantHistory && (
          <Stack
            grow
            verticalFill
            tokens={{ childrenGap: 20 }}
            horizontalAlign="center"
            verticalAlign="center"
            styles={(props, theme) => ({
              root: {
                background: theme.palette.neutralLighterAlt,
                padding: 8
              }
            })}
          >
            <Stack.Item
              styles={{
                root: {
                  height: "80px"
                }
              }}
            >
              <Text
                styles={{
                  root: {
                    fontSize: 14,
                    fontStyle: "italic"
                  }
                }}
              >
                No medical history entries
              </Text>
            </Stack.Item>
            {!isViewOnlyOrDischarged && (
              <>
                <Stack.Item styles={{ root: { height: "40px" } }}>
                  <DefaultButton
                    {...dataAttribute(
                      DataAttributes.Element,
                      MedicalHistoryTestElements.AddEntryButton
                    )}
                    text="Add a medical history entry"
                    iconProps={{ iconName: "CircleRing" }}
                    onClick={() => openMedicalHistoryDialog()}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Navigate onClick={onSignificantMedicalHistoryChange}>
                    Record no significant history
                  </Navigate>
                </Stack.Item>
              </>
            )}
          </Stack>
        )}

        {isEmptyList && hasNoSignificantHistory === true && (
          <Stack
            grow
            verticalFill
            tokens={{ childrenGap: 20 }}
            horizontalAlign="center"
            verticalAlign="center"
            styles={(props, theme) => ({
              root: {
                background: theme.palette.neutralLighterAlt,
                padding: 8
              }
            })}
          >
            <Stack.Item>
              <Text
                styles={{
                  root: {
                    fontSize: 14,
                    fontStyle: "italic"
                  }
                }}
              >
                No significant medical history recorded
              </Text>
            </Stack.Item>
          </Stack>
        )}

        <MedicalHistoryFormDialog
          hidden={!isMedicalHistoryDialogVisible}
          selectedDiagnosis={selectedDiagnosis}
          onDismiss={closeMedicalHistoryDialog}
          medicalHistories={medicalHistories}
        />

        <DeleteMedicalHistoryDialog
          hidden={!isDeleteDialogVisible}
          onConfirm={onDeleteConfirm}
          onCancel={onDeleteCancel}
          item={selectedDiagnosis}
        />
      </>
    );
  }
);

export const MedicalHistoryMiddle: React.FC<MedicalHistoryProps> = props => {
  const { clinical, core } = useStores();
  const { clinicalRecord } = props;

  const dataSource =
    clinicalRecord.clinicalData?.medicalHistory?.medicalHistories ?? [];

  const fetchData = async () => {
    // NOTE: Get lookup a coded system on the ontoserver
    const terminologyLookupCodes: TerminologyLookupRequest[] = dataSource
      .filter(({ diagnosisCode }) => diagnosisCode?.codeSystem)
      .map(
        ({ diagnosisCode }) =>
          diagnosisCode && {
            code: diagnosisCode.code,
            codeSystem: diagnosisCode.codeSystem,
            version: diagnosisCode.version
          }
      )
      .filter(isDefined);

    const [terminology, users] = await Promise.all([
      terminologyLookupCodes?.length > 0
        ? clinical.getTerminologiesLookup(terminologyLookupCodes)
        : undefined,
      core.fetchUsers({
        privateSecGroupIds: dataSource
          .filter(x => !!x.secGroupId)
          .map(x => x.secGroupId!)
      })
    ]);

    return { terminology, users };
  };

  return (
    <DataFetcher fetch={fetchData} fallback={<Spinner />}>
      {data => <MedicalHistoryBase users={data.users} {...props} />}
    </DataFetcher>
  );
};

export const MedicalHistory = withFetch(
  x => [x.clinical.ref.sidesOfBody.load()],
  MedicalHistoryMiddle
);
