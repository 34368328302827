import { observer, Observer } from "mobx-react-lite";
import { ReactNode, useState } from "react";

import {
  DefaultButton,
  DetailsListLayoutMode,
  SelectionMode,
  ToolTipButton,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalNotification } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { FormHeaderWrapper } from "../clinical-form/FormHeaderWrapper.tsx";
import { ClinicalActivityDialog } from "./ClinicalActivityDialog.tsx";
import { ClinicalActivityTable } from "./ClinicalActivityTable.tsx";
import { ClinicalNotificationCompleteDialog } from "./ClinicalNotificationCompleteDialog.tsx";
import { ClinicalNotificationDeleteDialog } from "./ClinicalNotificationDeleteDialog.tsx";
import { PatientClinicalActivityFormModel } from "./PatientClinicalActivityFormModel.ts";
import { ClinicalActivityType } from "./types/clinical-activity.type.ts";

export interface ClinicalActivityListProps {
  clinicalRecord: ClinicalRecord;
}

export interface ClinicalActivityListFilter {
  activityTypes?: string[];
  activityPriorities?: string[];
  activityStatuses?: string[];
  activityDescriptionSearch?: string;
  dueDateStartDate?: Date | undefined;
  dueDateEndDate?: Date | undefined;
  dueIncrement?: string | undefined;
  tasksRequired?: boolean;
  remindersRequired?: boolean;
  overdueRequired?: boolean;
}

export const ClinicalActivityListBase: React.FC<ClinicalActivityListProps> =
  observer(({ clinicalRecord }) => {
    const root = useStores();
    const theme = useTheme();

    const currentUser = root.core.user;

    const [dialogVisible, setDialogVisible] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [completeDialogVisible, setCompleteDialogVisible] = useState(false);

    const [selectedNotifications, setSelectedNotifications] = useState<
      ClinicalActivity[]
    >([]);

    const incompleteNotifications =
      selectedNotifications?.filter(x => !x.isCompleted) ?? [];

    const hasLockedNotificationsByOtherUsers = selectedNotifications?.some(
      x => !x.isCompleted && x.isLocked && x.lockedBy !== currentUser?.id
    );

    const hasIncompleteNotifications = incompleteNotifications.length > 0;

    const isDeleteDisabled =
      !hasIncompleteNotifications ||
      hasLockedNotificationsByOtherUsers ||
      root.clinical.ui.clinicalActivityActionDisabled;

    const isEditDisabled =
      dialogVisible ||
      !hasIncompleteNotifications ||
      selectedNotifications.length > 1 ||
      hasLockedNotificationsByOtherUsers ||
      root.clinical.ui.clinicalTaskActionDisabled;

    const {
      initialValues,
      onSubmit,
      deleteConfirmed,
      filterActivities,
      markCompleted
    } = new PatientClinicalActivityFormModel(
      clinicalRecord,
      root,
      selectedNotifications[0]
    );

    const handleDeleteConfirmed = async (
      reasonForDelete: string,
      reasonForDeleteComment?: string
    ) => {
      await deleteConfirmed(
        incompleteNotifications,
        reasonForDelete,
        reasonForDeleteComment
      );
      setSelectedNotifications([]);
      // Reload clinical activity
      clinicalRecord.loadClinicalActivities();

      deleteCancel(true);
    };

    const handleDeleteCancel = () => {
      deleteCancel(false);
    };

    const deleteCancel = (deselectAll: boolean) => {
      setDeleteDialogVisible(false);
      if (deselectAll) setSelectedNotifications([]);
    };

    const handleCancelDialog = () => {
      setDialogVisible(false);
    };

    const completeCancel = (deselectAll: boolean) => {
      setCompleteDialogVisible(false);
      if (deselectAll) setSelectedNotifications([]);
    };

    const handleCompleteCancel = () => {
      completeCancel(false);
    };

    const handleCompleteConfirmed = async (notes?: string) => {
      await markCompleted(incompleteNotifications, notes);
      setSelectedNotifications([]);
      // Reload clinical activity
      clinicalRecord.loadClinicalActivities();

      completeCancel(true);
    };

    const renderAdditionalButtons: ReactNode = (
      <>
        <When permission={Permission.ClinActivityWrite}>
          <ToolTipButton
            toolTipContent={ClinicalNotification.AddNotification}
            buttonProps={{
              text: ClinicalNotification.Add,
              iconProps: { iconName: "add" },
              primary: true,
              disabled: root.clinical.ui.clinicalActivityActionDisabled,
              onClick: () => {
                setDialogVisible(true);
                setSelectedNotifications([]);
              }
            }}
          />
        </When>

        <When permission={Permission.ClinTaskWrite}>
          <DefaultButton
            disabled={isDeleteDisabled}
            onClick={() => setCompleteDialogVisible(true)}
            text={ClinicalNotification.MarkAsCompleted}
          />
        </When>

        <When permission={Permission.ClinTaskWrite}>
          <DefaultButton
            disabled={isEditDisabled}
            onClick={() => setDialogVisible(true)}
            text={ClinicalNotification.Edit}
          />
        </When>

        <When permission={Permission.ClinActivityDelete}>
          <DefaultButton
            disabled={isDeleteDisabled}
            onClick={() => setDeleteDialogVisible(true)}
            text={ClinicalNotification.Delete}
          />
        </When>
      </>
    );

    const isEdit = dialogVisible && selectedNotifications.length === 1;

    return (
      <FormHeaderWrapper
        heading={ClinicalNotification.NotificationListHeading}
        onClose={root.clinical.ui.tabs.currentPatientRecordTab!.hideActive}
        saveButtons={renderAdditionalButtons}
      >
        <ClinicalActivityDialog
          hidden={!dialogVisible}
          clinicalRecordId={clinicalRecord.id}
          clinicalActivities={clinicalRecord?.clinicalActivities}
          clinicalActivity={isEdit ? selectedNotifications[0] : undefined}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onDismiss={handleCancelDialog}
        />

        <ClinicalNotificationDeleteDialog
          hidden={!deleteDialogVisible}
          selectedCount={incompleteNotifications.length ?? 0}
          onConfirm={handleDeleteConfirmed}
          onCancel={handleDeleteCancel}
          selectedNotifications={selectedNotifications}
        />

        <ClinicalNotificationCompleteDialog
          hidden={!completeDialogVisible}
          selectedCount={incompleteNotifications.length ?? 0}
          onConfirm={handleCompleteConfirmed}
          onCancel={handleCompleteCancel}
        />

        <FilterBar<ClinicalActivityListFilter>
          initialValues={{
            activityStatuses: [
              ClinicalActivityStatusText.Upcoming,
              ClinicalActivityStatusText.Overdue,
              ClinicalActivityStatusText.Today
            ]
          }}
          presets={[
            {
              text: "Tasks",
              name: "tasksRequired",
              id: "tasks-required",
              iconName: "BpPulseBoard10",
              valuesToBeSetOnToggleOn: {
                remindersRequired: false,
                activityTypes: [ClinicalActivityType.Task]
              },
              valuesToBeSetOnToggleOff: { activityTypes: [] }
            },
            {
              text: "Reminders",
              name: "remindersRequired",
              id: "reminders-required",
              iconName: "BpReminder",
              valuesToBeSetOnToggleOn: {
                tasksRequired: false,
                activityTypes: [ClinicalActivityType.Reminder]
              },
              valuesToBeSetOnToggleOff: { activityTypes: [] }
            },
            {
              text: "Overdue",
              name: "overdueRequired",
              id: "tasks-required",
              iconName: "AlertSolid",
              iconColor: theme.palette.redDark,
              valuesToBeSetOnToggleOn: {
                activityStatuses: [ClinicalActivityStatusText.Overdue]
              },
              valuesToBeSetOnToggleOff: { activityStatuses: [] }
            }
          ]}
          items={[
            {
              name: "activityDescriptionSearch",
              type: "searchBox",
              stickItem: true,
              props: {
                placeholder: "Search by description / reason",
                styles: { root: { maxWidth: 500, minWidth: 500 } }
              }
            },
            {
              type: "optionsSelect",
              name: "activityTypes",
              props: {
                id: "noShow-filter-appointmentDateRange",
                placeholder: "Type",
                options: root.clinical.ref.clinicalActivityTypes.keyTextValues,
                multiSelect: true,
                calloutWidth: 200,
                hideSearchOption: true
              }
            },
            {
              type: "optionsSelect",
              name: "activityStatuses",
              props: {
                id: "noShow-filter-appointmentDateRange",
                placeholder: "Status",
                options: [
                  {
                    key: ClinicalActivityStatusText.Upcoming,
                    text: ClinicalActivityStatusText.Upcoming
                  },
                  {
                    key: ClinicalActivityStatusText.Overdue,
                    text: ClinicalActivityStatusText.Overdue
                  },
                  {
                    key: ClinicalActivityStatusText.Today,
                    text: ClinicalActivityStatusText.Today
                  },
                  {
                    key: ClinicalActivityStatusText.Completed,
                    text: ClinicalActivityStatusText.Completed
                  }
                ],
                multiSelect: true,
                calloutWidth: 200,
                hideSearchOption: true
              }
            },
            {
              type: "optionsSelect",
              name: "activityPriorities",
              props: {
                id: "noShow-filter-appointmentDateRange",
                placeholder: "Priority",
                options: root.clinical.ref.priorities.keyTextValues,
                multiSelect: true,
                calloutWidth: 200,
                hideSearchOption: true
              }
            },
            {
              type: "dateTimeFramePicker",
              name: "dueDateStartDate",
              props: {
                id: "filter-datetimeframepicker",
                startDateName: "dueDateStartDate",
                endDateName: "dueDateEndDate",
                placeholder: "Due",
                incrementName: "dueIncrement",
                incrementProps: {
                  incrementTitle: "Consult count",
                  suffix: "consults"
                }
              }
            }
          ]}
        >
          {children => {
            return (
              <Observer>
                {() => {
                  return (
                    <ClinicalActivityTable
                      onSelectionChanged={setSelectedNotifications}
                      showType
                    >
                      {({ selection, columns, renderRow }) => (
                        <ShimmeredDetailsList
                          setKey="clinical-activity-table"
                          stickyHeader
                          enableShimmer={
                            clinicalRecord.patientClinicalTasksPromise.pending
                          }
                          errorMessage={
                            clinicalRecord.patientClinicalTasksPromise.error
                              ?.message
                          }
                          detailsListStyles={{
                            root: {
                              height: "100%"
                            }
                          }}
                          selectionMode={SelectionMode.multiple}
                          selectionPreservedOnEmptyClick={true}
                          columns={columns}
                          items={filterActivities(
                            clinicalRecord.clinicalActivities,
                            children.values
                          )}
                          onRenderRow={renderRow}
                          isHeaderVisible={true}
                          selection={selection}
                          layoutMode={DetailsListLayoutMode.justified}
                        />
                      )}
                    </ClinicalActivityTable>
                  );
                }}
              </Observer>
            );
          }}
        </FilterBar>
      </FormHeaderWrapper>
    );
  });

export const ClinicalActivityList = withFetch(
  x => [
    x.clinical.ref.clinicalActivityDescriptions.load(),
    x.clinical.ref.clinicalActivityTypes.load(),
    x.clinical.ref.priorities.load(),
    x.comms.loadClinicalReminderPreferenceByTenant()
  ],
  ClinicalActivityListBase
);
