import { observer } from "mobx-react-lite";
import { MutableRefObject, ReactNode } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Heading,
  PrimaryButton,
  Stack,
  ToolTipButton
} from "@bps/fluent-ui";
import {
  MedicationClinicalDataItemDto,
  MedicationsTestElements
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";
import { When } from "@ui-components/withPerm.tsx";

import { CorrespondencePrint } from "../patient-summary/CorrespondencePrint.tsx";
import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { PrescribingWizard } from "./PrescribingWizard.tsx";

interface MedicationFormResponsiveHeaderProps {
  heading?: string;
  onClose: () => void;
  pivotTab?: ReactNode;
  selection: MutableRefObject<
    Selection<
      MedicationClinicalDataItemDto & {
        key: string;
      }
    >
  >;
}

export const MedicationFormResponsiveHeader: React.FC<MedicationFormResponsiveHeaderProps> =
  observer(({ heading, onClose, pivotTab, selection }) => {
    const { core } = useStores();
    const helper = useMedicationsContext();
    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
    const {
      setMedicationDialogVisibleType,
      createPrescription,
      isPrintDialogVisible,
      lastPrintedDocumentId,
      setPrintDialogVisible,
      lastPrintedDocument
    } = helper;
    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { width: "100%" } }}
      >
        <Stack horizontal verticalAlign="center">
          <Heading variant="section-heading">{heading}</Heading>
          <Stack
            horizontal
            styles={{
              root: { marginTop: 10, paddingBottom: 8, paddingLeft: 8 }
            }}
          >
            {pivotTab}
          </Stack>
        </Stack>

        <Stack horizontal verticalAlign="center">
          <PrescribingWizard />
          {isPrintDialogVisible &&
            lastPrintedDocumentId &&
            lastPrintedDocument && (
              <CorrespondencePrint
                completePrinting={() => setPrintDialogVisible(false)}
                clinicalDocument={lastPrintedDocument}
                documentTitle={lastPrintedDocument.name}
              />
            )}
          {!isViewOnlyOrDischarged && (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: "8" }}
            >
              <PrimaryButton
                {...dataAttribute(
                  DataAttributes.Element,
                  MedicationsTestElements.PrescribeButton
                )}
                text="Prescribe"
                iconProps={{
                  iconName: "BpRx"
                }}
                onClick={() => {
                  setMedicationDialogVisibleType("prescribe");
                }}
              />
              <When permission={Permission.RxRecordAllowed}>
                <DefaultButton
                  {...dataAttribute(
                    DataAttributes.Element,
                    MedicationsTestElements.RecordButton
                  )}
                  text="Record"
                  iconProps={{
                    iconName: "PenWorkspace"
                  }}
                  onClick={() => setMedicationDialogVisibleType("record")}
                />
              </When>
              <DefaultButton
                {...dataAttribute(
                  DataAttributes.Element,
                  MedicationsTestElements.PrintButton
                )}
                text="Print"
                iconProps={{
                  iconName: "print"
                }}
                onClick={async () => {
                  await createPrescription(helper.selectedCurrentMeds);
                  const selectedMedsIndices =
                    selection.current.getSelectedIndices();
                  selectedMedsIndices.forEach(x =>
                    selection.current.setIndexSelected(x, false, false)
                  );
                }}
                disabled={
                  helper.selectedCurrentMeds.length === 0 ||
                  !core.hasPermissions(Permission.RxPrescribeAllowed)
                }
              />

              <ToolTipButton
                toolTipContent="Close"
                buttonProps={{
                  text: "Close",
                  onClick: onClose
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  });
