// eslint-disable-next-line import/extensions
import partition from "lodash/partition";

import { flatten, SideRailMenuItem } from "@bps/fluent-ui";
import { groupBy, newGuid, unique } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import {
  AddressType,
  CommunicationDto,
  CommunicationType,
  ContactStatus,
  EmployerDto,
  OrganisationRoleType,
  RelationshipDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";
import { employerRelationships } from "@shared-types/practice/employer.relationship.constant.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { communicationComparer } from "@stores/core/models/Communication.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { OrganisationCardIds } from "../../../shared-components/types/organisation-card-ids.enum.ts";
import { orgRelationships } from "../../constants/org-relationships.constant.ts";
import { professionalRelationships } from "../../constants/professional-relationships.constant.ts";
import {
  defaultAddress,
  defaultCommunication,
  toAddressFieldValue,
  toCommunicationFieldValue
} from "../../shared-components/edit/utils.ts";
import {
  EditOrganisationFormValues,
  OrgRelationshipFieldValue
} from "./EditOrganisationFormValues.tsx";

const hasRelatedContact = (
  relationship: OrgRelationshipFieldValue
): relationship is OrgRelationshipFieldValue & { relatedContactId: string } => {
  return !!relationship.relatedContactId;
};

export const defaultEmployer = (country: string) => {
  return {
    organisationRoleTypeCode:
      country === Country.NewZealand
        ? OrganisationRoleType.NzEmployer
        : OrganisationRoleType.AuEmployer
  };
};

export const defaultPrivateInsurer = (country: string) => {
  return {
    organisationRoleTypeCode:
      country === Country.NewZealand
        ? OrganisationRoleType.NzPrivateInsurer
        : OrganisationRoleType.AuPrivateInsurer
  };
};

export const defaultHealthProvider = (country: string) => {
  return {
    organisationRoleTypeCode:
      country === Country.NewZealand
        ? OrganisationRoleType.NzHealthProvider
        : OrganisationRoleType.AuHealthProvider
  };
};

export const getLinkedOrgId = (relationships: RelationshipDto[]) => {
  return relationships.filter(x => x.relationship === RelationshipType.Managing)
    .length
    ? relationships.filter(x => x.relationship === RelationshipType.Managing)[0]
        .relatedContactId
    : "";
};

export const toRelationshipDto = (
  relationships: OrgRelationshipFieldValue[]
): RelationshipDto[] => {
  const relationshipDto = [...relationships]
    .filter(hasRelatedContact)
    .map(rel => {
      const types = [...rel.professional, ...rel.org];
      return types.map(type => {
        const relationshipDto: RelationshipDto = {
          relatedContactId: rel.relatedContactId,
          relationship: type,
          hasRelationship: true,
          metadata:
            type === RelationshipType.EmployeeOf
              ? {
                  currentJob: rel.currentJob,
                  relationshipTypeCode: RelationshipType.EmployeeOf
                }
              : undefined
        };
        return relationshipDto;
      });
    })
    .reduce((acc, val) => acc.concat(val), []);

  const uniquReltionshipDto = unique(
    relationshipDto.map(rel => JSON.stringify(rel))
  );

  return uniquReltionshipDto.map(rel => JSON.parse(rel));
};

export const toOrgRelationshipFieldValues = (
  relationships: RelationshipDto[]
): OrgRelationshipFieldValue[] => {
  return relationships.filter(
    x =>
      x.relationship !== RelationshipType.Managing &&
      x.relationship !== RelationshipType.PartOf
  ).length
    ? groupBy(relationships, x => x.relatedContactId).map(
        ([relatedContactId, relationships]) => {
          const types = relationships.map(x => x.relationship);
          const currentJob = relationships.some(
            x =>
              x.relationship === RelationshipType.EmployeeOf &&
              x.metadata?.currentJob
          );
          return {
            relatedContactId,
            professional: types.filter(
              x =>
                professionalRelationships.includes(x) ||
                employerRelationships.includes(x)
            ),
            org: types.filter(x => orgRelationships.includes(x)),
            autoFocus: false,
            id: newGuid(),
            currentJob
          };
        }
      )
    : [];
};

export const sortRelationships = (
  relationships: RelationshipDto[],
  contactsMap: Map<string, Contact>
) => {
  //splitting the relationships array based on cuurent job and then sorting based on Surname,Firstname
  //then concat the 2 arrays, so both the Arrays are sorted based on Surname,Firstname,
  // while the current Job records always stays on top.
  return flatten(
    partition(relationships, x => {
      return x.metadata?.currentJob;
    }).map(y =>
      y.sort((a, b) => {
        const firstContact = contactsMap.get(a.relatedContactId!);
        const secondContact = contactsMap.get(b.relatedContactId!);
        // just in case, but there is no case we don't have related contacts on this level,
        // since we fetch organisation with all related contacts before we render the OrgFo
        if (!firstContact || !secondContact) return 0;
        if (firstContact?.reversedName! > secondContact?.reversedName!)
          return 1;
        if (firstContact?.reversedName! < secondContact?.reversedName!)
          return -1;
        return 0;
      })
    )
  );
};

export const getOrganisationFormValues = (
  organisation: Contact | undefined,
  isAddOrganisationContact: boolean,
  stores: { core: CoreStore; practice: PracticeStore }
): EditOrganisationFormValues => {
  const { core, practice } = stores;
  const searchValue = practice.ui.demographicInitialValues;
  const { country } = core.tenantDetails!;

  if (isAddOrganisationContact || !organisation) {
    return {
      status: ContactStatus.Active,
      addresses: [defaultAddress(AddressType.Both, newGuid(), country)],
      organisationName: searchValue?.organisationName,
      organisationRoles: [],
      communications: [
        defaultCommunication(CommunicationType.Phone, newGuid()),
        defaultCommunication(CommunicationType.Email, newGuid())
      ],
      relationships: [],
      linkOrganizationId: "",
      draftItemsEnabled: false
    };
  }

  const getOrgCommunicationsValues = (
    communications: CommunicationDto[],
    core: CoreStore
  ): CommunicationFieldValue[] => {
    return toCommunicationFieldValue({
      communications,
      core,
      communicationComparer,
      defaultCommunicationTypes: [
        CommunicationType.Phone,
        CommunicationType.Email
      ]
    });
  };

  const sortedRelationships = sortRelationships(
    organisation.relationships,
    practice.contactsMap
  );

  return {
    id: organisation.id,
    status: organisation.status,
    organisationName: organisation.name,
    organisationCategories: organisation.organisationCategories,
    relationships: toOrgRelationshipFieldValues(sortedRelationships),
    addresses: toAddressFieldValue(
      organisation.addresses,
      AddressType.Both,
      country
    ),
    communications: getOrgCommunicationsValues(
      organisation.communications,
      core
    ),
    linkOrganizationId: getLinkedOrgId(organisation.relationships),
    organisationRoles: organisation.organisationRoles ?? [],
    privateInsurer: organisation.privateInsurer,
    employer:
      country === Country.NewZealand
        ? ({
            ...organisation.employer,
            visitsPerClaim: organisation.employer?.visitsPerClaim ?? "16"
          } as EmployerDto)
        : organisation.employer,
    usingPrivateInsurer: !!organisation.employer?.privateInsurerContactId,
    profilePictureUrl: organisation.profilePictureUrl,
    draftItemsEnabled: organisation.draftItemsEnabled
  };
};

export const orgMenuItems: SideRailMenuItem[] = [
  {
    text: Labels.organisationInfo,
    id: OrganisationCardIds.OrganisationHeader
  },
  {
    text: Labels.organisationType,
    id: OrganisationCardIds.OrganisationType
  },
  {
    text: Labels.account,
    id: OrganisationCardIds.OrganisationAccount
  },
  {
    text: Labels.location,
    id: OrganisationCardIds.OrganisationLocation
  },
  {
    text: Labels.people,
    id: OrganisationCardIds.OrganisationPeople
  }
];
