import { action, observable, runInAction } from "mobx";

import { DateTime, upsertItem } from "@bps/utils";
import {
  MedicationClinicalDataItemDto,
  MedicationDurationUnit,
  ReactionWarningDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  DrugMedicationSummaryDto,
  DrugProductNameDto,
  GetMedicationSummaryDto
} from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { PrescribingDoseFormValues } from "../PrescribingWizard.types.tsx";
import { getDefaultFormValues } from "../utils.ts";

export class PrescribingWizardHelper {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private root: RootStore
  ) {
    runInAction(() => {
      this.isReactionsCheckedAndUpdated =
        !!this.clinicalRecord.clinicalData?.reactionsChecked
          ?.reactionsCheckedAndUpdated.value;
    });
  }

  private get notification() {
    return this.root.notification;
  }

  private get clinical() {
    return this.root.clinical;
  }

  saveAndNew: boolean = false;

  @observable
  acceptedWarnings: boolean = false;

  @observable
  productSearch: string = "";

  @observable
  products: DrugProductNameDto[] = [];

  @observable
  productsLoading: boolean = false;

  @observable
  formulations: DrugMedicationSummaryDto[] = [];

  @observable
  formulationsLoading: boolean = false;

  @observable
  formulationsSearch?: string;

  @observable
  formulationsRestriction: {
    [pbsListingCode: string]: boolean;
  } = {};

  @observable
  selectedProduct: DrugProductNameDto | undefined;

  @observable
  selectedFormulation: DrugMedicationSummaryDto | undefined;

  @observable
  isReactionsCheckedAndUpdated: boolean;

  @observable
  warnings: ReactionWarningDto[] = [];

  @observable
  warningsLoading: boolean = false;

  @action
  restorePrescribingWizardHelper = () => {
    this.acceptedWarnings = false;
    this.productSearch = "";
    this.products = [];
    this.productsLoading = false;
    this.formulations = [];
    this.formulationsLoading = false;
    this.formulationsSearch = undefined;
    this.formulationsRestriction = {};
    this.selectedProduct = undefined;
    this.selectedFormulation = undefined;
    this.warnings = [];
    this.warningsLoading = false;
    this.isReactionsCheckedAndUpdated =
      !!this.clinicalRecord.clinicalData?.reactionsChecked
        ?.reactionsCheckedAndUpdated.value;
    this.saveAndNew = false;
  };

  getInitialDoseFormValues(
    medication?: MedicationClinicalDataItemDto
  ): PrescribingDoseFormValues | undefined {
    if (medication) {
      const duration = !(
        medication.durationUnit === MedicationDurationUnit.UntilFinished ||
        !!medication.isLongTermMedication
      )
        ? medication.duration
        : undefined;

      return {
        quantity: medication.quantity,
        rpts: medication.repeats,
        duration,
        durationUnit: medication.durationUnit
          ? medication.durationUnit
          : MedicationDurationUnit.Day,
        dose: medication.dose,
        doseUnit: medication.doseUnit,
        frequency: medication.frequency,
        other: medication.otherInstructions,
        food: medication.food,
        route: medication.route,
        longTerm: medication.isLongTermMedication,
        prn: medication.prn,
        complex: medication.complexInstructions,
        regulation49: medication.regulation49,
        rxType: medication.rxType,
        medicationStartedBy: medication.medicationStartedBy,
        medicationStarted: DateTime.jsDateFromISO(medication.medicationStarted)
      };
    }

    if (this.selectedFormulation) {
      return getDefaultFormValues(
        this.selectedFormulation,
        this.clinicalRecord.patient?.dva?.cardColor
      );
    }
    return undefined;
  }

  public onSaveMedication = async (
    saveValues: PrescribingDoseFormValues,
    isPrescribed: boolean,
    medicationId?: string
  ) => {
    let medication: MedicationClinicalDataItemDto | undefined;

    if (medicationId) {
      medication =
        this.clinicalRecord.clinicalData?.medication?.medications.find(
          m => m.id === medicationId
        );
    }

    const saveMedication: MedicationClinicalDataItemDto = {
      packId:
        medication?.packId ?? this.selectedFormulation?.productPack.packId!,
      productName:
        medication?.productName ??
        this.selectedFormulation?.productPack?.medication!,
      strength:
        medication?.strength ?? this.selectedFormulation?.productPack.strength,
      quantity: saveValues.quantity,
      repeats: saveValues.rpts,
      duration: saveValues.duration,
      durationUnit: saveValues.durationUnit,
      dose: saveValues.dose,
      doseUnit: saveValues.doseUnit,
      frequency: saveValues.frequency,
      otherInstructions: saveValues.other,
      food: saveValues.food,
      route: saveValues.route,
      isLongTermMedication: saveValues.longTerm,
      prn: saveValues.prn || false,
      complexInstructions: saveValues.complex ? saveValues.complex : "",
      isCeased: false,
      isPrescribed: medication?.isPrescribed ?? isPrescribed,
      regulation49: saveValues.regulation49 || false,
      brandSubstitute: false,
      printBrandName: false,
      rxNote: "",
      rxType: saveValues.rxType,
      warnings: medication?.warnings ?? this.warnings,
      acceptedWarnings: medication?.acceptedWarnings ?? this.acceptedWarnings,
      medicationStartedBy: saveValues?.medicationStartedBy,
      medicationStarted: DateTime.fromJSDate(
        saveValues?.medicationStarted
      )?.toISODate()
    };

    let medications = [
      ...(this.clinicalRecord.clinicalData?.medication?.medications || [])
    ];

    if (medicationId) {
      medications = upsertItem({
        array: medications,
        item: saveMedication,
        predicate: x => x.id === medicationId
      });
    } else {
      medications.push(saveMedication);
    }

    const eTag = this.clinicalRecord.clinicalData?.medication?.eTag;
    if (!this.saveAndNew) {
      await this.clinicalRecord.saveClinicalData({
        medication: { medications, eTag }
      });

      if (medicationId) {
        this.notification.success("Rx edited");
      } else {
        this.notification.success("Rx added");
      }
    } else {
      await this.clinicalRecord.saveClinicalData({
        medication: { medications, eTag }
      });
      this.notification.success("Rx added, resetting form...");
    }
  };

  public handleSubmitMedication = async () => {
    if (!this.selectedFormulation) return;
    runInAction(() => {
      this.warningsLoading = true;
    });

    const result = await this.clinical
      .getReactionWarnings({
        patientId: this.clinicalRecord.id,
        packId: this.selectedFormulation.productPack.packId
      })
      .finally(() => {
        runInAction(() => {
          this.warningsLoading = false;
        });
      });

    if (!!result.length && !this.acceptedWarnings) {
      runInAction(() => {
        this.warnings = result;
      });
    }
    return;
  };

  preSearchReprescribe = async (med: MedicationClinicalDataItemDto) => {
    const productName = med.productName.split(" ")[0];
    const products = await this.root.drugs.searchProductNames(productName);
    const args: GetMedicationSummaryDto =
      products && products.length > 0
        ? {
            productNameId: `${products[0].id}`
          }
        : { packId: med.packId.toString() };

    const summaries = await this.root.drugs.searchMedicationSummary(args);
    runInAction(() => {
      this.productSearch = med.productName;
      this.productsLoading = false;
      this.products = products;
      this.selectedProduct = products[0];
      this.selectedFormulation = summaries.find(
        x => x.productPack.packId === med.packId
      );
      this.formulationsLoading = false;
      this.formulations = summaries;
    });
  };
}
