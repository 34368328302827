import { useForm } from "react-final-form";

import {
  AnimatedListWithKeys,
  dataAttribute,
  DataAttributes,
  Stack
} from "@bps/fluent-ui";
import { useAllocationField } from "@modules/billing/screens/shared-components/allocation-field/hooks/useAllocationField.ts";
import { ItemSelectionMode } from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { AllocationOptions } from "../../AllocationOptions.ts";
import { useAllocationFormContext } from "../../context/AllocationFormContext.ts";
import {
  ACCOUNT_CREDIT_METHOD,
  AllocationFormValues,
  allocationNameOf,
  PaymentFormValues
} from "../AllocationForm.types.ts";
import { PaymentMethodField } from "./PaymentMethodField.tsx";
import { showAccountCredit } from "./util.ts";

export const PaymentMethodsField: React.FC = () => {
  const { accountCreditPaymentOption, onAmountChange, onPaymentLengthChange } =
    useAllocationFormContext();

  const { deselectAllItems, allocatedTotal, allocationOption } =
    useAllocationField();

  const form = useForm<AllocationFormValues>();

  const { fields } = useFieldArray<PaymentFormValues>(
    allocationNameOf("payments"),
    { subscription: { value: true } }
  );

  if (!fields.value?.length) return null;

  return (
    <AnimatedListWithKeys
      items={fields.value}
      onItemIdRemoved={id => {
        const index = fields.value.findIndex(v => v.id === id);
        if (index !== -1) fields.remove(index);
      }}
    >
      {(id, onRemoveItem) => {
        const index = fields.value.findIndex(v => v.id === id);
        if (index < 0) return null;

        return (
          <Stack
            {...dataAttribute(DataAttributes.Element, `payment-field-${index}`)}
            horizontal
            tokens={{ childrenGap: 8 }}
          >
            <PaymentMethodField
              name={`${allocationNameOf("payments")}[${index}].method`}
              extraPaymentMethod={
                allocationOption?.key !== AllocationOptions.accountCredit &&
                showAccountCredit(fields.value, index)
                  ? accountCreditPaymentOption
                  : undefined
              }
            />
            <SpinNumberInputField
              {...dataAttribute(
                DataAttributes.Element,
                "payment-method-amount"
              )}
              validateOnInitialize={
                fields.value[index].method === accountCreditPaymentOption?.key
              }
              name={`${allocationNameOf("payments")}[${index}].amount`}
              step={1}
              precision={2}
              parse={value => value ?? ""}
              prefix="$"
              styles={{ spinButtonWrapper: { maxWidth: 140 } }}
              max={
                fields.value[index].method === accountCreditPaymentOption?.key
                  ? accountCreditPaymentOption?.max
                  : undefined
              }
              disabled={
                fields.length === 1 &&
                allocationOption?.key !== AllocationOptions.setAmount &&
                allocationOption?.itemSelectionMode !== ItemSelectionMode.hidden
              }
              fieldItemStyles={{ root: { flexBasis: 0 } }}
            />
            <DeleteButton
              {...dataAttribute(
                DataAttributes.Element,
                "delete-allocation-button"
              )}
              styles={{
                root: {
                  padding: 0
                }
              }}
              onClick={() => {
                onRemoveItem();
                onPaymentLengthChange &&
                  onPaymentLengthChange({
                    index,
                    values: form.getState().values,
                    form,
                    removed: true
                  });
              }}
              disabled={fields.length === 1}
            />
            <FieldSpy
              name={
                `${allocationNameOf(
                  "payments"
                )}[${index}].amount` as keyof AllocationFormValues
              }
              onChange={(value, values: AllocationFormValues, prevValue) => {
                form.batch(() => {
                  if (onAmountChange && values) {
                    onAmountChange({
                      value,
                      values,
                      index,
                      form
                    });
                  }
                  if (
                    Number(value) < Number(prevValue) &&
                    Number(value) < allocatedTotal
                  ) {
                    deselectAllItems();
                  }
                });
              }}
            />
            <FieldSpy
              name={`${allocationNameOf("payments")}[${index}].method`}
              onChange={value =>
                value === ACCOUNT_CREDIT_METHOD &&
                accountCreditPaymentOption?.max &&
                form.change(
                  `${allocationNameOf(
                    "payments"
                  )}[${index}].amount` as keyof AllocationFormValues,
                  Math.min(
                    accountCreditPaymentOption?.max,
                    fields.value[index].amount
                  )
                )
              }
            />
          </Stack>
        );
      }}
    </AnimatedListWithKeys>
  );
};
