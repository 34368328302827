import React, { FunctionComponent } from "react";

import { CenteredLargeSpinner } from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { GoalsCard } from "@modules/clinical/screens/patient-record/components/SOTAP/GoalsCard.tsx";
import { OutcomeMeasuresCard } from "@modules/clinical/screens/patient-record/components/SOTAP/outcome-measure/OutcomeMeasuresCard.tsx";
import { DischargeReferralFollowUpValidator } from "@modules/clinical/screens/patient-record/components/SOTAP/sotap-follow-on/validators/DischargeReferralFollowUpValidator.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { withFetchSOTAPRefData } from "../withFetchSOTAPRefData.tsx";
import { DischargeContext, useDischargeContext } from "./DischargeContext.tsx";
import { DischargeFormValues } from "./DischargeForm.types.ts";
import { DischargeFormHeader } from "./DischargeFormHeader.tsx";
import { DischargeFormModel } from "./DischargeFormModel.ts";
import { DischargeOutcomeCard } from "./DischargeOutcomeCard.tsx";
import { DischargeReferralFollowUpCard } from "./DischargeReferralFollowUpCard.tsx";
import { DischargeSummaryCard } from "./DischargeSummaryCard.tsx";

interface DischargeFormProps {
  clinicalRecord: ClinicalRecord;
  contextId: string;
  defaultTools?: ClinicalDataType[] | undefined;
}

const dischargeReferralFollowUpValidator =
  new DischargeReferralFollowUpValidator();

const DischargeFormBase: React.FC = () => {
  const { notification } = useStores();

  const { clinicalRecord } = usePatientRecordScreenContext();

  const helper = useDischargeContext();

  const {
    submitData,
    closeDischargeTab,
    initialValues,
    dischargeSideMenu,
    getDischargeStashedClinicalData
  } = helper;

  const onSubmitSucceeded = () => {
    notification.success("Discharge has been saved successfully");
    closeDischargeTab(helper.episodeOfCare.id);
  };

  const isViewOnly = helper.isViewOnly || !helper.isUserProviderEquivalent;
  const showReferralFollowUp = !!clinicalRecord.episodeOfCare?.referralNumber;

  const render = () => (
    <CardFormLayout
      sideRailMenuItems={dischargeSideMenu}
      styles={({ theme }) => ({
        root: {
          background: theme.palette.neutralLighterAlt
        }
      })}
      header={<DischargeFormHeader />}
    >
      <StashedClinicalDataFormSpy<DischargeFormValues>
        clinicalRecord={clinicalRecord}
        contextId={helper.contextId}
        getData={getDischargeStashedClinicalData}
        areasToObserve={{
          goals: [ClinicalDataType.Discharge],
          patientTreatmentPlan: [ClinicalDataType.Discharge],
          discharge: [ClinicalDataType.Discharge]
        }}
      />

      <DischargeSummaryCard />
      <DischargeOutcomeCard isViewOnly={isViewOnly} />
      {showReferralFollowUp && <DischargeReferralFollowUpCard />}
      <GoalsCard isViewOnly={isViewOnly} linkedCondition />
      <OutcomeMeasuresCard
        hideAddToolsButton
        isViewOnly={isViewOnly}
        episodeOfCareId={helper.episodeOfCare.id}
      />
    </CardFormLayout>
  );

  return (
    <SubmissionForm<DischargeFormValues>
      formName="discharge"
      disabled={isViewOnly}
      disableRoutePrompt
      styles={{
        root: {
          margin: -16
        }
      }}
      hideButtons
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      validate={dischargeReferralFollowUpValidator.validate}
      initialValues={initialValues}
      render={render}
      delayForSettingFieldsData={500}
    />
  );
};

const DischargeFormComponent: FunctionComponent<DischargeFormProps> = ({
  clinicalRecord,
  contextId,
  defaultTools
}) => {
  const context = contextId.split("::");
  const episodeOfCareId = context[0];
  const businessRoleCode = context[1];

  const fetch = async (): Promise<DischargeFormModel> => {
    const episodeOfCare =
      clinicalRecord.episodeOfCare?.id === episodeOfCareId
        ? clinicalRecord.episodeOfCare
        : await clinicalRecord.clinical.getEpisodeOfCare(episodeOfCareId!);

    const encounterClinicalData =
      clinicalRecord.episodeOfCare?.id === episodeOfCareId &&
      clinicalRecord.clinicalData
        ? clinicalRecord.clinicalData
        : await clinicalRecord.clinical.getEpisodeOfCareScopedClinicalData({
            patientId: clinicalRecord.id,
            episodeOfCareId,
            types: [
              ClinicalDataType.Discharge,
              ClinicalDataType.Diagnoses,
              ClinicalDataType.DischargeReferralFollowUp
            ]
          });

    const helper = new DischargeFormModel(clinicalRecord, {
      episodeOfCare,
      businessRoleCode,
      encounterClinicalData
    });

    if (clinicalRecord.episodeOfCare?.id === episodeOfCareId) {
      await helper.loadCurrentEncounterClinicalToolsData(
        businessRoleCode,
        defaultTools
      );
    } else {
      await helper.loadEpisodeOfCareClinicalToolsData(
        episodeOfCareId,
        businessRoleCode,
        defaultTools
      );
    }

    return helper;
  };

  return (
    <DataFetcher
      fetch={fetch}
      refetchId={contextId}
      fallback={<CenteredLargeSpinner />}
    >
      {model => (
        <DischargeContext.Provider value={model}>
          <DischargeFormBase />
        </DischargeContext.Provider>
      )}
    </DataFetcher>
  );
};

export const DischargeForm = withFetchSOTAPRefData(DischargeFormComponent);
