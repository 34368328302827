import { observer, Observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import {
  CenteredBox,
  CheckboxVisibility,
  DetailsRow,
  FontIcon,
  FontSizes,
  IColumn,
  IconButton,
  IDetailsRowProps,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  SelectionMode,
  Shimmer,
  Stack,
  Tile,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { OutboundCommStatusesEnumText } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  CommunicationKind,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useEventReminderScreenContext } from "@modules/booking/screens/event-reminders/context/EventReminderScreenContext.tsx";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { CalendarEventReminderSearchModel } from "@stores/booking/models/CalendarEventReminderSearchModel.ts";
import { formatCommunicationValue } from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { EventReminderListLabel } from "../EventReminderScreen.types.ts";
import { EventReminderDashboard } from "./EventReminderDashboard.tsx";
import { EventReminderFilters } from "./EventReminderFilters.tsx";
import {
  eventReminderMenuItems,
  getEventReminderStatusColours
} from "./utils.tsx";

export const EventReminderList: React.FC = observer(() => {
  const root = useStores();
  const { core, practice } = root;
  const {
    ui: { showEditContact, showContactDetails }
  } = practice;

  const country = core.tenantDetails && core.tenantDetails.country;
  const theme = useTheme();

  const { setReminderArgs } = useBookingCalendarScreenContext();
  const {
    eventReminderSearchResults,
    getFilteredEventReminders,
    getSortedAppointmentReminders,
    setEventReminder,
    setCurrentEventData,
    setColumns,
    columns,
    load
  } = useEventReminderScreenContext();

  const onShowContext = (
    evt: MouseEvent,
    appt: CalendarEventReminderSearchModel
  ) => {
    evt.preventDefault();
    evt.stopPropagation();

    setCurrentEventData({
      event: appt,
      contextualMenuPosition: evt
    });
  };

  useEffect(() => {
    setColumns(defaultColumns);
    load();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onColumnHeaderClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    getSortedAppointmentReminders(column);
  };

  const onRenderRow = (props: IDetailsRowProps) => {
    if (props && props.item) {
      return (
        <div onContextMenu={evt => onShowContext(evt.nativeEvent, props.item)}>
          <DetailsRow
            checkboxVisibility={CheckboxVisibility.always}
            styles={{
              cell: props.item.disabled
                ? {
                    color: theme.palette.neutralTertiary,
                    fontStyle: "italic"
                  }
                : undefined
            }}
            {...props}
          />
        </div>
      );
    }
    return null;
  };

  const renderTime = (item: CalendarEventReminderSearchModel) => {
    return (
      item.reminder && (
        <Stack>
          {item.reminder.calendarEventAppointmentTime.toFormat(
            TIME_FORMATS.DEFAULT_TIME_FORMAT
          )}
        </Stack>
      )
    );
  };

  const renderPhone = (item: CalendarEventReminderSearchModel) => {
    const optedOut = item.message?.contactHasOptedOut;
    if (optedOut) {
      return <Stack>Opted out</Stack>;
    } else if (!item.phoneNumber) {
      return <Stack>Not configured</Stack>;
    } else {
      const format = formatCommunicationValue(
        item.phoneNumber,
        CommunicationKind.Phone,
        country
      );
      return <Stack styles={{ root: { textAlign: "right" } }}>{format}</Stack>;
    }
  };

  const deliveryStatus = (item: CalendarEventReminderSearchModel) => {
    const status = item.message?.deliveryStatusCode;
    if (status) {
      return OutboundCommStatusesEnumText[status];
    } else return "";
  };

  const eventReminderStatusColor = getEventReminderStatusColours(theme);

  const renderResponse = (item: CalendarEventReminderSearchModel) => {
    return (
      <Observer>
        {() => {
          if (!item.disabled && item.attendanceStatus) {
            return (
              <FontIcon
                iconName="RadioBullet"
                styles={{
                  root: {
                    color: eventReminderStatusColor(item.attendanceStatus),
                    fontSize: FontSizes.size24,
                    display: "block",
                    textAlign: "center"
                  }
                }}
              />
            );
          }

          return null;
        }}
      </Observer>
    );
  };

  const renderHeader = (headerName: string, tootlTipText: string) => {
    return (
      <Stack horizontal tokens={{ childrenGap: 2 }}>
        <span>{headerName}</span>
        <TooltipHost
          content={tootlTipText}
          tooltipProps={{ calloutProps: { gapSpace: -13 } }} //-13 to show the callout just top of the info icon
        >
          <FontIcon
            iconName="Info"
            styles={{
              root: {
                fontSize: FontSizes.size12,
                color: theme.palette.black,
                paddingTop: 2,
                cursor: "pointer"
              }
            }}
          />
        </TooltipHost>
      </Stack>
    );
  };

  const renderPatient = (item: CalendarEventReminderSearchModel) => {
    return item.patientId ? (
      <Navigate
        styles={{
          root: item.disabled
            ? {
                color: theme.palette.neutralTertiary,
                fontStyle: "italic"
              }
            : undefined
        }}
        onClick={() => handleNavigateClicked(item.patient?.id!)}
      >{`${item.patientName}`}</Navigate>
    ) : (
      <Shimmer />
    );
  };

  const handleNavigateClicked = (id: string) => {
    const hasPatientWritePermission = core.hasPermissions([
      Permission.PatientWrite
    ]);

    if (hasPatientWritePermission)
      showEditContact(PatientCardIds.patientHeader, id);
    else showContactDetails(id);
  };

  const defaultColumns: IColumn[] = [
    {
      key: EventReminderListLabel.Actions,
      className: RESET_CELLS_PADDING_CLASSNAME,
      minWidth: 50,
      maxWidth: 60,
      styles: { cellTitle: { justifyContent: "center" } },
      name: EventReminderListLabel.Actions,
      onRender: (item: CalendarEventReminderSearchModel) => (
        <Observer>
          {() => {
            return (
              <Stack horizontalAlign="center" verticalAlign="center">
                <IconButton
                  iconProps={{ iconName: "More" }}
                  onRenderMenuIcon={() => null}
                  disabled={item.disabled}
                  menuProps={{
                    items: eventReminderMenuItems({
                      item,
                      root,
                      setReminderArgs,
                      theme
                    })
                  }}
                  styles={{
                    root: { width: "32px", height: "36px", padding: 0 },
                    flexContainer: { width: "32px", height: "36px" },
                    rootDisabled: { backgroundColor: "transparent" }
                  }}
                />
              </Stack>
            );
          }}
        </Observer>
      )
    },
    {
      key: "status",
      minWidth: 50,
      maxWidth: 70,
      styles: { cellTitle: { justifyContent: "center" } },
      name: EventReminderListLabel.Response,
      onRender: renderResponse
    },
    {
      key: "deliveryStatus",
      minWidth: 125,
      maxWidth: 200,
      name: EventReminderListLabel.DeliveryStatus,
      onColumnClick: onColumnHeaderClick,
      onRender: deliveryStatus
    },
    {
      key: "appointmentDate",
      minWidth: 80,
      maxWidth: 100,
      name: EventReminderListLabel.Date,
      onColumnClick: onColumnHeaderClick,
      isSorted: true,
      isSortedDescending: false,
      onRenderHeader: () =>
        renderHeader(EventReminderListLabel.Date, "Appointment date"),
      onRender: (item: CalendarEventReminderSearchModel) => {
        return (
          item.reminder && (
            <Stack>
              {DateTime.fromJSDate(
                item.reminder.calendarEventStartDate
              ).toDayDefaultFormat()}
            </Stack>
          )
        );
      }
    },
    {
      key: "appointmentTime",
      minWidth: 60,
      maxWidth: 70,
      name: EventReminderListLabel.Time,
      onColumnClick: onColumnHeaderClick,
      onRenderHeader: () =>
        renderHeader(EventReminderListLabel.Time, "Appointment time"),
      onRender: renderTime
    },
    {
      key: "appointmentType",
      minWidth: 150,
      maxWidth: 250,
      name: EventReminderListLabel.AppointmentType,
      onColumnClick: onColumnHeaderClick,
      onRenderHeader: () =>
        renderHeader(
          EventReminderListLabel.AppointmentType,
          "Appointment type"
        ),
      onRender: (item: CalendarEventReminderSearchModel) => item.appointmentType
    },
    {
      key: "patientName",
      minWidth: 125,
      maxWidth: 250,
      name: EventReminderListLabel.Patient,
      onColumnClick: onColumnHeaderClick,
      onRender: renderPatient
    },
    {
      key: "phoneNumber",
      minWidth: 125,
      maxWidth: 150,
      name: EventReminderListLabel.PhoneNumber,
      styles: {
        root: { textAlign: "end" },
        cellTitle: { justifyContent: "flex-end" }
      },
      onColumnClick: onColumnHeaderClick,
      onRender: renderPhone
    },
    {
      key: "providerName",
      minWidth: 125,
      maxWidth: 250,
      name: EventReminderListLabel.Provider,
      onColumnClick: onColumnHeaderClick,
      onRender: (item: CalendarEventReminderSearchModel) => item.providerName
    },
    {
      key: "location",
      minWidth: 100,
      maxWidth: 200,
      name: EventReminderListLabel.Location,
      onColumnClick: onColumnHeaderClick,
      onRender: (item: CalendarEventReminderSearchModel) =>
        `${item.locationName}`
    }
  ];

  const selection = useRef(
    new Selection({
      onSelectionChanged: async () => {
        const indexes = selection.current.getSelectedIndices();
        const itemKey = indexes[0];
        const item = selection.current.getItems()[
          itemKey
        ] as CalendarEventReminderSearchModel;

        if (item) {
          setEventReminder(item);
        }
      }
    })
  );

  return (
    <Tile
      styles={{
        root: { overflow: "hidden", padding: 16, height: "100%" },
        content: {
          padding: 0,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column"
        }
      }}
    >
      <EventReminderDashboard />
      <EventReminderFilters>
        {state => {
          return (
            <Observer>
              {() => {
                const filteredItems = getFilteredEventReminders(
                  state.values,
                  eventReminderSearchResults.value
                );

                if (
                  !eventReminderSearchResults.error &&
                  !eventReminderSearchResults.pending &&
                  !filteredItems.length
                ) {
                  return (
                    <CenteredBox>
                      <DefaultNoResultsTile defaultText="No records found" />
                    </CenteredBox>
                  );
                }

                return (
                  <Stack grow styles={{ root: { position: "relative" } }}>
                    <ScrollablePane>
                      <ShimmeredDetailsList
                        items={filteredItems}
                        enableShimmer={eventReminderSearchResults.pending}
                        errorMessage={eventReminderSearchResults.error?.message}
                        selectionMode={SelectionMode.single}
                        selection={selection.current}
                        selectionPreservedOnEmptyClick={true}
                        columns={columns}
                        onRenderRow={onRenderRow}
                        stickyHeader={true}
                      />
                    </ScrollablePane>
                  </Stack>
                );
              }}
            </Observer>
          );
        }}
      </EventReminderFilters>
    </Tile>
  );
});
